import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { IconClose, IconConfirm } from "../../utils/Icons/CustomIcons"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import { registerAnswer } from "@/redux/slices/events/eventsSlice"
import AcceptSelect from "./AcceptSelect"
import "./AcceptModal.scss"

const AcceptModal = ({
  show,
  setShow,
  setShowThank,
}: {
  show: boolean
  setShow: any
  setShowThank: any
}) => {
  const { t } = useTranslation()
  const { event_by_hash } = useAppSelector((state) => state.events)
  const dispatch = useAppDispatch()
  const regex =
    /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(:\d+)?(\/.*)?$/

  const [activeButton, setActiveButton] = useState<any>(
    event_by_hash.questions.data.length > 0
      ? event_by_hash.questions.data
          .filter((item) => item.type === 1)
          .map((item) => {
            return { id: item.id, values: [] }
          })
      : [],
  )

  const [value, setValue] = useState({
    name: "",
    phone: "",
    email: "",
    number: 0,
    answers: event_by_hash.questions.data.map((item) => {
      return { question_id: item.id, answer: "" }
    }),
  })

  const [error, setError] = useState<any>({
    name: "",
    phone: "",
    email: "",
    number: "",
    questions: [],
  })

  const onSendHandler = () => {
    const urls: any[] = []
    event_by_hash.questions.data
      .filter((item: any) => item.type === 3)
      .forEach((item: any) => {
        urls.push(value.answers.find((ans: any) => ans.question_id === item.id))
      })
    const badUrls: any[] = []
    urls.forEach((item: any) => {
      if (!regex.test(item.answer)) {
        badUrls.push(item.question_id)
      }
    })
    if (
      value.name === "" &&
      value.phone === "" &&
      value.email === "" &&
      (value.answers.filter((item) => item.answer === "").length > 0 ||
        badUrls.length > 0)
    ) {
      setError({
        ...error,
        name: "This field is required",
        phone: "This field is required",
        email: "This field is required",
        questions: value.answers
          .filter(
            (item) =>
              item.answer === "" ||
              item.answer.length === 0 ||
              badUrls.some((item2) => item2 === item.question_id),
          )
          .map((item) => item.question_id),
      })
    } else if (
      value.name === "" &&
      value.phone === "" &&
      (value.answers.filter((item) => item.answer === "").length > 0 ||
        badUrls.length > 0)
    ) {
      setError({
        ...error,
        name: "This field is required",
        phone: "This field is required",
        email: "",
        questions: value.answers
          .filter(
            (item) =>
              item.answer === "" ||
              item.answer.length === 0 ||
              badUrls.some((item2) => item2 === item.question_id),
          )
          .map((item) => item.question_id),
      })
    } else if (
      value.name === "" &&
      value.email === "" &&
      (value.answers.filter((item) => item.answer === "").length > 0 ||
        badUrls.length > 0)
    ) {
      setError({
        ...error,
        name: "This field is required",
        phone: "",
        email: "This field is required",
        questions: value.answers
          .filter(
            (item) =>
              item.answer === "" ||
              item.answer.length === 0 ||
              badUrls.some((item2) => item2 === item.question_id),
          )
          .map((item) => item.question_id),
      })
    } else if (value.name === "" && value.phone === "" && value.email === "") {
      setError({
        ...error,
        name: "This field is required",
        phone: "This field is required",
        email: "This field is required",
        questions: [],
      })
    } else if (
      value.phone === "" &&
      value.email === "" &&
      (value.answers.filter((item) => item.answer === "").length > 0 ||
        badUrls.length > 0)
    ) {
      setError({
        ...error,
        name: "",
        phone: "This field is required",
        email: "This field is required",
        questions: value.answers
          .filter(
            (item) =>
              item.answer === "" ||
              item.answer.length === 0 ||
              badUrls.some((item2) => item2 === item.question_id),
          )
          .map((item) => item.question_id),
      })
    } else if (value.name === "" && value.phone === "") {
      setError({
        ...error,
        name: "This field is required",
        phone: "This field is required",
        email: "",
        questions: [],
      })
    } else if (
      value.name === "" &&
        (value.answers.filter((item) => item.answer === "").length > 0 ||
      badUrls.length > 0)
    ) {
      setError({
        ...error,
        name: "This field is required",
        phone: "",
        email: "",
        questions: value.answers
          .filter(
            (item) =>
              item.answer === "" ||
              item.answer.length === 0 ||
              badUrls.some((item2) => item2 === item.question_id),
          )
          .map((item) => item.question_id),
      })
    } else if (
      value.phone === "" &&
        (value.answers.filter((item) => item.answer === "").length > 0 ||
      badUrls.length > 0)
    ) {
      setError({
        ...error,
        name: "This field is required",
        phone: "This field is required",
        email: "",
        questions: value.answers
          .filter(
            (item) =>
              item.answer === "" ||
              item.answer.length === 0 ||
              badUrls.some((item2) => item2 === item.question_id),
          )
          .map((item) => item.question_id),
      })
    } else if (
      value.email === "" &&
        (value.answers.filter((item) => item.answer === "").length > 0 ||
      badUrls.length > 0)
    ) {
      setError({
        ...error,
        name: "",
        phone: "",
        email: "This field is required",
        questions: value.answers
          .filter(
            (item) =>
              item.answer === "" ||
              item.answer.length === 0 ||
              badUrls.some((item2) => item2 === item.question_id),
          )
          .map((item) => item.question_id),
      })
    } else if (value.name === "" && value.email === "") {
      setError({
        ...error,
        name: "This field is required",
        phone: "",
        email: "This field is required",
        questions: [],
      })
    } else if (value.phone === "" && value.email === "") {
      setError({
        ...error,
        phone: "This field is required",
        name: "",
        email: "This field is required",
        questions: [],
      })
    } else if (value.name === "") {
      setError({
        ...error,
        name: "This field is required",
        phone: "",
        email: "",
        questions: [],
      })
    } else if (
      value.answers.filter((item) => item.answer === "").length > 0 ||
      badUrls.length > 0
    ) {
      setError({
        ...error,
        name: "",
        phone: "",
        email: "",
        questions: value.answers
          .filter(
            (item) =>
              item.answer === "" ||
              item.answer.length === 0 ||
              badUrls.some((item2) => item2 === item.question_id),
          )
          .map((item) => item.question_id),
      })
    } else if (value.phone === "") {
      setError({
        ...error,
        phone: "This field is required",
        name: "",
        email: "",
        questions: [],
      })
    } else if (value.email === "") {
      setError({
        ...error,
        email: "This field is required",
        name: "",
        phone: "",
        questions: [],
      })
    } else {
      setError({
        ...error,
        email: "",
        name: "",
        phone: "",
        questions: [],
      })
      if (
        value.email !== "" &&
        value.name !== "" &&
        value.phone !== "" &&
        value.answers.filter((item) => item.answer === "").length === 0 &&
        badUrls.length === 0
      ) {
        dispatch(
          registerAnswer({
            open_event_hash: event_by_hash.open_event_hash,
            accepted: true,
            first_name: value.name,
            phone_number: value.phone,
            email: value.email,
            number_of_guests: event_by_hash.open_event_number_of_guests_required
              ? value.number
              : 0,
            answers: value.answers.map((item: any) =>
              Array.isArray(item.answer) === true
                ? { ...item, answer: item.answer.join() }
                : { ...item, answer: item.answer.toString() },
            ),
          }),
        ).then((res) => {
          if (res.payload.success) {
            setValue({
              name: "",
              phone: "",
              email: "",
              number: 1,
              answers: event_by_hash.questions.data.map((item) => {
                return { question_id: item.id, answer: "" }
              }),
            })
            setActiveButton(
              event_by_hash.questions.data.length > 0
                ? event_by_hash.questions.data
                    .filter((item) => item.type === 1)
                    .map((item) => {
                      return { id: item.id, values: [] }
                    })
                : [],
            )
            setError({
              name: "",
              phone: "",
              email: "",
              number: "",
              questions: [],
            })
            setShow(false)
            setShowThank(true)
          } else {
            setError({
              name: "",
              phone: res.payload.error,
              email: "",
              number: "",
              questions: [],
            })
          }
        })
      }
    }
  }

  const urlQuestion = (question: string, index: number, id: number) => {
    return (
      <div key={id} className="accept-main__card-container">
        <p className="accept-main__card-question">
          {index + 1}. {question}
        </p>
        <div className="accept-main__card-label question-label">
          Your answer
          <span
            className={`${
              error.questions.length > 0 &&
              error.questions.filter((item: any) => item === id).length > 0
                ? ""
                : "error"
            } accept-main__card-error`}
          >
            {value.answers.length > 0 &&
            value.answers.find((item) => item.question_id === id)?.answer !== ""
              ? "The response must be in url format"
              : "This field is required"}
          </span>
        </div>
        <textarea
          className={`accept-main__card-textarea ${
            error.questions.length > 0 &&
            error.questions.filter((item: any) => item === id).length > 0
              ? "border-error"
              : ""
          }`}
          value={value.answers.find((item) => item.question_id === id)?.answer}
          onChange={(e) =>
            setValue({
              ...value,
              answers: value.answers.map((item) =>
                item.question_id === id
                  ? { ...item, answer: e.target.value }
                  : item,
              ),
            })
          }
        />
      </div>
    )
  }

  const textQuestion = (question: string, index: number, id: number) => {
    return (
      <div key={id} className="accept-main__card-container">
        <p className="accept-main__card-question">
          {index + 1}. {question}
        </p>
        <div className="accept-main__card-label question-label">
          Your answer
          <span
            className={`${
              error.questions.length > 0 &&
              error.questions.filter((item: any) => item === id).length > 0
                ? ""
                : "error"
            } accept-main__card-error`}
          >
            This field is required
          </span>
        </div>
        <textarea
          className={`accept-main__card-textarea ${
            error.questions.length > 0 &&
            error.questions.filter((item: any) => item === id).length > 0
              ? "border-error"
              : ""
          }`}
          value={value.answers.find((item) => item.question_id === id)?.answer}
          onChange={(e) =>
            setValue({
              ...value,
              answers: value.answers.map((item) =>
                item.question_id === id
                  ? { ...item, answer: e.target.value }
                  : item,
              ),
            })
          }
        />
      </div>
    )
  }

  const numberQuestion = (
    item: any,
    question: string,
    index: number,
    id: number,
  ) => {
    return (
      <div key={id} className="accept-main__card-container">
        <p className="accept-main__card-question">
          {index + 1}. {question}
        </p>
        <div className="accept-main__card-label question-label">
          Your answer (1 or many options)
          <span
            className={`${
              error.questions.length > 0 &&
              error.questions.filter((item: any) => item === id).length > 0
                ? ""
                : "error"
            } accept-main__card-error`}
          >
            Must be selected
          </span>
        </div>
        <div className="accept-main__card-cards">
          {item.options.data.map((option: any, index: any) => (
            <div
              key={index}
              className={`accept-main__card-card
            ${
              activeButton.find(
                (item: { id: number; values: any }) => item.id === id,
              ).values.length > 0 &&
              activeButton
                .find((item: { id: number; values: any }) => item.id === id)
                .values.includes(option.option)
                ? "active"
                : ""
            } 
            ${
              error.questions.length > 0 &&
              error.questions.filter((item: any) => item === id).length > 0
                ? "border-error"
                : ""
            }`}
              onClick={() => {
                setActiveButton(
                  activeButton.find(
                    (item: { id: number; values: [] }) => item.id === id,
                  ).values.length > 0 &&
                    activeButton
                      .find(
                        (item: { id: number; values: [] }) => item.id === id,
                      )
                      .values.includes(option.option)
                    ? activeButton.map((button: any) =>
                        button.id === id
                          ? {
                              ...button,
                              values: button.values.filter(
                                (value: string) => value !== option.option,
                              ),
                            }
                          : button,
                      )
                    : activeButton.map((button: any) =>
                        button.id === id
                          ? {
                              ...button,
                              values: [...button.values, option.option],
                            }
                          : button,
                      ),
                )
                setValue({
                  ...value,
                  answers: value.answers.map((answer: any) =>
                    answer.question_id === id
                      ? {
                          ...answer,
                          answer:
                            answer.answer !== "" &&
                            answer.answer.length > 0 &&
                            answer.answer.includes(option.option)
                              ? answer.answer.filter(
                                  (value: string) => value !== option.option,
                                )
                              : [...answer.answer, option.option],
                        }
                      : answer,
                  ),
                })
              }}
            >
              {option.option}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const getNumberOptions = (item: any) => {
    const numbers: any[] = []
    for (let i = 0; i < item; i++) {
      numbers.push(i)
    }
    return numbers.length > 0
      ? numbers.map((option: any) => {
          if (option === 0) {
            return { value: option, label: t("register.accept.comeAlone") }
          } else {
            return { value: option, label: ++option }
          }
        })
      : []
  }

  const dropdownQuestion = (
    item: any,
    question: string,
    index: number,
    id: number,
  ) => {
    const numbers: any[] = []
    for (let i = item.min_value; i <= item.max_value; i++) {
      if (i === item.min_value) {
        numbers.push(i)
      } else if (i === item.max_value) {
        numbers.push(i)
      } else {
        numbers.push(i)
      }
    }
    return (
      <div key={id} className="accept-main__card-container">
        <p className="accept-main__card-question">
          {index + 1}. {question}
        </p>
        <div className="accept-main__card-label question-label">
          Select answer option
          {error.questions.length > 0 &&
            error.questions.filter((item: any) => item === id).length > 0 && (
              <div className="accept-main__card-error">
                {t("register.error1")}
              </div>
            )}
        </div>

        <AcceptSelect
          error={error}
          type="answer"
          id={id}
          setValue={setValue}
          value={value}
          options={
            numbers.length > 0
              ? numbers.map((option: any) => {
                  return { value: option, label: option }
                })
              : []
          }
        />
      </div>
    )
  }

  const onHide = () => {
    setValue({
      name: "",
      phone: "",
      email: "",
      number: 1,
      answers: event_by_hash.questions.data.map((item) => {
        return { question_id: item.id, answer: "" }
      }),
    })
    setActiveButton(
      event_by_hash.questions.data.length > 0
        ? event_by_hash.questions.data
            .filter((item) => item.type === 1)
            .map((item) => {
              return { id: item.id, values: [] }
            })
        : [],
    )
    setError({
      name: "",
      phone: "",
      email: "",
      number: "",
      questions: [],
    })
    setShow(false)
  }

  return (
    <Modal className={`accept-modal`} show={show} onHide={onHide}>
      <Modal.Body>
        <div className="accept-container">
          <div className="accept">
            <div className="accept-inner">
              <div className="accept-main__header">
                <IconConfirm />
                <span className="accept-main__header-title">
                  {t("register.accept.title")}
                </span>
                <div
                  className="d-flex align-items-center"
                  onClick={() => setShow(false)}
                >
                  <IconClose className="accept-main__header-close" />
                </div>
              </div>
              <section className="accept-main">
                <div className="accept-main__form">
                  <div className="accept-main__card">
                    <p className="accept-main__card-description">
                      {t("register.accept.description")}
                    </p>
                    <input
                      className={`accept-main__card-input ${
                        error?.name && "error"
                      }`}
                      value={value.name}
                      onChange={(e) =>
                        setValue({ ...value, name: e.target.value })
                      }
                      placeholder="Enter your first name*"
                    />
                    <div className="accept-main__card-label">
                      {t("register.accept.name")}
                      {error?.name && (
                        <div className="accept-main__card-error">
                          {t("register.error1")}
                        </div>
                      )}
                    </div>
                    <input
                      className={`accept-main__card-input ${
                        error?.phone && "error"
                      }`}
                      type="number"
                      value={value.phone}
                      onChange={(e) =>
                        setValue({ ...value, phone: e.target.value })
                      }
                      placeholder="Enter your phone number*"
                    />
                    <div className="accept-main__card-label">
                      {t("register.accept.phone")}
                      {error?.phone && (
                        <div className="accept-main__card-error">
                          {error.phone /*t("register.error1")*/}
                        </div>
                      )}
                    </div>
                    <input
                      className={`accept-main__card-input ${
                        error?.email && "error"
                      }`}
                      value={value.email}
                      onChange={(e) =>
                        setValue({ ...value, email: e.target.value })
                      }
                      placeholder="Enter your email*"
                    />
                    <div className="accept-main__card-label">
                      {t("register.accept.email")}
                      {error?.email && (
                        <div className="accept-main__card-error">
                          {t("register.error1")}
                        </div>
                      )}
                    </div>
                    {event_by_hash.open_event_number_of_guests_required ===
                      1 && (
                      <AcceptSelect
                        type="number"
                        setValue={setValue}
                        options={getNumberOptions(
                          event_by_hash.open_event_number_of_guests_max,
                        )}
                        value={value}
                      />
                    )}
                    {event_by_hash.open_event_number_of_guests_required ===
                      1 && (
                      <div className="accept-main__card-label">
                        {t("register.accept.number")}
                        {error?.number && (
                          <div className="accept-main__card-error">
                            {t("register.error1")}
                          </div>
                        )}
                      </div>
                    )}
                    {event_by_hash.questions.data.length > 0 && (
                      <p className="accept-main__card-description">
                        {t("register.accept.questions")}
                      </p>
                    )}
                    {event_by_hash.questions.data.map((item, index) => {
                      if (item.type === 0) {
                        return textQuestion(item.question_text, index, item.id)
                      } else if (item.type === 1) {
                        return numberQuestion(
                          item,
                          item.question_text,
                          index,
                          item.id,
                        )
                      } else if (item.type === 2) {
                        return dropdownQuestion(
                          item,
                          item.question_text,
                          index,
                          item.id,
                        )
                      } else if (item.type === 3) {
                        return urlQuestion(item.question_text, index, item.id)
                      }
                    })}
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-dark" onClick={onSendHandler}>
                      {t("buttons.send")}
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AcceptModal
