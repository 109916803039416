import enpoints from "../endpoints"
import { axiosInstance } from "../instance"
import axios, { AxiosPromise } from "axios"
import {
  ICreatePovSubscription,
  CheckoutStripeSessionParams,
} from "@/components/Payments/types"

// Assuming you have an axios instance set up. If not, you can use axios directly.
const axiosInstancePov = axios.create({
  baseURL: "https://api.revenuecat.com/v1",
  headers: {
    Authorization: `Bearer sk_wMYCLmuOITSRZhakUZrboOXYmodLu`,
    "Content-Type": "application/json",
  },
})

export const getPovSubscription = (userEmail: string) => {
  const encodedEmail = encodeURIComponent(userEmail)
  return axiosInstancePov.get(
    `/subscribers/mazoomSubscription-@${encodedEmail}`,
  )
}

// If you still need the old endpoint, you can keep it as well
export const createPovSubscription = (params: ICreatePovSubscription) => {
  return axiosInstance.post(enpoints.SUBSCRIPTION.PURCHASE_REVENUE_CAT, params)
}

// export const getPovSubscription = () => {
//   return axiosInstance.get(`${enpoints.SUBSCRIPTION.PURCHASE_REVENUE_CAT}`)
// }

export const createStripeCheckoutSession = (
  params: CheckoutStripeSessionParams,
) => {
  return axiosInstance.post(
    enpoints.SUBSCRIPTION.CREATE_STRIPE_CHECKOUT_SESSION,
    params,
  )
}


