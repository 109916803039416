
    const API_KEY = import.meta.env.VITE_REACT_APP_GOOGLE_API_KEY;

    // Create the Google Maps script element
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap&v=weekly&libraries=places`;
    script.defer = true;

    // Append the script to the body
    document.body.appendChild(script);
