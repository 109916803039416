import { useState, useEffect, SyntheticEvent } from "react"
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import Spinner from "react-bootstrap/Spinner"
import {
  IPhoneStepOneRequest,
  IPhoneStepTwoRequest,
} from "@/components/Auth/types"
import { Link } from "react-router-dom"
import {
  IconAlertCircle,
  IconArrowLeft,
} from "../../components/utils/Icons/CustomIcons"
import { loginPhoneStepThree } from "@/redux/slices/auth/authSlice"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import PhoneInput from "react-phone-input-2"
import { Button } from "react-bootstrap"
import Preloader from "@/components/utils/Preloader/Preloader"
import {
  StringInput,
  // DateEvent,
  // ChooseFileEvent,
  FileDropzone,
  FileDropzoneWithIcon,
  // DateInput,
  SelectInput,
  GoogleAutocompleteInput,
} from "@/components/common/formElements"
import { NewDateEvent } from "@/components/EditEvent/DateEvent/NewDateEvent"
import { TimeOnlyPicker } from "@/components/EditEvent/DateEvent/TimeOnlyPicker"
import { DateOnlyPicker } from "@/components/EditEvent/DateEvent/DateOnlyPicker"
import SuccessMessage from "./SuccessMessage"
import FailedMessage from "./FailedMessage"
import api from "@/api/api"
import moment from "moment"
import Subscription from "./Subscription"
import BackToLogin from "./BackToLogin"
dayjs.extend(utc)
import Message from "../Auth/Message"
import { getContactsOnEventMobile } from "@/redux/slices/contacts/contactsSlices"
const SuffixOptions = [
  { value: "Mr", label: "Mr", id: 0 },
  { value: "Mrs", label: "Mrs", id: 1 },
  { value: "Dr", label: "Dr", id: 2 },
  { value: "المكرمة", label: "المكرمة", id: 3 },
  { value: "المكرم", label: "المكرم", id: 4 },
  { value: "الاستاذ", label: "الاستاذ", id: 5 },
]
// import { error } from "console"

const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
// Define Zod validation schema for event form

// Define Zod validation schema for WhatsApp Form
const whatsappSchema = z.object({
  phone: z
    .string()
    .min(9, { message: "Enter a valid WhatsApp number" })
    .regex(/^[0-9]+$/, "Invalid number"),
})

// Define the Zod schema for OTP validation
const otpSchema = z.object({
  otp: z
    .array(z.string().length(1, { message: "Must be 1 character" }))
    .length(3, { message: "Must enter 3 digits" }),
})
type OtpFormData = {
  otp: string[]
}

// type IPhoneStepOneRequest = z.infer<typeof whatsappSchema>

const NewCreateEventPage = () => {
  const { t } = useTranslation()
  const [imageWidth, setImageWidth] = useState("230px")
  const [imageHeight, setImageHeight] = useState("206px")

  useEffect(() => {
    const updateImageWidth = () => {
      if (window.innerWidth <= 576) {
        setImageWidth("300px") // Width for extra-small screens
      } else if (window.innerWidth <= 800) {
        setImageWidth("200px") // Width for small screens
      } else if (window.innerWidth <= 900) {
        setImageWidth("210px") // Width for small screens
      } else if (window.innerWidth <= 1200) {
        setImageWidth("220px") // Width for small screens
      } else if (window.innerWidth <= 1300) {
        setImageWidth("280px") // Width for small screens
      } else {
        setImageWidth("330px") // Width for medium and larger screens
        setImageHeight("206px")
      }
    }

    // Set initial width
    updateImageWidth()

    // Update width on window resize
    window.addEventListener("resize", updateImageWidth)
    return () => window.removeEventListener("resize", updateImageWidth)
  }, [])

  const eventType = [
    { label: `${t("event.wedding")}`, value: 0 },
    { label: `${t("event.engagement")}`, value: 1 },
    { label: `${t("event.graduation")}`, value: 2 },
    { label: `${t("event.corporateEvent")}`, value: 3 },
    { label: `${t("event.opening")}`, value: 4 },
    { label: `${t("event.birthday")}`, value: 5 },
    { label: `${t("event.newBorn")}`, value: 6 },
    { label: `${t("event.newHouse")}`, value: 7 },
    { label: `${t("event.familyOrFriendsGathering")}`, value: 8 },
    { label: `${t("event.nationalHolidayParty")}`, value: 9 },
    { label: `${t("event.reunion")}`, value: 10 },
    { label: `${t("event.other")}`, value: 11 },
  ]
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const file = useAppSelector((state) => state.localFile.file)
  const [imageOd, setImageId] = useState(null)
  const [eventId, setEventId] = useState(null)
  const [userId, setUserId] = useState<number | null>(null)
  const [step, setStep] = useState(1)
  const [eventData, setEventData] = useState<EventFormData | null>(null)
  const [whatsappData, setWhatsAppData] = useState<IPhoneStepOneRequest | null>(
    null,
  )
  const [nameData, setNameData] = useState<NameFormData | null>(null)
  const [loadingOtp, setLoadingOtp] = useState(false)
  const eventSchema = z.object({
    image: z
      .any()
      .refine((files) => files && files.length > 0, {
        message: t("event.formSchemas.imageIsRequired"),
      })
      .refine(
        (files) => {
          if (!files || files.length === 0) return true // Skip if no file is provided
          const file = files[0] // Since multiple is false, only one file is expected
          const validTypes = ["image/jpeg", "image/png", "image/jpg"]
          return validTypes.includes(file?.type)
        },
        {
          message: "Allowed formats are JPG, PNG, JPEG.",
        },
      )
      .refine(
        (files) => {
          if (!files || files.length === 0) return true // Skip if no file is provided
          const file = files[0]
          return file?.size <= 5 * 1024 * 1024 // 5MB size limit
        },
        {
          message: "Image size should be less than 5 MB.",
        },
      ),
    // eventName: z.string().min(1, "Event name is required"),
    eventName: z.string().min(1, t("event.formSchemas.eventNameIsRequired")),

    location: z
      .object({
        address: z.string().nonempty("Address is required."),
        latitude: z.number().refine((val) => !isNaN(val), {
          message: "Latitude must be a valid number.",
        }),
        longitude: z.number().refine((val) => !isNaN(val), {
          message: "Longitude must be a valid number.",
        }),
        name: z.string().nonempty("Name is required."),
      })
      .refine(
        (data) => {
          // Check if all fields are valid
          return !!(
            data.address &&
            data.latitude &&
            data.longitude &&
            data.name
          )
        },
        {
          message: "All fields are required and must be valid.",
        },
      ),

    // start_datetime: z
    //   .date()
    //   .refine(  // eventDate: z
    //   .date()
    //   .refine(
    //     (val) => !isNaN(new Date(val).getTime()), // check if it's a valid date
    //     {
    //       message: "Invalid date format",
    //     },
    //   )
    //   .transform((val) => new Date(val)),
  })
  type EventFormData = z.infer<typeof eventSchema>

  const nameSchema = z.object({
    // firstName: z.string().min(1, t("event.formSchemas.firstNameIsRequired")),
    // lastName: z.string().min(1, t("event.formSchemas.lastNameIsRequired")),
    name: z.string().min(1, t("event.formSchemas.nameIsRequired")),
    suffix: z.string().min(1, t("event.formSchemas.suffixIsRequired")),
  })
  type NameFormData = z.infer<typeof nameSchema>
  const WhatsAppForm = ({
    onNext,
    setStep,
  }: {
    onNext: (data: IPhoneStepOneRequest) => void
    setStep: (data: number) => void
  }) => {
    const [loadingWhatsap, setLoadingWhatsap] = useState(false)
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<IPhoneStepOneRequest>({
      resolver: zodResolver(whatsappSchema),
    })

    // Handle form submission
    const onSubmit = async (total: IPhoneStepOneRequest) => {
      try {
        setLoadingWhatsap(true)
        // setSteps("whatsapNumber")
        // const data = eventData
        const resSent = await api.auth.loginPhoneStepOneNewUser(total)
        setLoadingWhatsap(false)

        console.log("resSent-resSent", resSent)
        if (resSent.status == 200 && resSent?.data?.new_user == true) {
          console.log("whatsap sent")
          onNext(total)
        } else {
          // onNext(total)

          toast.error("Already registered user please login")

          setStep(9)
        }
      } catch (err) {
        setLoadingWhatsap(false)

        console.error("Error while creating event:", err)
        toast.error("Error while sending otp")
      }
    }

    return (
      <div className=" d-flex align-items-center justify-content-center ">
        <div
          className=" bg-white p-5  align-items-center justify-content-center "
          style={{ maxWidth: "500px" }}
        >
          <div className="  ">
            <Button
              className="d-flex align-items-center "
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "none",
              }}
              onClick={() => setStep(1)}
            >
              <IconArrowLeft />
              <span
                className="reminders-page__link-text"
                style={{ fontSize: "17px" }}
              >
                {t("buttons.backNew")}
                {/* Back */}
              </span>
            </Button>
          </div>
          <div className="flex flex-column align-items-center  d-flex align-items-center justify-content-center p-3 ">
            <img
              src="/images/whatsapp.jpg"
              width="50px"
              height="50px"
              alt=""
              className="d-flex justify-content-center align-items-center"
            />
            <h3 className="mt-3 fs-3 text-center fw-normal text-dark">
              {t("event.addWhatsAppNumber")}
            </h3>

            <h6 className="mt-3 fs-9 text-center text-muted opacity-75 fw-light">
              {t("event.weWillSendTheCode")}
            </h6>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    country={"sa"} // Default country
                    placeholder={"999-99-99"}
                    inputStyle={{
                      fontSize: "16px", // Increase font size
                      height: "50px", // Increase height
                      paddingLeft: "40px",
                      margin: "5px",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      borderBottom: "1px solid #ced4da",
                      backgroundColor: "transparent", // Make input background transparent
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent", // Make flag background transparent
                      border: "none", // Remove border if needed
                      marginRight: "2px",
                      height: "50px", // Increase flag button height
                      fontSize: "16px", // Increase font size for the flag
                    }}
                    value={field.value}
                    onChange={field.onChange} // Hook into react-hook-form
                  />
                )}
              />
              {errors.phone && (
                <span className=" error mt-1">{errors.phone.message}</span>
              )}
              {/* <Button type="submit" className="mt-5 w-100 bg-dark border-0">
                Verify
              </Button> */}
              {loadingWhatsap ? (
                <div className="mt-4 btn btn-dark w-100 d-flex flex-column justify-content-center align-items-center ">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                      Loading...
                      {t("common.loading")}
                    </span>
                  </Spinner>
                </div>
              ) : (
                <Button
                  type="submit"
                  className="mt-4 w-100 bg-dark border-0"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("event.verify")}
                </Button>
              )}{" "}
            </form>
          </div>

          <div></div>
        </div>
      </div>
    )
  }
  const EventForm = ({ onNext }: { onNext: (data: EventFormData) => void }) => {
    const methods = useForm<EventFormData>({
      resolver: zodResolver(eventSchema),
    })
    const [uploadedImage, setUploadedImage] = useState<string | null>(null)

    const onSubmit: SubmitHandler<EventFormData> = async (data: any) => {
      onNext(data)

      // try {
      //   // setSteps("whatsapNumber")
      //   const formData = new FormData()
      //   formData.append("file", data?.image[0])
      //   // const imgRes = await api.events.loadImgEvent({ file: data?.image[0] })
      //   // console.log("imgRes", imgRes)

      //   // if (imgRes.status === 200) {
      //   //   const imageId = imgRes.data.data?.id
      //   //   setImageId(imageId)

      //   //   // Step 2: Prepare and send event creation data
      //   //   const formatDate = moment(new Date()).format("Y-MM-DD")
      //   //   const formatTime = moment(new Date()).format("HH:mm")

      //   //   const eventValues = {
      //   //     orientation: "v",
      //   //     name: data?.eventName,
      //   //     simplified: true,
      //   //     host_name: "host",
      //   //     event_created_date: formatDate,
      //   //     event_created_time: formatTime,
      //   //     address: {
      //   //       address: data?.location?.address,
      //   //       lat: data?.location?.latitude,
      //   //       lng: data?.location?.longitude,
      //   //     },
      //   //     text_style: {
      //   //       font_family: "Oxygen, sans-serif",
      //   //       font_size: "2x 1",
      //   //       font_style: {
      //   //         textBold: false,
      //   //         textItalic: false,
      //   //         textUnderline: false,
      //   //       },
      //   //       color: "#000",
      //   //       text_align: "center",
      //   //     },
      //   //     text_position: "flex-start",
      //   //     start_datetime: dayjs
      //   //       .utc(data?.start_datetime)
      //   //       .format("YYYY-MM-DD HH:mm:ss"),
      //   //     venue: data?.location?.name,
      //   //     language: "en",
      //   //     type: data?.eventType,
      //   //     image_id: imageId, // Use the uploaded imageId here
      //   //     invitation_show_qr_code: true,
      //   //   }

      //   //   const eventRes = await api.events.createEvent(eventValues)
      //   //   console.log("eventRes", eventRes)
      //   //   if (eventRes.status === 200) {
      //   //     const eventId = eventRes.data.data.event_id
      //   //     setEventId(eventId)

      //   //     // Step 3: Set event design using the created eventId
      //   //     const eventImag = await api.events.eventDesign({
      //   //       event_id: eventId,
      //   //       pattern_id: imageId,
      //   //     })
      //   //     console.log("eventImag", eventImag)
      //   //     if (eventImag?.status == 200) {
      //   //       toast.success("Event created successfully")
      //   //     }
      //   //   }
      //   // }
      // } catch (err) {
      //   console.error("Error while creating event:", err)
      //   toast.error("Error while creating event")
      // }
    }
    return (
      <div className="container mb-10 ">
        <div className="mb-3 mt-0">
          <Link
            className="d-flex align-items-center reminders-page__link-container"
            to="/welcome"
          >
            <IconArrowLeft />
            <span className="reminders-page__link-text">
              {t("buttons.backNew")}
            </span>
          </Link>
        </div>
        {/* <h2 className="caption-two">{t("event.titleCreate")}</h2> */}
        <h2 className="caption-two mb-2">{t("event.createASampleEvent")}</h2>
        <h6 className="caption-three text-muted">
          {t("event.toCreateASampleEventToSeeHowMazoomWorks")}
        </h6>

        {/* <div className="createEventForm bl-default "> */}
        <div
          className="mt-4 p-4 d-flex flex-column bg-white p-4"
          style={{ borderRadius: "15px" }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {/* <div className="d-flex flex-column flex-md-row gap-4 "> */}
              <div className="container w-full d-flex  flex-wrap flex-column flex-md-row gap-4 ">
                <div className="col-12 col-md-4 top-0 align-items-top d-flex justify-content-center">
                  {uploadedImage ? (
                    <div style={{ borderRadius: "10px", position: "relative" }}>
                      <img
                        style={{
                          borderRadius: "15px",
                          // height: "206px",
                          height: imageHeight,
                          width: imageWidth,
                          // Responsive width that adjusts to the container
                          // minWidth: "230px", // Limits the width to 230px on larger screens
                          objectFit: "cover", // Ensures the image covers the entire container, cropping overflow
                          objectPosition: "center",
                          // maxWidth: "300px",
                          // width: imageWidth,
                        }}
                        src={uploadedImage}
                        alt="Uploaded File"
                      />
                      <div
                        className="position-absolute  bottom-0 end-0 me-3 mb-3  bg-white"
                        style={{ width: "40px" }}
                      >
                        <FileDropzoneWithIcon
                          name="image"
                          label="Upload Image"
                          accept="image/*"
                          multiple={false}
                          setImage={setUploadedImage} // Pass the function to set the uploaded image
                        />
                      </div>
                    </div>
                  ) : (
                    <FileDropzone
                      name="image"
                      label="Upload Image"
                      accept="image/*"
                      multiple={false}
                      setImage={setUploadedImage} // Pass the function to set the uploaded image
                    />
                  )}
                </div>
                <div
                  className="col-12 col-md-7 d-flex flex-lg-row flex-wrap gap-4 align-items-center"
                  style={{ gap: "1px" }}
                >
                  <div className="col-12 col-md-5 mb-3">
                    <StringInput
                      name="eventName"
                      label={t("event.eventName")}
                      placeholder={t("event.eventName")}
                    />
                  </div>

                  {/* <div className="col-12 col-md-5 mb-4">
                    <NewDateEvent
                      name="start_datetime" // The name of the field
                      label={t("event.eventDate")} // The label to display
                    />
                  </div> */}

                  {/* <div
                    className="col-12 col-md-5  "
                    style={{ marginTop: "7px" }}
                  >
                    <SelectInput
                      name="eventType"
                      label={t("event.eventType")}
                      options={eventType}
                    />
                  </div> */}

                  {/* <div className="col-12 col-md-5 mb-2">
                    <DateOnlyPicker
                      name="eventDate"
                      label={t("event.selectDate")}
                    />
                  </div> */}
                  <div className="col-12 col-md-5 mb-4">
                    <GoogleAutocompleteInput
                      name="location"
                      label={t("event.eventLocation")}
                    />
                  </div>
                  {/* <div className="col-12 col-md-5 mb-2 ">
                    <TimeOnlyPicker
                      name="eventTime"
                      label={t("event.selectStartTime")}
                    />
                  </div> */}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  // onClick={(e) => onSubmit(e, "")}
                  className="btn btn-dark"
                >
                  {t("buttons.tryTheInvite")}
                </button>
              </div>
              {/* </div> */}
            </form>
          </FormProvider>
        </div>
      </div>
    )
  }

  const NameForm = ({
    onNext,
    setStep,
  }: {
    onNext: (data: NameFormData) => void
    setStep: (data: number) => void
  }) => {
    const [loadingName, setLoadingName] = useState(false)

    const methods = useForm<NameFormData>({
      resolver: zodResolver(nameSchema),
    })
    console.log("methods", methods)
    // Handle form submission
    const onSubmit = async (total: NameFormData) => {
      onNext(total)
    }

    return (
      <div className=" d-flex align-items-center justify-content-center ">
        <div
          className=" bg-white p-3 align-items-center justify-content-center "
          style={{ maxWidth: "500px" }}
        >
          <div className="  ">
            <Button
              className="d-flex align-items-center "
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "none",
              }}
              onClick={() => setStep(1)}
            >
              <IconArrowLeft />
              <span
                className="reminders-page__link-text"
                style={{ fontSize: "17px" }}
              >
                {t("buttons.backNew")}
                {/* Back */}
              </span>
            </Button>
          </div>
          <div className="w-100 flex flex-column align-items-center  d-flex align-items-center justify-content-center p-3 ">
            <h3 className="mt-3 fs-3 text-center fw-normal text-dark">
              {t("event.pleaseEnterTheEssentialInformationOfTheGuest")}
            </h3>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="w-100 p-3"
              >
                <div className="col-12 mb-5   " style={{ marginTop: "7px" }}>
                  <SelectInput
                    name="suffix"
                    label={t("event.suffix")}
                    options={SuffixOptions}
                  />
                </div>
                <div className="col-12  mb-5 mt-5 ">
                  <StringInput
                    name="name"
                    label={t("event.name")}
                    placeholder={t("event.name")}
                  />
                </div>

                {/* <div className="col-12  mb-5">
                  <StringInput
                    name="lastName"
                    label={t("event.lastName")}
                    placeholder={t("event.lastName")}
                  />
                </div> */}

                {/* <Button type="submit" className="mt-5 w-100 bg-dark border-0">
                Verify
              </Button> */}

                <Button type="submit" className="mt-4 w-100 bg-dark border-0">
                  {t("buttons.tryTheInvite")}
                </Button>
              </form>
            </FormProvider>
          </div>

          <div></div>
        </div>
      </div>
    )
  }

  // Component definition
  const OtpVerification = ({
    onVerify,
    setStep,
  }: {
    onVerify: (otp: string) => void
    setStep: (data: number) => void
  }) => {
    const [resendLoading, setResendLoading] = useState(false)
    const [cooldown, setCooldown] = useState(60) // Cooldown in seconds

    // Setup the useForm hook with Zod resolver
    const {
      control,
      handleSubmit,
      setValue,
      getValues,

      watch,
      setError,
      formState: { errors },
    } = useForm<OtpFormData>({
      resolver: zodResolver(otpSchema),
      defaultValues: {
        otp: Array(3).fill(""),
      },
    })
    const otpValues = watch("otp")

    // Function to handle OTP resend
    const handleResend = async () => {
      setResendLoading(true)
      setCooldown(60) // Reset cooldown timer to 60 seconds

      try {
        // Simulate API call for resending OTP
        await new Promise((resolve) => setTimeout(resolve, 2000)) // Simulate 2-second delay
        const resSent = await api.auth.loginPhoneStepOne({
          phone: whatsappData?.phone ? whatsappData?.phone : null,
        })

        if (resSent.status === 200) {
          toast.success("OTP code sent successfully")
        }
        setResendLoading(false)

        // After successful resend, reset loading state
      } catch (error) {
        console.error("Error resending OTP:", error)
        setResendLoading(false)
      }
    }

    // Handle form submission
    const onSubmit = async (data: OtpFormData) => {
      setLoadingOtp(true)
      const otpFinal = {
        phone: whatsappData?.phone ? whatsappData?.phone : null,
        otp: data.otp.join(""),
      }
      try {
        // Simulate OTP verification delay
        // const otpResponse: any = await api.auth.loginPhoneStepThree(otpFinal)
        dispatch(loginPhoneStepThree(otpFinal)).then((data: any) => {
          if (data?.payload?.result === 5 && data?.payload?.user != null) {
            const userId = localStorage.setItem(
              "usersId",
              data?.payload?.user?.id,
            )
            setUserId(data?.payload?.user?.id)
            // setStep(4)
            setStep(8)
            onVerify("265252")

            // toast.success("valid OTP")
            ////login them in
          } else {
            setLoadingOtp(false)
            toast.error("Invalid OTP")
          }
        })
        // console.log("otpResponse slice", otpResponse)

        // setTimeout(() => {
        //   setLoadingOtp(false)
        // }, 3000)
      } catch (err) {
        setLoadingOtp(false)
        console.error("Error while verifying OTP:", err)
      }
    }

    // Handle input change and auto-submit if all fields are filled
    const handleChange = (index: number, value: string) => {
      if (value.length <= 1) {
        setValue(`otp.${index}`, value.replace(/\D/g, "")) // Only allow numbers

        if (value && index < 2) {
          document.getElementById(`otp-input-${index + 1}`)?.focus()
        }
      }

      const currentOtpValues = getValues("otp")
      if (currentOtpValues.every((digit) => digit !== "")) {
        const timeoutId = setTimeout(() => {
          handleSubmit(onSubmit)()
        }, 400) // Set timeout here directly

        return () => clearTimeout(timeoutId)
      }
    }

    // Countdown for resend cooldown
    useEffect(() => {
      if (cooldown > 0) {
        const timer = setTimeout(() => setCooldown((prev) => prev - 1), 1000)
        return () => clearTimeout(timer)
      }
    }, [cooldown])
    const handlePaste = (event: React.ClipboardEvent) => {
      const pasteData = event.clipboardData.getData("text")
      if (/^\d{3}$/.test(pasteData)) {
        // If the pasted data matches the 8-digit OTP format, fill in the OTP inputs
        pasteData.split("").forEach((char, index) => {
          setValue(`otp.${index}`, char, {
            shouldValidate: true,
            shouldDirty: true,
          })
        })
      } else {
        setError("otp", { message: "Invalid OTP format" })
      }
    }

    return (
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="bg-white p-5 align-items-center justify-content-center"
          style={{ maxWidth: "500px" }}
        >
          <div>
            <button
              className="d-flex align-items-center"
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "none",
              }}
              onClick={() => setStep(2)}
            >
              <IconArrowLeft />
              <span
                className="reminders-page__link-text "
                style={{ fontSize: "15px" }}
              >
                {t("buttons.backNew")}
              </span>
            </button>
          </div>
          <div className="flex flex-column align-items-center d-flex align-items-center justify-content-center p-3">
            <h3 className="mt-4 fs-3 text-center fw-normal text-dark">
              {t("event.pleaseEnterACode")}
            </h3>
            <h6
              className="mt-4 text-center text-muted opacity-75 fw-light"
              style={{ fontSize: "17px" }}
            >
              {t("event.weSentACodeTo")}
              <span className="fw-bold text-dark">{whatsappData?.phone}</span>
            </h6>
            <form
              className="d-flex justify-content-center mt-4"
              onPaste={handlePaste}
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <Controller
                  key={index}
                  name={`otp.${index}` as const}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      id={`otp-input-${index}`}
                      type="text"
                      onChange={(e) => handleChange(index, e.target.value)}
                      // value={otpValues[index]} // Ensure the value is correctly set from `watch`
                      // value={field.value} // Use field.value to ensure the input is correctly controlled

                      onKeyDown={(e) => {
                        if (
                          e.key === "Backspace" &&
                          !field.value &&
                          index > 0
                        ) {
                          document
                            .getElementById(`otp-input-${index - 1}`)
                            ?.focus()
                        }
                      }}
                      style={{
                        width: "40px",
                        height: "40px",
                        fontSize: "20px",
                        textAlign: "center",
                        margin: "0 5px",
                        border: "1px solid #ced4da",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                />
              ))}
            </form>
            {errors.otp && (
              <span className="error mt-1">
                {t("event.pleaseFillAllTheFields")}
                {/* Please fill all the fields */}
              </span>
            )}
            {loadingOtp && (
              <div className="mt-4 w-100 d-flex flex-column justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            <h6
              className="mt-4 text-center text-muted opacity-75 fw-light"
              style={{ fontSize: "15px" }}
            >
              {/* The code will expire in {cooldown}  */}
              {t("event.theCodeWillExpireIn")}
              {cooldown} {t("event.seconds")}
            </h6>
            <h6
              className="mt-4 text-center text-muted opacity-75 fw-light"
              style={{ fontSize: "15px", color: "#000000" }}
            >
              {t("event.didntReceiveTheCode")}
              <button
                className="ms-2 mt-3 border-0 fw-bold"
                style={{
                  background: "none",
                  padding: 0,
                  cursor: cooldown === 0 ? "pointer" : "not-allowed",
                  font: "inherit",
                  textDecoration: "underline",
                  color: "#000000",
                }}
                onClick={handleResend}
                disabled={resendLoading || cooldown > 0}
              >
                {resendLoading
                  ? t("resending")
                  : cooldown > 0
                  ? `${t("event.resendIn")} in ${cooldown}s`
                  : t("event.sendTheCodeAgain")}
              </button>
            </h6>
          </div>
        </div>
      </div>
    )
  }
  const callInvite = async (params: any) => {
    let globalStatus = 0
    try {
      // Add a short delay to ensure data propagation if needed
      await new Promise((resolve) => setTimeout(resolve, 1000))

      const inviteResponse = await api.events.sendTestInvitationTwo(params)
      // console.log("userId-before answer", userId)
      const id = localStorage.getItem("usersId")
      try {
        const params = {
          userId: id,
          answersId: quizAnswers,
        }
        if (quizAnswers) {
          const quiz = await api.events.quizAnswers(params)
          localStorage.removeItem("quizAnswers")
        }
      } catch (err) {
        console.log("err", err)
      }

      await new Promise((resolve) => setTimeout(resolve, 1000))
      let checkInviteStatus: any = ""
      while (globalStatus == 0) {
        checkInviteStatus = await api.events.getTestWhasappInvitation(
          params?.event_id,
        )
        if (
          checkInviteStatus?.status == 200 &&
          checkInviteStatus?.data?.data?.whatsapp_invitation_status == 0
        ) {
          globalStatus = 0
        } else {
          globalStatus = 6
          // setLoadingOtp(false)
          // setStep(6)
        }
      }

      if ([200, 201, 202, 203].includes(inviteResponse?.status)) {
        if (
          checkInviteStatus?.status == 200 &&
          (checkInviteStatus?.data?.data?.whatsapp_invitation_status == 1 ||
            checkInviteStatus?.data?.data?.whatsapp_invitation_status == 2 ||
            checkInviteStatus?.data?.data?.whatsapp_invitation_status == 3 ||
            checkInviteStatus?.data?.data?.whatsapp_invitation_status == 4)
        ) {
          setLoadingOtp(false)
          setStep(5)
        } else {
          setLoadingOtp(false)
          setStep(6)
        }
      } else {
        setLoadingOtp(false)
        setStep(6)
      }
    } catch (err) {
      setLoadingOtp(false)
      setStep(6)
    }
  }
  const handleEventNext = (data: EventFormData) => {
    setEventData(data)
    setStep(2)
  }

  const handleWhatsAppNext = (data: IPhoneStepOneRequest) => {
    setWhatsAppData(data)
    setStep(3)
  }
  const handleNameNext = async (name: NameFormData) => {
    setNameData(name)
    setStep(4)

    try {
      // setSteps("whatsapNumber")
      const data = eventData
      const formData = new FormData()
      // formData.append("file", data?.image[0])
      const imgRes = await api.events.loadImgEvent({ file: data?.image[0] })
      console.log("imgRes", imgRes)
      // console.log("imgRes", imgRes)
      if (imgRes.status === 200) {
        const imageId = imgRes.data.data?.id
        setImageId(imageId)
        // Step 2: Prepare and send event creation data
        const formatDate = moment(new Date()).format("Y-MM-DD")
        const formatTime = moment(new Date()).format("HH:mm")

        const eventValues = {
          orientation: "v",
          name: data?.eventName,
          simplified: true,
          host_name: "host",
          event_created_date: formatDate,
          event_created_time: formatTime,
          address: {
            address: data?.location?.address,
            lat: data?.location?.latitude,
            lng: data?.location?.longitude,
          },
          text_style: {
            font_family: "Oxygen, sans-serif",
            font_size: "2x 1",
            font_style: {
              textBold: false,
              textItalic: false,
              textUnderline: false,
            },
            color: "#000",
            text_align: "center",
          },
          text_position: "flex-start",
          // start_datetime: dayjs
          //   .utc(data?.start_datetime)
          //   .format("YYYY-MM-DD HH:mm:ss"),
          venue: data?.location?.name,
          language: "ar",
          // type: data?.eventType,
          image_id: imageId, // Use the uploaded imageId here
          invitation_show_qr_code: true,
        }

        const eventRes = await api.events.createEvent(eventValues)

        if (eventRes.status === 200) {
          const eventId = eventRes.data.data.event_id
          setEventId(eventId)

          // Step 3: Set event design using the created eventId
          const eventDesign = await api.events.eventDesign({
            event_id: eventId,
            pattern_id: imageId,
          })

          if (eventDesign?.status == 200) {
            // send the test invitation to
            const params = {
              event_id: Number(eventId),
              phone: whatsappData?.phone,
            }

            const testParams = {
              event_id: Number(eventRes.data.data.event_id),
              // event_id: 2564,
              suffix: name?.suffix,
              first_name: name?.name,
              last_name: "",
              phone: whatsappData?.phone,
            }

            // callInvite(testParams)
            await callInvite(testParams)
          } else {
            console.log("process three faild")
            setLoadingOtp(false)
            setStep(6)
          }
        } else {
          console.log("process two faild")
          setLoadingOtp(false)
          setStep(6)
        }
      } else {
        console.log("1 process faild")
        setLoadingOtp(false)
        setStep(6)
      }
    } catch (err) {
      setLoadingOtp(false)
      setStep(6)

      console.error("Error while creating event:", err)
      // toast.error("Error while sending the  event")
    }
  }

  let quizAnswers: any
  const storedAnswers = localStorage.getItem("quizAnswers")
  if (storedAnswers) {
    quizAnswers = JSON.parse(storedAnswers)
  } else {
    quizAnswers = [] // or set to an appropriate default value
  }

  const handleOtpVerify = async (otp: string) => {
    // You can add your OTP validation logic here
    // try {
    //   // setSteps("whatsapNumber")
    //   const data = eventData
    //   const formData = new FormData()
    //   // formData.append("file", data?.image[0])
    //   const imgRes = await api.events.loadImgEvent({ file: data?.image[0] })
    //   console.log("imgRes", imgRes)
    //   // console.log("imgRes", imgRes)
    //   if (imgRes.status === 200) {
    //     const imageId = imgRes.data.data?.id
    //     setImageId(imageId)
    //     // Step 2: Prepare and send event creation data
    //     const formatDate = moment(new Date()).format("Y-MM-DD")
    //     const formatTime = moment(new Date()).format("HH:mm")
    //     const eventValues = {
    //       orientation: "v",
    //       name: data?.eventName,
    //       simplified: true,
    //       host_name: "host",
    //       event_created_date: formatDate,
    //       event_created_time: formatTime,
    //       address: {
    //         address: data?.location?.address,
    //         lat: data?.location?.latitude,
    //         lng: data?.location?.longitude,
    //       },
    //       text_style: {
    //         font_family: "Oxygen, sans-serif",
    //         font_size: "2x 1",
    //         font_style: {
    //           textBold: false,
    //           textItalic: false,
    //           textUnderline: false,
    //         },
    //         color: "#000",
    //         text_align: "center",
    //       },
    //       text_position: "flex-start",
    //       // start_datetime: dayjs
    //       //   .utc(data?.start_datetime)
    //       //   .format("YYYY-MM-DD HH:mm:ss"),
    //       venue: data?.location?.name,
    //       language: "ar",
    //       // type: data?.eventType,
    //       image_id: imageId, // Use the uploaded imageId here
    //       invitation_show_qr_code: true,
    //     }
    //     const eventRes = await api.events.createEvent(eventValues)
    //     if (eventRes.status === 200) {
    //       const eventId = eventRes.data.data.event_id
    //       setEventId(eventId)
    //       // Step 3: Set event design using the created eventId
    //       const eventDesign = await api.events.eventDesign({
    //         event_id: eventId,
    //         pattern_id: imageId,
    //       })
    //       if (eventDesign?.status == 200) {
    //         // send the test invitation to
    //         const params = {
    //           event_id: Number(eventId),
    //           phone: whatsappData?.phone,
    //         }
    //         const testParams = {
    //           event_id: Number(eventRes.data.data.event_id),
    //           // event_id: 2564,
    //           suffix: "Mrs",
    //           first_name: "Sample Guest",
    //           last_name: "Invite",
    //           phone: whatsappData?.phone,
    //         }
    //         // callInvite(testParams)
    //         await callInvite(testParams)
    //       } else {
    //         console.log("process three faild")
    //         setLoadingOtp(false)
    //         setStep(6)
    //       }
    //     } else {
    //       console.log("process two faild")
    //       setLoadingOtp(false)
    //       setStep(6)
    //     }
    //   } else {
    //     console.log("1 process faild")
    //     setLoadingOtp(false)
    //     setStep(6)
    //   }
    // } catch (err) {
    //   setLoadingOtp(false)
    //   setStep(6)
    //   console.error("Error while creating event:", err)
    //   // toast.error("Error while sending the  event")
    // }
    // toast.success("Event submitted successfully!")
    // Reset steps after submission or navigate as needed
    // setStep(1)
    // setEventData(null)
    // setWhatsAppData(null)
  }

  return (
    <div className="createEventPage">
      {/* event step */}

      {step === 1 && <EventForm onNext={handleEventNext} />}
      {step === 2 && (
        <>
          <WhatsAppForm onNext={handleWhatsAppNext} setStep={setStep} />
        </>
      )}
      {step === 3 && (
        <>
          {/* <OtpVerification onVerify={handleOtpVerify} setStep={setStep} /> */}
          <OtpVerification onVerify={handleOtpVerify} setStep={setStep} />
        </>
      )}

      {step == 4 && (
        <div>
          {" "}
          <Preloader />
        </div>
      )}

      {step == 5 && (
        <SuccessMessage
          phoneNumber={whatsappData?.phone ? whatsappData?.phone : ""}
          setStep={setStep}
        />
      )}
      {step == 6 && (
        <FailedMessage
          phoneNumber={whatsappData?.phone ? whatsappData?.phone : ""}
          setStep={setStep}
        />
      )}
      {step == 7 && <Subscription />}

      {step == 8 && <NameForm onNext={handleNameNext} setStep={setStep} />}

      {step == 9 && <BackToLogin />}

      <ToastContainer
        style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
      />
    </div>
  )
}

export default NewCreateEventPage
