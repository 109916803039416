import { KeyboardEvent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import socketio from "socket.io-client"
import Echo from "laravel-echo"
import {
  getConversation,
  getMessagesWithParams,
  markAsRead,
  sendMessage,
  setMessages,
  setPage,
} from "../../redux/slices/messages/messagesSlice"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import Preloader from "../../components/utils/Preloader/Preloader"
import {
  IconArrowBottomFat,
  IconArrowLeftFat,
  IconArrowMessage,
  IconPlus,
} from "../../components/utils/Icons/CustomIcons"
import MessageCard from "../../components/Messages/MessageCard"
import ChooseUser from "../../components/Messages/ChooseUser/ChooseUser"
import Dialog from "../../components/Messages/Dialog"
import { getContactsOnEvent } from "../../redux/slices/contacts/contactsSlices"

type Message = {
  id: number
  name: string
  not_read_received_messages_count: number
  conversations: { data: [] }
}

type Conversations = {
  contact: {
    data: {
      email: string
      first_name: string
      id: number
      last_name: string
      phone: string
      suffix: string
    }
  }
  created_at: string
  id: number
  message: string
  not_read_received_messages_count: number
  read_status: number
  send_from_whatsapp: boolean
  whatsapp_message_can_be_sent: boolean
}

const MessagesPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const messages = useAppSelector((state) => state.messages.data)
  const page = useAppSelector((state) => state.messages.page)
  const conversations = useAppSelector((state) => state.messages.currentData)
  const id = useAppSelector((state) => state.messages.contactId)
  const { isLoading } = useAppSelector((state) => state.events)
  const isContactLoading = useAppSelector((state) => state.contacts.isLoading)

  const dialogRef = useRef<HTMLDivElement>(null)
  const eventsRef = useRef(null)
  const [show, setShow] = useState(false)
  const [name, setName] = useState("")
  const [right, setRight] = useState(false)
  const [showMessages, setShowMessages] = useState<any>([])
  const [eventId, setEventId] = useState<number>(0)
  const [contactId, setContactId] = useState<number>(0)
  const [value, setValue] = useState("")
  const [canSend, setCanSend] = useState(false)
  const { accessToken } = useAppSelector((state) => state.auth)

  useEffect(() => {
    const echo = new Echo({
      host: import.meta.env.VITE_REACT_APP_LARAVEL_ECHO_URL,
      broadcaster: "socket.io",
      client: socketio,
      encrypted: false,
      transports: ["websocket"],
      auth: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    })

    if (messages) {
      echo
        .channel(`private-quest-message.${612}`)
        .listen(".created", (ev: any) => {
          dispatch(setMessages(ev.message))
        })
    }

    return () => {
      if (messages) {
        echo.leaveChannel(`private-quest-message.${612}`)
      }
    }
  }, [id, messages])

  const scrollChat = () => {
    if (dialogRef.current) {
      dialogRef.current.scrollTop = dialogRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollChat()
    window.scrollTo(0, 1000)
  }, [conversations])

  const onSubmit = () => {
    if (canSend) {
      dispatch(
        sendMessage({ id: eventId, contactId: contactId, message: value }),
      ).then(() => dispatch(markAsRead(contactId)))
      setValue("")
      scrollChat()
    }
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      dispatch(
        sendMessage({ id: eventId, contactId: contactId, message: value }),
      ).then(() => dispatch(markAsRead(contactId)))
      setValue("")
      scrollChat()
    }
  }

  useEffect(() => {
    dispatch(getMessagesWithParams())
  }, [dispatch, page])

  useEffect(() => {
    let foundFirst = false
    messages &&
      setShowMessages(
        messages.map((item: any, index: number) => {
          if (item.conversations.data.length > 0) {
            foundFirst = true
            setEventId(
              item.conversations && item.conversations.data.length > 0
                ? item.id
                : 0,
            )
            setContactId(
              item.conversations && item.conversations.data.length > 0
                ? item.conversations.data[0].contact.data.id
                : 0,
            )
            dispatch(
              getConversation(
                item.conversations && item.conversations.data.length > 0
                  ? item.conversations.data[0].contact.data.id
                  : 0,
              ),
            )
            dispatch(
              markAsRead(
                item.conversations && item.conversations.data.length > 0
                  ? item.conversations.data[0].contact.data.id
                  : 0,
              ),
            )
            setName(
              item.conversations && item.conversations.data.length > 0
                ? `${item.conversations.data[0].contact.data.first_name} ${item.conversations.data[0].contact.data.last_name}`
                : ``,
            )
            item.conversations &&
              item.conversations.data.length > 0 &&
              setRight(true)
            item.conversations &&
              item.conversations.data.length > 0 &&
              window.scrollTo(0, 1000)
            scrollChat()
            setCanSend(item.conversations.data[0].whatsapp_message_can_be_sent)
            return { id: item.id, show: false }
          } else {
            return { id: item.id, show: true }
          }
        }),
      )
  }, [messages])

  const changeShow = (event: Message) => {
    showMessages.length > 0 &&
      setShowMessages(
        showMessages.map((item: { id: number; show: boolean }) =>
          item.id === event.id
            ? { ...item, show: item.show ? false : true }
            : item,
        ),
      )
  }

  const showModal = (event: Message) => {
    setShow(true)
    setEventId(event.id)
    dispatch(
      getContactsOnEvent({
        sort: "",
        order: "",
        searchValue: "",
        statusNumber: "",
        reminderNumber: "",
        id: Number(event.id),
      }),
    )
  }

  return (
    <>
      {isLoading && <Preloader />}
      <div className="container messages-wrapper">
        <div className="messages">
          <h2
            className={`caption-two messages-header ${
              right ? "messages-header__none" : ""
            }`}
          >
            {t("messages.title")}
          </h2>
          <div className="messages-container">
            <div
              ref={eventsRef}
              className={`messages-left ${right ? "messages-left__none" : ""}`}
            >
              {messages.map((event: Message) => {
                return (
                  <div
                    className="messages-section"
                    key={Number(event.id) + Number(contactId)}
                  >
                    <div
                      className="messages-section__header"
                      onClick={() => changeShow(event)}
                    >
                      <span className="messages-section__header-title">
                        {event.name}
                        <span
                          className={`${
                            showMessages.length > 0 &&
                            showMessages.find(
                              (item: any) => item.id === event.id,
                            ) !== undefined &&
                            showMessages.find(
                              (item: any) => item.id === event.id,
                            ).show === true &&
                            event.conversations.data.length !== 0 &&
                            event.not_read_received_messages_count > 0
                              ? "show"
                              : ""
                          } messages-section__msg`}
                        >
                          {event.not_read_received_messages_count}{" "}
                          {t("messages.new")}
                        </span>
                      </span>
                      <span
                        className={`messages-section__arrow ${
                          showMessages.length > 0 &&
                          showMessages.find(
                            (item: any) => item.id === event.id,
                          ) !== undefined &&
                          showMessages.find((item: any) => item.id === event.id)
                            .show === false
                            ? "show"
                            : ""
                        }`}
                      >
                        <IconArrowBottomFat />
                      </span>
                    </div>
                    <div
                      className={`messages-section__body ${
                        showMessages.length > 0 &&
                        showMessages.find(
                          (item: any) => item.id === event.id,
                        ) !== undefined &&
                        showMessages.find((item: any) => item.id === event.id)
                          .show === true
                          ? "show"
                          : ""
                      }`}
                    >
                      <a
                        className="btn btn-simple messages-section__button"
                        onClick={() => showModal(event)}
                      >
                        <IconPlus />
                        {t("messages.button")}
                      </a>
                      {event.conversations.data.length > 0 &&
                        event.conversations.data.map((item: Conversations) => {
                          return (
                            <MessageCard
                              key={item.id}
                              eventId={event.id}
                              id={item.contact.data.id}
                              avatar={"/vector/avatar3.svg"}
                              name={item.contact.data.first_name}
                              lastName={item.contact.data.last_name}
                              text={item.message}
                              time={item.created_at}
                              count={item.not_read_received_messages_count}
                              setEventId={setEventId}
                              setContactId={setContactId}
                              setRight={setRight}
                              scrollChat={scrollChat}
                              setName={setName}
                              send={item.whatsapp_message_can_be_sent}
                              setCanSend={setCanSend}
                            />
                          )
                        })}
                    </div>
                  </div>
                )
              })}
              {messages.length > 0 && (
                <button
                  className="messages-left__btn"
                  onClick={() => dispatch(setPage(page + 1))}
                >
                  Show more
                </button>
              )}
            </div>
            <div
              className={`${
                !right ? "messages-return__none" : ""
              } messages-return`}
            >
              <IconArrowLeftFat />
              <span
                className={`messages-return__text`}
                onClick={() => setRight(false)}
              >
                {t("messages.all")}
              </span>
            </div>
            {contactId !== 0 ? (
              <Dialog
                contactId={contactId}
                right={right}
                setRight={setRight}
                value={value}
                setValue={setValue}
                onSubmit={onSubmit}
                onKeyDown={onKeyDown}
                name={name}
                dialogRef={dialogRef}
                eventId={eventId}
                canSend={canSend}
              />
            ) : (
              <div className="messages-dialog-none">
                <div className="messages-dialog-none__container">
                  <img
                    src="/images/no-conversations.png"
                    alt="no-conversations"
                    className="messages-dialog-none__img"
                  ></img>
                  <p className="messages-dialog-none__text">
                    No conversations yet.
                  </p>
                  <p className="messages-dialog-none__text">
                    Select a contact from the list and start a conversation
                    right now!
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {isContactLoading ? (
          <Preloader />
        ) : (
          <ChooseUser
            show={show}
            setShow={setShow}
            eventId={eventId}
            setEventId={setEventId}
            setName={setName}
            setRight={setRight}
            setContactId={setContactId}
            scrollChat={scrollChat}
            setCanSend={setCanSend}
          />
        )}
      </div>
      <div
        className={`messages-send__mobile ${
          !right ? "messages-send__mobile-none" : ""
        }`}
      >
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => onKeyDown(e)}
          className="messages-send__input"
        />
        <span className="messages-send__btn" onClick={onSubmit}>
          <IconArrowMessage />
        </span>
      </div>
    </>
  )
}

export default MessagesPage
