import Select from "react-select"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@/redux/store"

const SelectLanguageEvent = ({
  placeholder,
  event,
  setEvent,
  error,
  value,
  handleChange,
  setLanguage,
}: any) => {
  const { t } = useTranslation()
  const { currentEvent } = useAppSelector((state) => state.events)

  const eventLanguage = [
    { value: "ar", label: "عرب", id: 1 },
    { value: "en", label: "English", id: 0 },
  ]

  return (
    <div className={error ? "select-event input-error" : "select-event"}>
      <Select
        // menuIsOpen={true}
        classNamePrefix="select-event"
        defaultValue={() => {
          currentEvent &&
            handleChange(
              eventLanguage.find((obj) => obj.value === currentEvent.language),
            )
          return (
            currentEvent &&
            eventLanguage.find((obj) => obj.value === currentEvent.language)
          )
        }}
        onChange={(val: any) => {
          handleChange(val.label)
          setLanguage(val.label)
          setEvent({ ...event, language: val.label })
        }}
        options={eventLanguage}
        styles={{
          option: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            position: "relative",
            backgroundColor: "#FFF",
            "&:active": {
              backgroundColor: "#FFF",
            },
            "&::after": {
              content: state.isSelected ? "url(/vector/check.svg)" : '""',
              position: "absolute",
              right: "10px",
              top: "calc(50% - 8px)",
              width: "16px",
              height: "16px",
            },
          }),
        }}
      />

      <label className={"active"} htmlFor="">
        {placeholder}
      </label>

      {error && <span className="input-item__error error mt-1">{error}</span>}
    </div>
  )
}

export default SelectLanguageEvent
