import enpoints from "../endpoints"
import { axiosInstance } from "../instance"

export const getContacts = (
  currentPage: number,
  limit: number,
  params: { sort: string; searchValue: string, order: string },
) => {
  let search = ""

  if (params.searchValue !== "") {
    search = `&search=first_name:${params.searchValue};last_name:${params.searchValue};phone:${params.searchValue}`
  }

  return axiosInstance.get(
    enpoints.CONTACTS.GET +
      `?page=${currentPage}${params.sort}${params.order}&limit=${limit}${search}`,
  )
}
export const getContactsNew = () => {
  return axiosInstance.get(enpoints.CONTACTS.GET)
}

export const getContactsOnEvent = (
  currentPage: number,
  limit: number,
  params: {
    sort: string
    order: string
    searchValue: string
    id: number
    statusNumber: any
    reminderNumber: any
  },
) => {
  let search = ""
  let status = ""
  let reminder = ""

  if (params.searchValue !== "") {
    search = `&search=${params.searchValue}`
  }

  if (params.statusNumber !== "") {
    status = `&statuses=${params.statusNumber}`
  }

  if (params.reminderNumber !== "") {
    reminder = `&free_reminder=${params.reminderNumber}`
  }

  return axiosInstance.get(
    enpoints.CONTACTS.GET_ON_EVENT +
      `${params.id}/contacts` +
      `?${params.order}${status}${params.sort}${reminder}&page=${currentPage}&page_size=${limit}${search}`,
  )
}

export const getContactsForMessages = (
  id: number,
  currentPage: number,
  limit: number,
) => {
  return axiosInstance.get(
    enpoints.CONTACTS.GET_ON_EVENT +
      `${id}/contacts?&page=${currentPage}&page_size=${limit}`,
  )
}

export const deleteContacts = (id: any) => {
  return axiosInstance.delete(enpoints.CONTACTS.GET, {
    params: { contacts: id },
  })
}

export const changeContact = (params: {
  id: number
  first_name: string
  last_name: string
  suffix: string
  email: string
  phone: string
}) => {
  let { id, first_name, last_name, suffix, email, phone } = params

  return axiosInstance.put(enpoints.CONTACTS.UP + `${id}`, {
    first_name,
    last_name,
    suffix,
    email,
    phone,
  })
}

export const addContact = (params: {
  first_name: string
  last_name: string
  suffix: string
  email: string
  phone: string
}) => {
  let { first_name, last_name, suffix, email, phone } = params

  return axiosInstance.post(enpoints.CONTACTS.CREATE, {
    first_name,
    last_name,
    suffix,
    email,
    phone,
    deleted_at: null,
  })
}
export const addContactNew = (params: {
  first_name: string
  last_name: string
  suffix: string
  email: string
  phone: string
}) => {
  let { first_name, last_name, suffix, email, phone } = params

  return axiosInstance.post(enpoints.CONTACTS.CREATE_NEW, {
    first_name,
    last_name,
    suffix,
    email,
    phone,
  })
}




export const importContacts = (params: any) => {
  return axiosInstance.post(enpoints.CONTACTS.IMPORT, params)
}

export const deleteGuest = (ids: any) => {
  return axiosInstance.delete(enpoints.CONTACTS.DELETE_GUEST, {
    params: { event_id: ids.eventId, contacts: ids.guestId },
  })
}

export const addGuest = (params: any) => {
  return axiosInstance.post(enpoints.CONTACTS.ADD_GUEST, params)
}

export const updateGuest = (params: any) => {
  return axiosInstance.put(enpoints.CONTACTS.UPDATE_GUEST, params)
}

export const importGuests = (params: any) => {
  return axiosInstance.post(enpoints.CONTACTS.IMPORT_GUEST, params)
}

export const importFromContacts = (params: any) => {
  return axiosInstance.post(enpoints.CONTACTS.IMPORT_FROM_CONTACTS, {
    event_id: params.event_id,
    contacts: params.contactsArr,
    groups: [],
  })
}
