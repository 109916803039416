import React from "react"
import { Image } from "react-konva"

interface URLImageProps {
  src: any
  x: number
  y: number
  color?: string
  funcDblClick?: any
  opacity?: any
  width?: any
  height?: any
}

const QRCodeImageButton: React.FC<URLImageProps> = ({
  src,
  x,
  y,
  funcDblClick,
  opacity,
  width,
  height
}) => {
  return (
    <Image
      opacity={opacity}
      width={width}
      height={height}
      x={x}
      y={y}
      image={src}
      onDblClick={funcDblClick}
      onDblTap={funcDblClick}
    />
  )
}

export { QRCodeImageButton }
