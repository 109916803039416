import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import { getEvent } from "../../redux/slices/events/eventsSlice"
import useDebounce from "../../hooks/useDebounce"
import {
  IconArrowLeft,
  IconBtnCreate,
  IconCheckbox,
  IconSearch,
} from "../../components/utils/Icons/CustomIcons"
import ActionsPopup from "./ActionsPopup"
import ActionModal from "./ActionModal/ActionModal"
import { importFromContacts } from "../../redux/slices/contacts/contactsSlices"
import HoverTooltip from "../Event/HoverTooltip"

const ContactsHead = ({
  filter,
  setFilter,
  setAddShow,
  setImportShow,
  addShow,
  checked,
  setChecked,
  notify,
  guests,
}: any) => {
  const { event_id } = useParams()
  const event = useAppSelector((state) => state.events.currentEvent)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState("")

  const debouncedSearchTerm = useDebounce(search, 500)

  useEffect(() => {
    dispatch(getEvent(Number(event_id)))
  }, [dispatch, event_id])

  useEffect(() => {
    if (debouncedSearchTerm) {
      setFilter({ ...filter, searchValue: search })
    }

    if (search === "") {
      setFilter({ ...filter, searchValue: search })
    }
  }, [debouncedSearchTerm])

  const importContact = () => {
    const array = checked.map((item: any) => ({
      contact_id: Number(item),
      quantity_members: 0,
    }))
    dispatch(
      importFromContacts({ event_id: Number(event_id), contactsArr: array }),
    ).then((result: any) => {
      if (result.error) {
        notify(result.payload)
        setChecked([])
      } else {
        setChecked([])
        setShowModal(!showModal)
      }
    })
  }

  const importContacts = () => {
    const array = guests.map((item: any) => ({
      contact_id: Number(item.id),
      quantity_members: 0,
    }))
    dispatch(
      importFromContacts({ event_id: Number(event_id), contactsArr: array }),
    ).then((result: any) => {
      if (result.error) {
        notify(result.payload)
        setChecked([])
      } else {
        setChecked([])
        setShowModal(!showModal)
      }
    })
  }

  return (
    <div className="d-flex flex-column select-contacts__header">
      <div className="event-page__link">
        <Link
          className="d-flex align-items-center event-page__link-container"
          to={`/${event_id}`}
        >
          <IconArrowLeft />
          <span className="event-page__link-text">
            Back to event {event?.name}
          </span>
        </Link>
      </div>
      <div className="contacts-head">
        <div className="contacts-head__title">
          <h2 className="caption">{t("contacts.title")}</h2>
          <div className="contacts-head__input">
            <div className="input-search">
              <input
                type="text"
                placeholder={t("placeholder.search")}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />

              <button className="input-search__btn">
                <IconSearch />
              </button>
            </div>
          </div>
        </div>

        <ActionsPopup
          show={addShow}
          setShow={setAddShow}
          setImportShow={setImportShow}
        />

        <div className="contacts-head__controls">
          {checked.length !== 0 ? (
            <a href="#" className="btn btn-simple" onClick={importContact}>
              <IconCheckbox />
              Add selected
            </a>
          ) : (
            <HoverTooltip text={"You need to select a contact"} id={"t-5"}>
              <a href="#" className="btn btn-simple">
                <IconCheckbox />
                Add selected
              </a>
            </HoverTooltip>
          )}

          <a href="#" className="btn btn-dark" onClick={importContacts}>
            <IconBtnCreate />
            Add all to the event
          </a>
        </div>
      </div>
      <ActionModal show={showModal} setShow={setShowModal} id={event_id} />
    </div>
  )
}

export default ContactsHead
