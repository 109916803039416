import { useAppSelector } from "@/redux/store"
import { useTranslation } from "react-i18next"
import {
  IconBattery,
  IconClose,
  IconFailed,
  IconFooterIcons,
  IconLock,
  IconRefresh,
  IconSignal,
  IconTextSize,
  IconTime,
  IconWifi,
} from "../../utils/Icons/CustomIcons"
import "./DeclinePhone.scss"

const DeclinePhone = () => {
  const { t } = useTranslation()

  return (
    <div className="decline-phone">
      <div className="phone">
        <div className="phone-inner">
          <section className="phone-header">
            <IconTime />
            <div className="phone-header__icons">
              <IconSignal />
              <IconWifi />
              <IconBattery />
            </div>
          </section>

          <section className="phone-main">
            <div className="phone-main__form">
              <div className="phone-main__header">
                <IconFailed />
                <span className="phone-main__header-title">
                  Decline the invitation
                </span>
                <IconClose className="phone-main__header-close" />
              </div>
              <div className="phone-main__card">
                <p className="phone-main__card-description">
                  You are about to decline the invitation. Please provide the
                  following information:
                </p>
                <p className="phone-main__card-input">Alfred</p>
                <p className="phone-main__card-label">Name*</p>
                <p className="phone-main__card-input">962021870187</p>
                <p className="phone-main__card-label">Phone number*</p>
                <p className="phone-main__card-input">alfreddavis@gmail.com</p>
                <p className="phone-main__card-label">Email</p>
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn btn-dark">Submit</button>
              </div>
            </div>
          </section>

          <section className="phone-footer">
            <div className="phone-footer__http">
              <IconTextSize />
              <IconLock />
              <span className="phone-footer__http-text">
                app.mazoom.sa/register/819
              </span>
              <IconRefresh />
            </div>
            <IconFooterIcons />
            <div className="phone-footer__http-bottom">
              <div className="phone-footer__http-line" />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default DeclinePhone
