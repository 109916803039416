import QRCode from "qrcode.react"

const endpoints = {
  AUTH: {
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT: "/user/password/forgot",
    PHONESTEP1: "/user/phone-number-login",
    PHONESTEP2: "/user/phone-number-check-otp",
    PROFILE: "/profile",
    GOOGLE: "/auth/google",
    CONFIRMED: "/events/user-confirmed",
    LOGOUT: "/logout",
    RESET: "/user/password/reset",
  },
  EVENTS: {
    GET: "/events",
    UPDATE: "/events",
    CREATE_QUESTIONS: "/open-events/questions",
    GET_QUESTIONS: "/open-events/questions",
    UPDATE_QUESTIONS: "/open-events/questions",
    DELETE_QUESTIONS: "/open-events/questions",
    CREATE_OPTIONS: "/open-events/questions",
    UPDATE_OPTIONS: "/open-events/questions/options",
    DELETE_OPTIONS: "/open-events/questions/options",
    SEND_SETTINGS: "/open-events/registration-settings",
    GET_EVENT_BY_HASH: "/open-events/info",
    COPY_EVENT: "/open-events/copy-event",
    REGISTER_ANSWER: "/open-events/register",
    DELETE: "/events",
    GET_EVENT: "/events/get",
    LOAD_IMG: "/upload",
    PREVIEW: "/event-preview",
    DESIGN: "/events/design",
    QR_DESIGN: "/settings/qr-code-designs",
    GET_QR: "/settings/qr-code-design-image",
    UPDATE_QR_DESIGN: "/settings/qr-code-designs",
    UPLOAD_QR_IMAGE: "/settings/qr-code-designs/",
    SEND_INVITATION_ALL: "/events/event-send-whatsapp-invitation-all",
    SEND_INVITATION: "https://api.whatsapp.com/send",
    TEST_THE_INVITATION: "/users/get-credits-for-phone-number-send-otp",
    VALIDATE_OTP: "/users/get-credits-for-phone-number-validate-otp",
    UPDATE_INVITES: "/events/",
    UPDATE_INVITE: "/events/contacts/quantity",
    SEND_QR: "/events/send-undelivered-qr-codes",
    SEND_WA_INVITE: "/events/event-send-whatsapp-invitation-bulk",
    GET_BALANCE: "/events/app/user-balance-new",
    SEND_REMINDER: "/events/send-reminder",
    SEND_THANK_YOU_MESSAGE: "/events/send-thank",
    SEND_TEST_INVITATION: "/events/test-invitation-by-host",
    SEND_TEST_INVITATION_TWO: "/events/test-invitation-by-host-onboarding",

    GET_TEST_INVITATION: "/events/test-invitation",

    GET_TEST_WHATSAPP_INVITATION: "/events/test-invitation-onboarding",

    GET_THANK_MESSAGE: "/thank-you-message-preview",
    CHECK_DISCOUNT: "/events/check-discount-code",
    GET_PRICING: "/app/events/pricing?os_id=0",
    CHECK_CHARGE: "/events/tap-check-charge",
    GET_ALL_IMAGES: "/pov/events",
    ENABLE_POV: "/pov/events",
    EVENT_BY_PHONE_NUMBER: "/events/invitation/contacts",
    SEND_RESPONSE_FOR_INVITE: "/events/invitation",
    QUESTIONS: "/questions",
    QUIZ_ANSWER: "/answer/submit",
  },
  CONTACTS: {
    GET: "/contacts",
    GET_ON_EVENT: "/events/",
    UP: "/contacts/",
    CREATE: "/create-contact",
    CREATE_NEW: "/contacts",

    IMPORT: "/contacts-import",
    ADD_GUEST: "/events/add-contact",
    UPDATE_GUEST: "/events/contacts",
    DELETE_GUEST: "/events/contacts/delete",
    IMPORT_GUEST: "/events/contacts/import",
    IMPORT_FROM_CONTACTS: "/events/contacts",
  },
  MESSAGES: {
    GET: "/events/messages",
    GET_CONVERSATION: "/events/conversation/",
    SEND_MESSAGE: "/events/respond-to-guest-via-whatsapp",
    MARK_AS_READ: "/messages/mark-all-as-read",
    SEND_MESSAGE_TO_OWNER_EVENT: "/events/message",
  },
  SUBSCRIPTION: {
    PURCHASE_REVENUE_CAT: "/subscriptions/revenue-cat/purchase",
    CREATE_STRIPE_CHECKOUT_SESSION: "/stripe/session/checkout",
  },
}

export default endpoints
