import { useEffect, useState } from "react"
import Select from "react-select"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  IconBalance,
  IconCheck,
  IconConfirm,
} from "@/components/utils/Icons/CustomIcons"
import { checkDiscount, getPricing } from "@/redux/slices/events/eventsSlice"
import "./CreditsPage.scss"

const TAP_PUBLISHABLE_KEY = import.meta.env.VITE_REACT_APP_TAP_PUBLISHABLE_KEY

type Pack =
  | {
      value: string
      label: string
      id: number
    }
  | ""

const CreditsPage = () => {
  const { discount, pricing } = useAppSelector((state) => state.events)
  const { balance, lang, first_name, last_name, email, phone } = useAppSelector(
    (state) => state.auth,
  )
  const [promo, setPromo] = useState("")
  const [pack, setPack] = useState<Pack>("")
  const [error, setError] = useState(false)

  const dispatch = useAppDispatch()

  function initGoSell() {
    goSell.config({
      gateway: {
        publicKey: TAP_PUBLISHABLE_KEY,
        merchantId: null,
        language: lang,
        contactInfo: true,
        supportedCurrencies: "all",
        supportedPaymentMethods: "all",
        saveCardOption: false,
        customerCards: true,
        notifications: "standard",
        callback: (response: any) => {
          console.log("response", response)
        },
        onClose: () => {
          goSell.closeModal()
        },
        labels: {
          cardNumber: "Card Number",
          expirationDate: "MM/YY",
          cvv: "CVV",
          cardHolder: "Name on Card",
          actionButton: "Pay",
        },
        style: {
          base: {
            color: "#535353",
            lineHeight: "18px",
            fontFamily: "sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "rgba(0, 0, 0, 0.26)",
              fontSize: "15px",
            },
          },
          invalid: {
            color: "red",
            iconColor: "#fa755a",
          },
        },
      },
      customer: {
        first_name: first_name,
        middle_name: null,
        last_name: last_name,
        email: email,
        phone: {
          country_code: phone?.substring(0, 3) || "965",
          number: phone?.substring(3) || "99999999",
        },
      },
      order: {
        discount:
          discount.discount && pack !== "" && pricing.length > 0
            ? discount.limit
              ? Math.min(discount.discount, discount.limit)
              : discount.discount
            : discount.discount_percent && pack !== "" && pricing.length > 0
            ? discount.limit
              ? Math.min(
                  (discount.discount_percent *
                    pricing.find((item: any) => item.id === pack.id).price) /
                    100,
                  discount.limit,
                )
              : (discount.discount_percent *
                  pricing.find((item: any) => item.id === pack.id).price) /
                100
            : 0,
        amount:
          pack !== "" && pricing.length > 0
            ? discount.discount
              ? discount.limit
                ? Math.max(
                    pricing.find((item: any) => item.id === pack.id).price -
                      discount.discount,
                    pricing.find((item: any) => item.id === pack.id).price -
                      discount.limit,
                  )
                : pricing.find((item: any) => item.id === pack.id).price -
                  discount.discount
              : discount.discount_percent
              ? discount.limit
                ? Math.max(
                    (pricing.find((item: any) => item.id === pack.id).price *
                      (100 - discount.discount_percent)) /
                      100,
                    pricing.find((item: any) => item.id === pack.id).price -
                      discount.limit,
                  )
                : (pricing.find((item: any) => item.id === pack.id).price *
                    (100 - discount.discount_percent)) /
                  100
              : pricing.find((item: any) => item.id === pack.id).price
            : 0,
        currency: "SAR",
        shipping: null,
        taxes: null,
      },
      transaction: {
        mode: "charge",
        charge: {
          saveCard: false,
          threeDSecure: true,
          description: "Mazoom credits",
          statement_descriptor: "statement_descriptor",
          metadata: {
            event_id: null,
            type: "credits",
            discount_code_id: discount.discount_id,
          },
          receipt: {
            email: true,
            sms: false,
          },
          redirect: `${window.location.origin}/credits/result`,
          post: `${
            import.meta.env.VITE_REACT_APP_API_BASE_URL
          }/ecards/payment-webhook`,
          hashstring: "",
        },
      },
    })
  }

  useEffect(() => {
    dispatch(getPricing())
  }, [dispatch])

  useEffect(() => {
    if (
      (discount.discount !== null || discount.discount_percent !== null) &&
      pack !== ""
    ) {
      setPack({
        ...pack,
        label: `${
          pricing.find((item: any) => item.id === pack.id).credits
        } credit(-s) for ${
          discount.discount
            ? discount.limit
              ? Math.max(
                  pricing.find((item: any) => item.id === pack.id).price -
                    discount.limit,
                  pricing.find((item: any) => item.id === pack.id).price -
                    discount.discount,
                ) > 0
                ? Math.max(
                    pricing.find((item: any) => item.id === pack.id).price -
                      discount.limit,
                    pricing.find((item: any) => item.id === pack.id).price -
                      discount.discount,
                  ) > 0
                : 0
              : pricing.find((item: any) => item.id === pack.id).price -
                  discount.discount >
                0
              ? pricing.find((item: any) => item.id === pack.id).price -
                discount.discount
              : 0
            : discount.discount_percent
            ? discount.limit
              ? Math.max(
                  pricing.find((item: any) => item.id === pack.id).price -
                    discount.limit,
                  (pricing.find((item: any) => item.id === pack.id).price *
                    (100 - discount.discount_percent)) /
                    100,
                )
              : (pricing.find((item: any) => item.id === pack.id).price *
                  (100 - discount.discount_percent)) /
                100
            : pricing.find((item: any) => item.id === pack.id).price
        } SAR`,
      })
    }
    console.log(pack)
  }, [discount.discount, discount.discount_percent])

  return (
    <>
      <div className="credits">
        <div className="container container-credits">
          <h2 className="caption-two credits-title">Credits</h2>
          <div className="credits-main">
            <div className="credits-main__balance credits-main__section">
              <h4 className="credits-main__title">Your balance: </h4>
              <div className="credits-main__balance-description">
                <span className="credits-main__balance-text">
                  {balance} credits
                </span>
                <IconBalance />
              </div>
            </div>
            <div className="credits-main__buy credits-main__section">
              <h4 className="credits-main__title">Buy credits:</h4>
              <Select
                // menuIsOpen={true}
                placeholder="Select package"
                options={
                  pricing.length > 0 &&
                  pricing.map((item: any) => {
                    return {
                      value: `${item.credits} credit(-s) for ${item.price} SAR`,
                      label: `${item.credits} credit(-s) for ${
                        discount.discount
                          ? discount.limit
                            ? Math.max(
                                item.price - discount.limit,
                                item.price - discount.discount,
                              ) > 0
                              ? Math.max(
                                  item.price - discount.limit,
                                  item.price - discount.discount,
                                )
                              : 0
                            : item.price - discount.discount > 0
                            ? item.price - discount.discount
                            : 0
                          : discount.discount_percent
                          ? discount.limit
                            ? Math.max(
                                item.price - discount.limit,
                                (item.price *
                                  (100 - discount.discount_percent)) /
                                  100,
                              )
                            : (item.price * (100 - discount.discount_percent)) /
                              100
                          : item.price
                      } SAR`,
                      id: item.id,
                    }
                  })
                }
                isSearchable={false}
                value={pack === "" ? "" : pack}
                onChange={(val: any) => {
                  setPack(val)
                  console.log(val)
                }}
                styles={{
                  container: (state: any) => ({
                    ...state,
                    outline: "none",
                    border: "0",
                    position: "relative",
                    boxShadow: "none",
                    "@media (max-width: 576px)": {
                      width: "100%",
                    },
                  }),
                  valueContainer: (state: any) => ({
                    ...state,
                    display: "flex",
                    justifyContent: "end",
                    padding: "0 10px 0 0",
                    "@media (max-width: 576px)": {
                      justifyContent: "start",
                      height:
                        (discount.discount !== null ||
                          discount.discount_percent !== null) &&
                        pack !== ""
                          ? "39px"
                          : "20px",
                    },
                  }),
                  placeholder: (state: any) => ({
                    ...state,
                    color: "#CDCDCD",
                    margin: "0",
                    fontSize: "16px",
                    lineHeight: "19px",
                    "@media (max-width: 1279px)": {
                      fontSize: "14px",
                      lineHeight: "17px",
                    },
                  }),
                  singleValue: (state: any) => ({
                    ...state,
                    color: "#0B0C0E",
                    fontSize: "16px",
                    lineHeight: "19px",
                    fontWeight: "500",
                    margin: "0",
                    position: "relative",
                    paddingTop:
                      (discount.discount !== null ||
                        discount.discount_percent !== null) &&
                      pack !== ""
                        ? "25px"
                        : "0",
                    "&::before": {
                      content:
                        (discount.discount !== null ||
                          discount.discount_percent !== null) &&
                        pack !== ""
                          ? `"${pack && pack.value}"`
                          : "''",
                      position: "absolute",
                      right: "0px",
                      top: "0",
                      textDecoration: "line-through",
                      fontSize: "12px",
                      lineHeight: "14.52px",
                      fontWeight: "500",
                      color: "#0B0C0E",
                      "@media (max-width: 1279px)": {
                        fontSize: "10px",
                        lineHeight: "12px",
                      },
                      "@media (max-width: 576px)": {
                        left: "0",
                      },
                    },
                    "@media (max-width: 1279px)": {
                      fontSize: "14px",
                      lineHeight: "17px",
                      paddingTop:
                        (discount.discount !== null ||
                          discount.discount_percent !== null) &&
                        pack !== ""
                          ? "22px"
                          : "0",
                    },
                    "@media (max-width: 576px)": {
                      lineHeight: "19.6px",
                    },
                  }),
                  control: (state: any) => ({
                    ...state,
                    fontSize: "14px",
                    lineHeight: "26px",
                    color: "#71706F",
                    outline: "none",
                    border: "none",
                    boxShadow: "none",
                    width: "304px",
                    minHeight:
                      (discount.discount !== null ||
                        discount.discount_percent !== null) &&
                      pack !== ""
                        ? "44px"
                        : "34px",
                    "@media (max-width: 1279px)": {
                      minHeight:
                        (discount.discount !== null ||
                          discount.discount_percent !== null) &&
                        pack !== ""
                          ? "39px"
                          : "20px",
                      height:
                        (discount.discount !== null ||
                          discount.discount_percent !== null) &&
                        pack !== ""
                          ? "39px"
                          : "20px",
                    },
                    "@media (max-width: 576px)": {
                      width: "100%",
                    },
                  }),
                  indicatorSeparator: (state) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (base: any, state) => ({
                    ...base,
                    transform: state.selectProps.menuIsOpen
                      ? "rotate(180deg)"
                      : null,
                    color: "#0B0C0E",
                    padding: "0",
                  }),
                  indicatorsContainer: (state: any) => ({
                    ...state,
                    "@media (max-width: 576px)": {
                      height:
                        (discount.discount !== null ||
                          discount.discount_percent !== null) &&
                        pack !== ""
                          ? "39px"
                          : "20px",
                    },
                  }),
                  option: (state: any) => ({
                    ...state,
                    fontSize: "16px",
                    padding: "11px 10px",
                    lineHeight: "22.4px",
                    color: "#0B0C0E",
                    backgroundColor: state.isSelected ? "#F2F4F7" : "#FFF",
                    "&:hover": {
                      background: "#F2F4F7",
                    },
                    "@media (max-width: 1279px)": {
                      fontSize: "14px",
                      lineHeight: "20px",
                      padding: "12px 10px",
                    },
                  }),
                  menu: (state: any) => ({
                    ...state,
                    width: "304px",
                    margin: "10px 0 0",
                    "@media (max-width: 1279px)": {
                      width: "298px",
                    },
                    "@media (max-width: 576px)": {
                      width: "calc(100% - 2px)",
                      margin: "10px 1px 0 1px",
                    },
                  }),
                  menuList: (state: any) => ({
                    ...state,
                    height: "264px",
                    overflow: "scroll",
                    scrollbarColor: "rgba(109, 109, 110, 0.5)",
                    padding: "0",
                    "@media (max-width: 1279px)": {
                      height: "220px",
                    },
                  }),
                }}
              />
            </div>
            <div className="credits-main__method credits-main__section">
              <h4 className="credits-main__title">Payment method:</h4>
              <p className="credits-main__method-description">Tap payments</p>
            </div>
            <div className="credits-main__promo credits-main__section">
              <h4 className="credits-main__title">Promo code:</h4>
              {discount.discount !== null ||
              discount.discount_percent !== null ? (
                <div className="credits-main__promo-success">
                  <p className="credits-main__promo-description">
                    Discount applied
                  </p>
                  <IconConfirm />
                </div>
              ) : (
                <div
                  className={`credits-main__promo-code ${
                    promo !== "" && error === true ? "not" : ""
                  }`}
                >
                  <input
                    className={`credits-main__promo-input ${
                      promo !== "" && error === true ? "not" : ""
                    }`}
                    onChange={(e) => {
                      setPromo(e.target.value)
                      if (promo === "") {
                        setError(false)
                      }
                    }}
                  />
                  <button
                    className="btn btn-simple"
                    onClick={() => {
                      dispatch(checkDiscount(promo)).then((res: any) => {
                        if (res.error) {
                          setError(true)
                        } else {
                          setError(false)
                        }
                      })
                    }}
                  >
                    <IconCheck color="dark" />
                  </button>
                </div>
              )}
            </div>
            {pack === "" ? (
              <OverlayTrigger
                containerPadding={3}
                overlay={
                  <Tooltip className="credits-tooltip">
                    You need to select a package
                  </Tooltip>
                }
              >
                <button className="btn btn-dark credits-btn unactive">
                  Buy credits
                </button>
              </OverlayTrigger>
            ) : (
              <button
                className="btn btn-dark credits-btn"
                onClick={() => {
                  initGoSell()
                  setTimeout(() => {
                    goSell.openPaymentPage()

                    setTimeout(() => {
                      goSell.openPaymentPage()
                    }, 2000)
                  })
                }}
              >
                Buy credits
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CreditsPage
