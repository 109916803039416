import { Modal } from "react-bootstrap"
import { useAppDispatch } from "../../../redux/store"
import { useParams } from "react-router-dom"
import { sendQR } from "../../../redux/slices/events/eventsSlice"
import { toast } from "react-toastify"
import styles from "./SendQRModal.module.scss"

const SendQRModal = ({
  show,
  setShow,
  title,
  type,
  buttonRight,
}: any) => {
  const dispatch = useAppDispatch()
  const { event_id } = useParams()

  const notify = (text: string, color: boolean) => {
    color ?
    toast.error(text, {
      position: "top-right",
      autoClose: 3000,
    }) :
    toast.success(text, {
      position: "top-right",
      autoClose: 3000,
    })
  }

  const onSubmit = () => {
    dispatch(sendQR(event_id))
    setShow(!show)
    notify("Undeliverable QR codes sent", false)
  }

  return (
    <Modal
      className={`${styles.addPopup} ${
        type === "delete"
          ? "event-page__delete-modal"
          : "event-page__invite-modal"
      }`}
      show={show}
      onHide={() => setShow(!show)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>{title}</h2>

          <div className={styles.controls}>
            <span className="btn btn-simple" onClick={onSubmit}>
              Yes
            </span>
            <button onClick={() => setShow(!show)} className="btn btn-dark">
              {buttonRight === "Continue" ? "Continue" : "No"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SendQRModal
