import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import api from "../../../api/api"

// Types
export interface ISubscriptionDetails {
  expires_date: string
  grace_period_expires_date: string | null
  product_identifier: string
  purchase_date: string
}

export interface ISubscriptionInfo {
  auto_resume_date: string | null
  billing_issues_detected_at: string | null
  expires_date: string
  grace_period_expires_date: string | null
  is_sandbox: boolean
  original_purchase_date: string
  period_type: string
  purchase_date: string
  refunded_at: string | null
  store: string
  store_transaction_id: string
  unsubscribe_detected_at: string | null
}

export interface IPaymentInitState {
  isSubscriptionLoading: boolean
  errorSubscription: string | null
  isSubscriptionCreatingLoading: boolean
  errorCreateSubscription: string | null
  currentPovPlan: string | null
  currentPovPlanDetails: string | null
  entitlements: {
    [key: string]: ISubscriptionDetails
  }
  subscriptions: {
    [key: string]: ISubscriptionInfo
  }
  request_date: string
  request_date_ms: number
}

export interface ICreatePovSubscription {
  povPlan: string
}

// Default basic plan
const defaultBasicPlan: { [key: string]: ISubscriptionDetails } = {
  basic_pov_plan: {
    expires_date: "2025-10-26T06:58:09Z",
    grace_period_expires_date: null,
    product_identifier: "prod_QupGp5JLtQEu6v",
    purchase_date: "2024-09-26T06:58:09Z",
  },
}

// Async Thunks
export const subscribePov = createAsyncThunk(
  "subscription/povSubscription",
  async (payload: ICreatePovSubscription, { rejectWithValue }) => {
    try {
      const response = await api.payments.createPovSubscription(payload)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Something went wrong")
    }
  },
)

export const getPovSubscription = createAsyncThunk(
  "subscription/getPovSubscription",
  async (userEmail: string, { rejectWithValue }) => {
    try {
      const res = await api.payments.getPovSubscription(userEmail)
      return res.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Something went wrong")
    }
  },
)

// Initial State
const initialState: IPaymentInitState = {
  currentPovPlanDetails: "",
  currentPovPlan: "basic_pov_plan",
  isSubscriptionLoading: false,
  errorSubscription: null,
  isSubscriptionCreatingLoading: false,
  errorCreateSubscription: null,
  entitlements: defaultBasicPlan,
  subscriptions: {},
  request_date: "",
  request_date_ms: 0,
}

// Slice
const paymentSubscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscribePov.pending, (state) => {
        state.isSubscriptionCreatingLoading = true
        state.errorCreateSubscription = null
      })
      .addCase(subscribePov.fulfilled, (state, action: PayloadAction<any>) => {
        state.isSubscriptionCreatingLoading = false
        state.errorCreateSubscription = null
        // Handle the response if needed
      })
      .addCase(subscribePov.rejected, (state, action: PayloadAction<any>) => {
        state.isSubscriptionCreatingLoading = false
        state.errorCreateSubscription = action.payload
      })
      .addCase(
        getPovSubscription.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isSubscriptionLoading = false
          state.errorSubscription = null

          const entitlements = action.payload.subscriber?.entitlements
          if (entitlements && Object.keys(entitlements).length > 0) {
            const currentDate = new Date()
            let activeEntitlements: { [key: string]: ISubscriptionDetails }[] =
              []

            for (const [planType, entitlement] of Object.entries(
              entitlements as { [key: string]: ISubscriptionDetails },
            )) {
              const expiryDate = new Date(entitlement.expires_date)
              if (expiryDate > currentDate) {
                activeEntitlements.push({
                  [planType]: entitlement as ISubscriptionDetails,
                })
              }
            }

            if (activeEntitlements.length > 0) {
              // Sort active entitlements by purchase date, most recent first
              activeEntitlements.sort((a, b) => {
                const dateA = new Date(Object.values(a)[0].purchase_date)
                const dateB = new Date(Object.values(b)[0].purchase_date)
                return dateB.getTime() - dateA.getTime()
              })

              const planHierarchy = [
                "basic_pov_plan",
                "standard_pov_plan",
                "unlimited_pov_plan",
              ]

              if (activeEntitlements.length === 1) {
                // Only one active plan
                const activePlan = Object.keys(activeEntitlements[0])[0]
                state.entitlements = activeEntitlements[0]
                state.currentPovPlan = activePlan
                state.currentPovPlanDetails = null
              } else if (activeEntitlements.length === 2) {
                const plan1 = Object.keys(activeEntitlements[0])[0]
                const plan2 = Object.keys(activeEntitlements[1])[0]
                const newerPlan = plan1
                const olderPlan = plan2

                if (
                  planHierarchy.indexOf(newerPlan) >
                  planHierarchy.indexOf(olderPlan)
                ) {
                  // Upgrade scenario
                  state.entitlements = activeEntitlements[0]
                  state.currentPovPlan = newerPlan
                  state.currentPovPlanDetails = null
                } else {
                  // Downgrade scenario
                  const higherPlan =
                    planHierarchy.indexOf(plan1) > planHierarchy.indexOf(plan2)
                      ? plan1
                      : plan2
                  const lowerPlan = higherPlan === plan1 ? plan2 : plan1
                  const higherPlanExpiry = new Date(
                    activeEntitlements.find(
                      (e) => Object.keys(e)[0] === higherPlan,
                    )![higherPlan].expires_date,
                  )

                  state.entitlements = activeEntitlements.find(
                    (e) => Object.keys(e)[0] === higherPlan,
                  )!
                  state.currentPovPlan = higherPlan

                  const formattedLowerPlan =
                    lowerPlan == "standard_pov_plan"
                      ? "Standard Package"
                      : lowerPlan == "unlimited_pov_plan"
                      ? "Unlimited Package"
                      : "Basic Package "

                  state.currentPovPlanDetails = `You will be downgraded to ${formattedLowerPlan} after ${higherPlanExpiry.toDateString()}`
                }
              }
            } else {
              state.entitlements = defaultBasicPlan
              state.currentPovPlan = "basic_pov_plan"
              state.currentPovPlanDetails = null
            }
          } else {
            state.entitlements = defaultBasicPlan
            state.currentPovPlan = "basic_pov_plan"
            state.currentPovPlanDetails = null
          }

          state.subscriptions = action.payload.subscriber?.subscriptions || {}
          state.request_date = action.payload.request_date
          state.request_date_ms = action.payload.request_date_ms
        },
      )

    // ... rest of the code ...
  },
})

export default paymentSubscriptionSlice.reducer
