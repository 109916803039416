import { useState } from "react"
import { Link } from "react-router-dom"
import {
  IconAlertCircle,
  IconArrowLeft,
} from "../../components/utils/Icons/CustomIcons"
import { Database } from "lucide-react"
import { Card, Button, Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import noInvitation from "@/assets/images/noInvitation.svg" // Adjust the path as necessary

const NoInviteFound = () => {
  const { t } = useTranslation()
  return (
    <div className="container mt-1">
      <div className="d-flex flex-column align-items-center justify-content-center gap-3 mt-3">
        <div className="d-flex flex-column  ">
          <img src={noInvitation} width="180px" height="auto" />
        </div>{" "}
        <h3 className="mb-1 ">{t("event.noInvitationsYet")}</h3>
        <h6 className="fw-light me-3 ms-3 text-muted">
          {t("event.unfortunatelyYouDontHaveInvitationsYet")}
        </h6>
        <Link to="/">
          <Button
            className="mt-2 bg-dark text-dark text-white border-0"
            style={{ width: "250px" }}
          >
            {t("event.backToHome")}
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default NoInviteFound
