import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Card, Button, Row, Col } from "react-bootstrap"
import WelcomeQuiz from "./WelcomeQuiz"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { useTranslation } from "react-i18next"
import Skeleton from "@/components/common/skeleton"
import tryMazoom from "@/assets/images/tryMazoom.svg" // Adjust the path as necessary

const Welcome = () => {
  const [isLoading, setIsLoading] = useState(true)

  const handleImageLoad = () => {
    setIsLoading(false)
  }

  const isQuiz = useFeatureFlagEnabled("onboarding_test_group")
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClickRedirect = () => {
    console.log("Redirecting to /guest/create-event")
    if (isQuiz) {
      navigate("/quiz")
    } else {
      navigate("/guest/create-event")
    }
  }
  return (
    <div className="container mt-1">
      <div
        style={{
          // width: "400px",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div className="border-rounded bg-white p-3 d-flex flex-column align-items-center justify-content-center gap-3 mt-3">
          {isLoading && (
            <Skeleton width="190px" height="150px" borderRadius="8px" />
          )}
          <img
            src={tryMazoom}
            width="180px"
            height="auto"
            style={{
              minHeight: "150px",
              minWidth: "100px",
              display: isLoading ? "none" : "block",
            }}
            onLoad={handleImageLoad}
            alt="Welcome Home"
          />
          {/* <h3 className="mb-1 ">{t("event.welcomeToMazoomApp")}</h3> */}
          <h3 className="mb-1 fs-4   text-black ">
            {t("event.welcomeToMazoomApp")}
          </h3>
          <h6 className="fw-light me-3 ms-3 text-muted">
            {t("event.weHaveAFewQuestionsToLearnAboutYouAndYourGoals")}
          </h6>
          <div className="d-flex flex-column gap-2 w-100 p-2">
            {/* <Link to="/guest/create-event" className="w-100"> */}
            <Button
              className="w-100 bg-dark text-dark text-white border-0"
              onClick={handleClickRedirect}
            >
              {t("event.tryTheApp")}
            </Button>
            {/* </Link> */}
            <Link to="/whatsapp-login" className="w-100">
              <Button
                className="w-100 bg-light text-dark text-dark"
                style={{ border: "1px solid #000000" }}
              >
                {t("event.seeMyInvites")}{" "}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
const WelcomeHome = () => {
  const isQuiz = useFeatureFlagEnabled("onboarding_test_group")

  // if (isQuiz) {
  //   return <WelcomeQuiz />
  // } else {
  //   return <Welcome />
  // }
  return <Welcome />
}

export default WelcomeHome
