import { Modal } from "react-bootstrap"
import styles from "./AboutModal.module.scss"

const AboutModal = ({ show, setShow }: any) => {
  const onHide = () => {
    setShow(!show)
  }

  return (
    <Modal className={`${styles.testModal} about-modal`} show={show} onHide={onHide}>
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`${styles.caption}`}>About open event</h2>
          <p className={styles.text}>
            Open event description should be here...
          </p>
          <div className={styles.section}>
            <ul>
              <p className={styles.title}>Free Version:</p>
              <li className={styles.element}>Up to 100 registrations</li>
              <li className={styles.element}>One question to a guest</li>
            </ul>
            <ul>
              <p className={styles.title}>Premium account:</p>
              <li className={styles.element}>Up to 10 000 registrations</li>
              <li className={styles.element}>Up to 10 questions</li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AboutModal
