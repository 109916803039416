import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  IconAlertCircle,
  IconArrowLeft,
  IconClose,
} from "../../components/utils/Icons/CustomIcons"
import { Database } from "lucide-react"
import { Card, Button, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
const Subscription = () => {
  const [activeCard, setActiveCard] = useState(0)
  const { t } = useTranslation()
const navigate = useNavigate()
const location = useLocation()

const handleCardClick = (cardIndex: any) => {
  setActiveCard(cardIndex)
}


  // Three separate lists of features
  const itemsBasic = [
    t("subscription.sendInvitesThroughWhatsappOrEmail"),
    t("subscription.rsvp"),
    t("subscription.uniqueQrCodeForEachGuest"),
    t("subscription.eventLocation"),
    t("subscription.scannerApp"),
  ]

  const itemsSpecial = [
    t("subscription.allFeaturesOfTheBasicPackage"),
    t("subscription.invitationManagement"),
    t("subscription.subAccount"),
    t("subscription.replaceDeclinesForFree"),
    t("subscription.scanningManagementAtTheEvent"),
  ]

  const itemsPremium = [
    t("subscription.allFeaturesOfTheBasicPackage"),
    t("subscription.accountManager"),
    t("subscription.usingAPrivateNumber"),
    t("subscription.globalEventLocation"),
    t("subscription.invitationCustomizationOptions"),
  ]

  return (
    <div className="container mt-3" style={{ minHeight: "80vh" }}>
      <div className="mb-3">
        <Link
          className="d-flex align-items-center reminders-page__link-container"
          to="/"
        >
          <IconClose />
          <span className="reminders-page__link-text">
            {t("subscription.close")}
          </span>
        </Link>
      </div>
      <h3 className="mb-3">{t("subscription.pricingAndServices")}</h3>
      <h6 className="fw-normal fs-7 mb-4">
        {t("subscription.selectYourTariffPlan")}
      </h6>
      {/* <div className="d-flex gap-2 mb-4 mt-2">
        <Button
          onClick={() => setActiveCard(0)}
          style={{
            backgroundColor: activeCard === 0 ? "#263238" : "white",
            color: activeCard === 0 ? "white" : "black",
            cursor: "pointer",
            border: "1px solid grey",
          }}
        >
          Basic
        </Button>
        <Button
          onClick={() => setActiveCard(1)}
          style={{
            backgroundColor: activeCard === 1 ? "#263238" : "white",
            color: activeCard === 1 ? "white" : "black",
            cursor: "pointer",
            border: "1px solid grey",
          }}
        >
          Special
        </Button>
        <Button
          onClick={() => setActiveCard(2)}
          style={{
            backgroundColor: activeCard === 2 ? "#263238" : "white",
            color: activeCard === 2 ? "white" : "black",
            cursor: "pointer",
            border: "1px solid grey",
          }}
        >
          Premium
        </Button>
      </div> */}

      <Row>
        {/* Basic Plan */}
        <Col xs={12} md={6} lg={4} className="mb-4">
          <Card
            onClick={() => handleCardClick(0)}
            style={{
              // backgroundColor: activeCard === 0 ? "#263238" : "white",
              // color: activeCard === 0 ? "white" : "black",
              backgroundColor: "white",
              // color: activeCard === 0 ? "white" : "black",
              cursor: "pointer",
            }}
          >
            <Card.Body className="d-flex flex-column">
              <Card.Title className="mb-0 mb-lg-3 ">
                <div className="d-flex justify-content-between">
                  <h6 className="fs-4">{t("subscription.basic")}</h6>
                  {/* <div className="d-flex align-items-center">
                    <h6 className="me-2 fs-6 mt-2">
                      {t("subscription.yourPlan")}
                    </h6>

                    <div
                      style={{
                        width: "19px",
                        height: "19px",
                        borderRadius: "50%",
                        backgroundColor: activeCard === 0 ? "#ffffff" : "",
                        border: `2px solid ${
                          activeCard === 0 ? "#ffffff" : "#000000"
                        }`,
                        marginRight: "10px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {activeCard === 0 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          fill="black"
                          viewBox="0 0 16 16"
                          style={{ position: "absolute" }}
                        >
                          <path d="M4.5 8.5l2.5 2.5 5-5a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414z" />
                        </svg>
                      )}
                    </div>
                  </div> */}
                </div>
                <div className="mt-4 d-flex justify-content-between">
                  <h5 className="fw-normal" style={{ fontSize: "0.73em" }}>
                    {t("subscription.startingFrom")}
                  </h5>
                  <div className="d-flex gap-2">
                    <Database
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "2px",
                        fontWeight: "bold",
                      }}
                    />
                    <h5
                      className="fw-bold"
                      style={{ fontSize: "0.80em", marginTop: "1px" }}
                    >
                      79 {t("subscription.currency")}
                    </h5>
                  </div>
                </div>

                <hr />
                <ul className="list-unstyled">
                  {itemsBasic.map((item, index) => (
                    <li key={index} className="d-flex align-items-center mb-2">
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          // backgroundColor: activeCard === 0 ? "#ffffff" : "",
                          // border: `2px solid ${
                          //   activeCard === 0 ? "#ffffff" : "#000000"
                          // }`,

                          backgroundColor: "#000000",
                          border: `2px solid #000000`,
                          marginRight: "10px",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* {activeCard === 1 && ( */}
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12" // Adjusted size for better centering
                          height="12"
                          fill="#ffffff" // White color for the checkmark
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.5 8.5l2.5 2.5 5-5a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414z" />
                        </svg> */}
                        {/* )} */}
                      </div>
                      <div
                        className="mt-0 fw-light"
                        style={{ fontSize: "0.69em" }}
                      >
                        {item}
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Title>
              <Link
                className="mt-0 ms-auto "
                to="/credits"
                style={{
                  borderRadius: "5px",
                  padding: "10px",
                  textDecoration: "none",
                  textTransform: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  className=" d-block mb-0 "
                  style={{
                    // backgroundColor: activeCard != 0 ? "#263238" : "white",
                    // color: activeCard != 0 ? "white" : "black",
                    backgroundColor: "#263238",
                    color: "white",
                    cursor: "pointer",
                    border: "1px solid grey",
                  }}
                >
                  {t("subscription.tryForFree")}
                </Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Special Plan */}
        <Col xs={12} md={6} lg={4} className="mb-4">
          <Card
            onClick={() => handleCardClick(1)}
            style={{
              // backgroundColor: activeCard === 1 ? "#263238" : "white",
              // color: activeCard === 1 ? "white" : "black",
              cursor: "pointer",
              backgroundColor: "white",
            }}
          >
            <Card.Body className="d-flex flex-column">
              <Card.Title>
                <div className="d-flex justify-content-between">
                  <h6 className="fs-4">{t("subscription.special")}</h6>
                  {/* <div className="d-flex align-items-center">
                    <h6 className="me-2 fs-6 mt-2">
                      {t("subscription.yourPlan")}
                    </h6>
                    <div
                      style={{
                        width: "19px",
                        height: "19px",
                        borderRadius: "50%",
                        backgroundColor: activeCard === 1 ? "#ffffff" : "",
                        border: `2px solid ${
                          activeCard === 1 ? "#ffffff" : "#000000"
                        }`,
                        marginRight: "10px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {activeCard === 1 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          fill="black"
                          viewBox="0 0 16 16"
                          style={{ position: "absolute" }}
                        >
                          <path d="M4.5 8.5l2.5 2.5 5-5a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414z" />
                        </svg>
                      )}
                    </div>
                  </div> */}
                </div>
                <div className="mt-4 d-flex justify-content-between">
                  <h5 className="fw-normal" style={{ fontSize: "0.73em" }}>
                    {t("subscription.startingFrom")}
                  </h5>
                  <div className="d-flex gap-2">
                    <Database
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "2px",
                        fontWeight: "bold",
                      }}
                    />
                    <h5
                      className="fw-bold"
                      style={{ fontSize: "0.80em", marginTop: "1px" }}
                    >
                      900 {t("subscription.currency")}
                    </h5>
                  </div>
                </div>

                <hr />

                <ul className="list-unstyled">
                  {itemsSpecial.map((item, index) => (
                    <li key={index} className="d-flex align-items-center mb-2">
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          // backgroundColor: activeCard === 0 ? "#ffffff" : "",
                          // border: `2px solid ${
                          //   activeCard === 0 ? "#ffffff" : "#000000"
                          // }`,

                          backgroundColor: "#000000",
                          border: `2px solid #000000`,
                          marginRight: "10px",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* {activeCard === 1 && ( */}
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12" // Adjusted size for better centering
                          height="12"
                          fill="#ffffff" // White color for the checkmark
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.5 8.5l2.5 2.5 5-5a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414z" />
                        </svg> */}
                        {/* )} */}
                      </div>
                      <div
                        className="mt-0 fw-light"
                        style={{ fontSize: "0.69em" }}
                      >
                        {item}
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Title>
              <a
                href="https://api.whatsapp.com/send?phone=966505278757&text=%D8%A3%D8%B1%D8%BA%D8%A8%20%D8%A8%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D9%81%D8%B3%D8%A7%D8%B1%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%A8%D8%A7%D9%82%D8%A9%20%D8%A7%D9%84%D9%85%D9%85%D9%8A%D8%B2%D9%87%20%D9%85%D9%86%20%D9%85%D8%B9%D8%B2%D9%88%D9%85%20%2F%0AI%20want%20to%20know%20more%20about%20mazoom%20special%20package"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  borderRadius: "5px",
                  padding: "10px",
                  textDecoration: "none",
                  textTransform: "none",
                  marginLeft: "auto",
                  // className = "mt-0 ms-auto ",
                }}
                onClick={(e) => {
                  e.preventDefault() // Prevents default anchor behavior
                  window.open(
                    "https://api.whatsapp.com/send?phone=966505278757&text=%D8%A3%D8%B1%D8%BA%D8%A8%20%D8%A8%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D9%81%D8%B3%D8%A7%D8%B1%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%A8%D8%A7%D9%82%D8%A9%20%D8%A7%D9%84%D9%85%D9%85%D9%8A%D8%B2%D9%87%20%D9%85%D9%86%20%D9%85%D8%B9%D8%B2%D9%88%D9%85%20%2F%0AI%20want%20to%20know%20more%20about%20mazoom%20special%20package",
                    "_blank",
                    "noopener,noreferrer",
                  ) // Opens Google in a new tab
                  // window.location.href = "/" // Redirects to the home page
                }}
              >
                <Button
                  className=" mt-2 d-block"
                  style={{
                    border: "none",
                    maxWidth: "200px",
                    margin: "0 auto",
                    // backgroundColor: activeCard != 1 ? "#263238" : "white",
                    // color: activeCard != 1 ? "white" : "black",
                    backgroundColor: "#263238",
                    // border: `2px solid #000000`,
                  }}
                >
                  {t("subscription.orderNow")}
                </Button>
              </a>
            </Card.Body>
          </Card>
        </Col>
        {/* Repeat the structure as above with itemsSpecial and handleCardClick(1) */}

        {/* Premium Plan */}
        <Col xs={12} md={6} lg={4} className="mb-4">
          <Card
            onClick={() => handleCardClick(2)}
            style={{
              // backgroundColor: activeCard === 2 ? "#263238" : "white",
              // color: activeCard === 2 ? "white" : "black",
              cursor: "pointer",
              backgroundColor: "white",
            }}
          >
            <Card.Body className="d-flex flex-column">
              <Card.Title>
                <div className="d-flex justify-content-between">
                  <h6 className="fs-4">{t("subscription.premium")}</h6>
                  {/* <div className="d-flex align-items-center">
                    <h6 className="me-2 fs-6 mt-2">
                      {t("subscription.yourPlan")}
                    </h6>
                    <div
                      style={{
                        width: "19px",
                        height: "19px",
                        borderRadius: "50%",
                        backgroundColor: activeCard === 2 ? "#ffffff" : "",
                        border: `2px solid ${
                          activeCard === 2 ? "#ffffff" : "#000000"
                        }`,
                        marginRight: "10px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {activeCard === 2 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          fill="black"
                          viewBox="0 0 16 16"
                          style={{ position: "absolute" }}
                        >
                          <path d="M4.5 8.5l2.5 2.5 5-5a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414z" />
                        </svg>
                      )}
                    </div>
                  </div> */}
                </div>
                <div className="mt-4 d-flex justify-content-between">
                  <h5 className="fw-normal" style={{ fontSize: "0.73em" }}>
                    {t("subscription.startingFrom")}
                  </h5>
                  <div className="d-flex gap-2">
                    <Database
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "2px",
                        fontWeight: "bold",
                      }}
                    />
                    <h5
                      className="fw-bold"
                      style={{ fontSize: "0.80em", marginTop: "1px" }}
                    >
                      5000 {t("subscription.currency")}
                    </h5>
                  </div>
                </div>

                <hr />
                <ul className="list-unstyled">
                  {itemsPremium.map((item, index) => (
                    <li key={index} className="d-flex align-items-center mb-2">
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          // backgroundColor: activeCard === 0 ? "#ffffff" : "",
                          // border: `2px solid ${
                          //   activeCard === 0 ? "#ffffff" : "#000000"
                          // }`,

                          backgroundColor: "#000000",
                          border: `2px solid #000000`,
                          marginRight: "10px",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* {activeCard === 1 && ( */}
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12" // Adjusted size for better centering
                          height="12"
                          fill="#ffffff" // White color for the checkmark
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.5 8.5l2.5 2.5 5-5a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414z" />
                        </svg> */}
                        {/* )} */}
                      </div>
                      <div
                        className="mt-0 fw-light"
                        style={{ fontSize: "0.69em" }}
                      >
                        {item}
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Title>
              <a
                href="https://form.typeform.com/to/qTXckHE5?typeform-source=www.landing.mazoom.sa"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  borderRadius: "5px",
                  padding: "10px",
                  textDecoration: "none",
                  marginLeft: "auto",
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.preventDefault() // Prevents default anchor behavior
                  window.open(
                    "https://form.typeform.com/to/qTXckHE5?typeform-source=www.landing.mazoom.sa",
                    "_blank",
                    "noopener,noreferrer",
                  ) // Opens Google in a new tab
                  // window.location.href = "/" // Redirects to the home page
                }}
              >
                <Button
                  className=" mt-2 d-block"
                  style={{
                    border: "none",
                    maxWidth: "200px",
                    margin: "0 auto",
                    // backgroundColor: activeCard != 2 ? "#263238" : "white",
                    // color: activeCard != 2 ? "white" : "black",
                    backgroundColor: "#263238",
                  }}
                >
                  {t("subscription.orderNow")}
                </Button>
              </a>
            </Card.Body>
          </Card>
        </Col>

        {/* Repeat the structure as above with itemsPremium and handleCardClick(2) */}
      </Row>

      {/* {activeCard == 0 && (
        <Link
          to="/credits"
          style={{
            borderRadius: "5px",
            padding: "10px",
            textDecoration: "none",
            textTransform: "none",
          }}
        >
          <Button
            className="text-light mt-2 d-block"
            style={{
              border: "none",
              maxWidth: "200px",
              margin: "0 auto",
              backgroundColor: "#263238",
            }}
          >
            {t("subscription.tryForFree")}
          </Button>
        </Link>
      )}
      {activeCard == 1 && (
        <a
          href="https://api.whatsapp.com/send?phone=966505278757&text=%D8%A3%D8%B1%D8%BA%D8%A8%20%D8%A8%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D9%81%D8%B3%D8%A7%D8%B1%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%A8%D8%A7%D9%82%D8%A9%20%D8%A7%D9%84%D9%85%D9%85%D9%8A%D8%B2%D9%87%20%D9%85%D9%86%20%D9%85%D8%B9%D8%B2%D9%88%D9%85%20%2F%0AI%20want%20to%20know%20more%20about%20mazoom%20special%20package"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            borderRadius: "5px",
            padding: "10px",
            textDecoration: "none",
            textTransform: "none",
          }}
          onClick={(e) => {
            e.preventDefault() // Prevents default anchor behavior
            window.open(
              "https://api.whatsapp.com/send?phone=966505278757&text=%D8%A3%D8%B1%D8%BA%D8%A8%20%D8%A8%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D9%81%D8%B3%D8%A7%D8%B1%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%A8%D8%A7%D9%82%D8%A9%20%D8%A7%D9%84%D9%85%D9%85%D9%8A%D8%B2%D9%87%20%D9%85%D9%86%20%D9%85%D8%B9%D8%B2%D9%88%D9%85%20%2F%0AI%20want%20to%20know%20more%20about%20mazoom%20special%20package",
              "_blank",
              "noopener,noreferrer",
            ) // Opens Google in a new tab
            // window.location.href = "/" // Redirects to the home page
          }}
        >
          <Button
            className="text-light mt-2 d-block"
            style={{
              border: "none",
              maxWidth: "200px",
              margin: "0 auto",
              backgroundColor: "#263238",
            }}
          >
            {t("subscription.orderNow")}
          </Button>
        </a>
      )}
      {activeCard == 2 && (
        <a
          href="https://form.typeform.com/to/qTXckHE5?typeform-source=www.landing.mazoom.sa"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            borderRadius: "5px",
            padding: "10px",
            textDecoration: "none",
            textTransform: "none",
          }}
          onClick={(e) => {
            e.preventDefault() // Prevents default anchor behavior
            window.open(
              "https://form.typeform.com/to/qTXckHE5?typeform-source=www.landing.mazoom.sa",
              "_blank",
              "noopener,noreferrer",
            ) // Opens Google in a new tab
            // window.location.href = "/" // Redirects to the home page
          }}
        >
          <Button
            className="text-light mt-2 d-block"
            style={{
              border: "none",
              maxWidth: "200px",
              margin: "0 auto",
              backgroundColor: "#263238",
            }}
          >
            {t("subscription.orderNow")}
          </Button>
        </a>
      )} */}
    </div>
  )
}

export default Subscription
