import { IEvent, IEvents } from "./types"
import EventsItem from "./EventsItem"
import {t} from "i18next";

const Events = ({ events }: IEvents) => {
  return (
    <div className="event-list">
      {events.filter((item) => item.is_open_event === 1).length > 0 && (
        <h3 className="event-list__title">{t("events.openEvents")}</h3>
      )}
      {events.map((event: IEvent) => {
        if (event.is_open_event === 1) {
          return <EventsItem key={event.event_id} {...event} />
        }
      })}
      {events.filter((item) => item.is_open_event === 0).length > 0 && (
        <h3 className="event-list__title">{t("events.events")}</h3>
      )}
      {events.map((event: IEvent) => {
        if (event.is_open_event === 0) {
          return <EventsItem key={event.event_id} {...event} />
        }
      })}
    </div>
  )
}

export default Events
