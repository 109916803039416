import React from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import SelectAddGuest from "./SelectAddGuest"
import {
  addGuest,
  getContactsOnEvent,
  setLoading,
} from "../../../redux/slices/contacts/contactsSlices"
import { useAppDispatch } from "../../../redux/store"
import styles from "./AddGuest.module.scss"

const AddGuest = ({
  guest,
  setGuest,
  show,
  handleClose,
  validation,
  setValidation,
  filter,
}: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const isEmpty = (val: any) => {
    if (!val) {
      return "empty"
    }
  }

  const notify = (text: string, color: boolean) => {
    color
      ? toast.error(text, {
          position: "top-right",
          autoClose: 3000,
        })
      : toast.success(text, {
          position: "top-right",
          autoClose: 3000,
        })
  }

  const showToast = (text: any) => {
    setValidation(text)
    notify((Object.values(text) as string[][])[0][0], true)
  }

  const numberValidationToast = () => {
    setValidation({
      ...validation,
      quantity_members: ["Value must be between 0 and 10"],
    })
    notify("Value must be between 0 and 10", true)
  }

  const handeInput = (input: string, value: string | number) => {
    setGuest({ ...guest, [input]: value })
  }

  const onSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    guest.quantity_members >= 0 && guest.quantity_members <= 10
      ? dispatch(addGuest(guest)).then((result: any) => {
          if (result.error) {
            showToast(result.payload)
          } else {
            dispatch(setLoading())
            handleClose()
            dispatch(getContactsOnEvent(filter))
            notify("Guest added successfully", false)
            setGuest({
              event_id: Number(event_id),
              first_name: "",
              last_name: "",
              suffix: "",
              phone: "",
              email: "",
              quantity_members: 0,
            })
            setValidation([])
          }
        })
      : numberValidationToast()
  }

  const showError = (message: string) => {
    handleClose()
    notify(message, true)
    setGuest({
      event_id: Number(event_id),
      first_name: "",
      last_name: "",
      suffix: "",
      phone: "",
      email: "",
      quantity_members: 0,
    })
    setValidation([])
  }

  const onHide = () => {
    handleClose()
    setGuest({
      event_id: Number(event_id),
      first_name: "",
      last_name: "",
      suffix: "",
      phone: "",
      email: "",
      quantity_members: 0,
    })
    setValidation([])
  }

  return (
    <Modal
      className={`${styles.addPopup} add-guest__modal`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            {t("contacts.addGuestPopup.title")}
          </h2>
          <span className={styles.text}>
            {t("contacts.addGuestPopup.text")}
          </span>

          <form action="" className={styles.form} onSubmit={onSubmit}>
            <span className={styles.title}>Guest Information</span>
            <SelectAddGuest
              placeholder={t("placeholder.suffix2") + " *"}
              value={guest.suffix}
              validation={validation}
              handeInput={handeInput}
            />

            <div className={`profile-input ${styles.input}`}>
              <input
                type="text"
                value={guest.first_name}
                onChange={(event) => {
                  handeInput("first_name", event.target.value)
                }}
                className={isEmpty(guest.first_name)}
                placeholder={t("placeholder.firstName") + " *"}
              />

              {validation?.first_name && (
                <span className="input-item__error error mt-1">
                  {validation?.first_name}
                </span>
              )}
            </div>

            <div className={`profile-input ${styles.input}`}>
              <input
                type="text"
                value={guest.last_name}
                onChange={(event) => {
                  handeInput("last_name", event.target.value)
                }}
                className={isEmpty(guest.last_name)}
                placeholder={t("placeholder.lastName")}
              />

              {validation?.last_name && (
                <span className="input-item__error error mt-1">
                  {validation?.last_name}
                </span>
              )}
            </div>

            <div className={`profile-input ${styles.input}`}>
              <input
                type="text"
                value={guest.email}
                className={isEmpty(guest.email)}
                onChange={(event) => {
                  handeInput("email", event.target.value)
                }}
                placeholder={t("placeholder.email")}
              />

              {validation?.email && (
                <span className="input-item__error error mt-1">
                  {validation?.email}
                </span>
              )}
            </div>

            <div className={`profile-input ${styles.input}`}>
              <input
                type="text"
                value={guest.phone}
                onChange={(event) => {
                  handeInput("phone", event.target.value)
                }}
                className={isEmpty(guest.phone)}
                placeholder={t("placeholder.phoneNumber") + " *"}
              />

              {validation?.phone && (
                <span className="input-item__error error mt-1">
                  {validation?.phone}
                </span>
              )}
            </div>
            <span className={styles.title}>Additional Invites</span>
            <div className={`profile-input ${styles.input}`}>
              <input
                type="number"
                value={guest.quantity_members}
                onChange={(event) => {
                  handeInput("quantity_members", event.target.value)
                }}
                className={isEmpty(guest.quantity_members)}
                placeholder={t("placeholder.invitesQuantity")}
              />

              {validation?.quantity_members && (
                <span className="input-item__error error mt-1">
                  {validation?.quantity_members}
                </span>
              )}
            </div>

            <div className={styles.controls}>
              <span className="btn btn-simple" onClick={onHide}>
                {t("buttons.cancel")}
              </span>
              <button type="submit" className="btn btn-dark" onClick={onSubmit}>
                {t("buttons.save")}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddGuest
