function FormatDate(dateString: string): string {
  const date = new Date(dateString)
  try {
    // Ensure the date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format")
    }

    // Array of month names
    const monthNames: string[] = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    // Extract day, month, and year from the date
    const day: number = date.getDate()
    const month: string = monthNames[date.getMonth()]
    const year: number = date.getFullYear()

    // Return formatted date as "Jul 23, 2024"
    return `${month} ${day}, ${year}`
  } catch (err) {
    return ""
  }
}

export default FormatDate
