import { Modal } from "react-bootstrap"
import { useActions } from "@/hooks/useActions"
import { useAppDispatch } from "@/redux/store"
import { clearQrId } from "@/redux/slices/events/eventsSlice"
import styles from "./DeleteModal.module.scss"

const DeleteModal = ({ show, setShow }: any) => {
  const dispatch = useAppDispatch()
  const { deleteImage, setImage, setCroppedImagePath } = useActions()

  const onDelete = () => {
    deleteImage()
    setImage(null)
    setCroppedImagePath(null)
    dispatch(clearQrId())
    setShow(false)
  }

  return (
    <Modal
      className={`${styles.addPopup} delete-qr-modal`}
      show={show}
      onHide={() => setShow(!show)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            Do you really want to discard all your changes?{" "}
          </h2>

          <div className={styles.controls}>
            <span className="btn btn-simple" onClick={onDelete}>
              {"Yes"}
            </span>
            <button onClick={() => setShow(!show)} className="btn btn-dark">
              {"No"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteModal
