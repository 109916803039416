import styles from "../../QrDesignEditor.module.scss"
import { useActions } from "@/hooks/useActions"

const QrDesignEditorButton = ({
  icon,
  text,
  setTextVisible,
  textVisible,
  setQrVisible,
  setCountVisible,
  setSelectVisible,
  selectVisible,
  qrVisible,
  countVisible,
  onReset,
  setShow,
  setUpdateText,
  updateText
}: {
  icon: string
  text: string
  textVisible?: boolean
  setTextVisible?: any
  setQrVisible?: any
  setCountVisible?: any
  setSelectVisible?: any
  selectVisible?: any
  qrVisible?: any
  countVisible?: any
  onReset?: any
  setShow: any
  setUpdateText?: any
  updateText?: boolean
}) => {
  const { toggleCropModalShow } = useActions()
 
  const handleClick = (func: any, value: any) => {
      func(!value)
      setShow(true)
  }

  return (
    <div className={styles.heading_editor_btn}>
      {text === "Crop" ? (
        <button
          onClick={() => toggleCropModalShow()}
          data-bs-toggle="modal"
          data-bs-target="#cropModal"
        >
          <img src={icon} alt="" />
        </button>
      ) : text === "Text" ? (
        <button onClick={() => { 
          handleClick(setTextVisible, textVisible)
          setUpdateText(!updateText)}}>
          {" "}
          <img src={icon} alt="" />{" "}
        </button>
      ) : (
        text === "QR" ? (
          <button onClick={() => handleClick(setQrVisible, qrVisible)}>
            {" "}
            <img src={icon} alt="" />{" "}
          </button>
        ) : (
          text === "Amount" ? (
            <button onClick={() => handleClick(setCountVisible, countVisible)}>
              {" "}
              <img src={icon} alt="" />{" "}
            </button>
          ) : (
            text === "Reset" ? (
              <button onClick={() => onReset(true)}>
                {" "}
                <img src={icon} alt="" />{" "}
              </button>
            ) : (
              text === "Name" ? (
                <button onClick={() => handleClick(setSelectVisible, selectVisible)}>
                  {" "}
                  <img src={icon} alt="" />{" "}
                </button>
              ) : (
                <button>
                  {" "}
                  <img src={icon} alt="" />{" "}
                </button>
              )
            )
          )
        )
      )}
      <p>{text}</p>
    </div>
  )
}
export default QrDesignEditorButton
