import { useAppSelector } from "@/redux/store"
import "./InvitationPhone.scss"
import {
  IconArrowDownTriangle,
  IconBattery,
  IconDatePicker,
  IconFooterIcons,
  IconLocation,
  IconLock,
  IconRefresh,
  IconSignal,
  IconTextSize,
  IconTime,
  IconTitle,
  IconWifi,
} from "../../utils/Icons/CustomIcons"
import { useTranslation } from "react-i18next"

const InvitationPhone = () => {
  const { t } = useTranslation()
  const { new_event_img, currentEvent } = useAppSelector((state) => state.events)

  return (
    <div className="invitation-phone">
      <div className="phone">
        <div className="phone-inner">
          <section className="phone-header">
            <IconTime />
            <div className="phone-header__icons">
              <IconSignal />
              <IconWifi />
              <IconBattery />
            </div>
          </section>

          <section className="phone-title">
            <IconTitle />
            <div className="phone-title__lang">
              <span>{t("header.language2")}</span>
              <IconArrowDownTriangle />
            </div>
          </section>

          <section className="phone-main">
            <div className="picture">
              <img
                src={
                  new_event_img.file_path
                    ? new_event_img.file_path
                    : currentEvent ? currentEvent.pattern : "/vector/no-foto.svg"
                }
                alt=""
              />
            </div>
            <div className="phone-main__bottom">
              <div className="phone-main__content">
                <p className="phone-main__description">
                  You are invited to the event:
                </p>
                <span className="phone-main__title">
                  Weeding Party of Hannah & Bradley{" "}
                </span>
              </div>
              <div className="d-flex gap-3 phone-main__card">
                <IconDatePicker />
                <div className="phone-main__card-description">
                  <p className="phone-main__card-caption">Date</p>
                  <p className="phone-main__card-place">Sunday, 28 May 2023</p>
                  <p className="phone-main__card-address">7:00 p.m.</p>
                </div>
              </div>
              <div className="d-flex gap-3 phone-main__card">
                <IconLocation />
                <div className="phone-main__card-description">
                  <p className="phone-main__card-caption">Location</p>
                  <p className="phone-main__card-place">
                    Makkah royal clock tower
                  </p>
                  <p className="phone-main__card-address">
                    Ajyad Street, Mecca 24231, Arab Saudi
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center gap-3 phone-main__buttons">
                <button className="btn btn-simple">Decline</button>
                <button className="btn btn-dark">Accept</button>
              </div>
            </div>
          </section>

          <section className="phone-footer">
            <div className="phone-footer__http">
              <IconTextSize />
              <IconLock />
              <span className="phone-footer__http-text">
                app.mazoom.sa/register/819
              </span>
              <IconRefresh />
            </div>
            <IconFooterIcons />
            <div className="phone-footer__http-bottom">
              <div className="phone-footer__http-line" />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default InvitationPhone
