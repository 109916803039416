import { useState } from "react"
import { Link } from "react-router-dom"
import {
  IconAlertCircle,
  IconArrowLeft,
} from "../../components/utils/Icons/CustomIcons"
import { Database } from "lucide-react"
import { Card, Button, Row, Col } from "react-bootstrap"
import Skeleton from "@/components/common/skeleton"
import quizConfirm from "@/assets/images/quizConfirm.svg" // Adjust the path as necessary

const QuizPass = () => {
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const handleImageLoad = () => {
    setIsLoadingImage(false)
  }
  return (
    <div className="container mt-1">
      <div className="d-flex flex-column align-items-center justify-content-center text-center gap-2 mt-3">
        <div className="d-flex flex-column  ">
          {/* <img src="/images/quizPass.png" width="110px" height="auto" /> */}

          {isLoadingImage && (
            <Skeleton width="120px" height="150px" borderRadius="8px" />
          )}
          <img
            src={quizConfirm}
            width="120px"
            height="auto"
            style={{ display: isLoadingImage ? "none" : "block" }}
            onLoad={handleImageLoad}
          />
        </div>{" "}
        {/* <h3 className="mb-0 fs-6 mb-0 mt-0 " style={{ fontWeight: 540 }}>
          {" "}
          Confirmation option A
        </h3> */}
        <div className="mb-0  fs-4"> Great! </div>
        <h6
          className="fw-light me-3 ms-3 text-muted"
          style={{ maxWidth: "430px", fontSize: "0.63em" }}
        >
          Mazoom is designed to help you easily create and send invitations,
          even if you’re new to it.{" "}
        </h6>
        <h6
          className="fw-light me-3 ms-3 text-muted d-flex justify-content-center"
          style={{ maxWidth: "430px", fontSize: "0.62em" }}
        >
          Fun fact: First-time event planners often complete their invites 50%
          faster with Mazoom. Give it a try by creating a sample event!
        </h6>
        <Link to="/guest/create-event">
          <Button
            className=" bg-dark text-dark text-white border-0"
            style={{ width: "200px" }}
          >
            See how it works{" "}
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default QuizPass
