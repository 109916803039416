import Select from "react-select"

const AcceptModal = ({
  options,
  value,
  setValue,
  id,
  type,
  error,
}: {
  options: any
  value?: any
  setValue?: any
  id?: number
  type: string
  error?: any
}) => {
  return (
    <Select
      // menuIsOpen={true}
      isSearchable={false}
      options={options}
      defaultValue={options[0]}
      value={value && options.find((e: any) => e.value === value)}
      onChange={(option) => {
        type === "answer"
          ? setValue({
              ...value,
              answers: value.answers.map((item: any) =>
                item.question_id === id
                  ? { ...item, answer: option.value }
                  : item,
              ),
            })
          : setValue({
              ...value,
              number: option.value,
            })
      }}
      styles={{
        container: (state: any) => ({
          ...state,
          display: "block",
          width: "100%",
          minHeight: "26px",
          "@media (max-width: 991px)": {
            display: "block",
            maxWidth: "100%",
          },
        }),
        valueContainer: (state: any) => ({
          ...state,
          padding: "0px 10px 0px 0px",
          color: "red",
          "@media (max-width: 991px)": {
            maxWidth: "90%",
          },
        }),
        placeholder: (state: any) => ({
          ...state,
          display: "none",
        }),
        control: (state: any) => ({
          ...state,
          fontSize: "14px",
          lineHeight: "26px",
          minHeight: "20px",
          border: 0,
          boxShadow: "none",
          borderBottom:
            error &&
            error.questions.length > 0 &&
            error.questions.filter((item: any) => item === id).length > 0
              ? "1px solid #EB5757"
              : "1px solid #E3E2E2",
          "&:hover": {
            borderBottom: "1px solid #E3E2E2",
          },
          borderRadius: "",
          paddingBottom: "11px",
          marginBottom: "5px",
          paddingLeft: "0",
          "@media (max-width: 1279px)": {
            fontSize: "12px",
            lineHeight: "16px",
          },
        }),
        singleValue: (state: any) => ({
          ...state,
          color: "rgba(11, 12, 14, 1)",
          "@media (max-width: 991px)": {
            whiteSpace: "wrap",
          },
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),

        dropdownIndicator: (base: any, state) => ({
          ...base,
          padding: "0px",
          height: "16px",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        }),
        option: (defaultStyles: any, state: any) => ({
          ...defaultStyles,
          fontSize: "16px",
          "@media (max-width: 1279px)": {
            fontSize: "14px",
          },
          lineHeight: "19.6px",
          position: "relative",
          backgroundColor: state.isSelected ? "#F2F4F7" : "#FFF",
          "&:hover": {
            background: "#F2F4F7",
          },
          "&::after": {
            content: state.isSelected ? "url(/vector/check.svg)" : '""',
            position: "absolute",
            right: "10px",
            top: "calc(50% - 8px)",
            width: "16px",
            height: "16px",
          },
          padding: "12px 10px",
          "@media (max-width: 991px)": {
            padding: "6px 36px 6px 10px",
          },
          color: "#0B0C0E",
        }),

        menu: (state: any) => ({
          ...state,
          boxShadow:
            "0 0 0 0px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
          borderRadius: "0",
        }),
        menuList: (state: any) => ({
          ...state,
          padding: "0",
        }),
      }}
    />
  )
}
export default AcceptModal
