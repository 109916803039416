import enpoints from "../endpoints"
import { axiosInstance } from "../instance"
import { AxiosPromise } from "axios"
import { IEventsRequest, IEventsResponse } from "../../components/Events/types"

export const getMessages = () => {
  return axiosInstance.get(enpoints.MESSAGES.GET)
}

export const getMessagesWithParams = (page:number, total: number) => {
  return axiosInstance.get(enpoints.MESSAGES.GET + `?page=${page}&total=${total}`)
}

export const getConversation = (id: number) => {
  return axiosInstance.get(enpoints.MESSAGES.GET_CONVERSATION + `${id}`)
}

export const sendMessage = (params: { id: number, contactId: number, message: string}) => {
  return axiosInstance.post(enpoints.MESSAGES.SEND_MESSAGE, {
    "event_id": params.id,
    "contact_id": params.contactId,
    "message": params.message
    })
}

export const markAsRead = (id: number) => {
  return axiosInstance.post(`/events/${id}` + enpoints.MESSAGES.MARK_AS_READ)
}

export const sendMessageToOwnerEvent = (params: any) => {
  return axiosInstance.post(enpoints.MESSAGES.SEND_MESSAGE_TO_OWNER_EVENT, {
    message: params?.message,
    sender: params?.sender,
    event: params?.event,
  })
}



