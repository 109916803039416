import { useActions } from "@/hooks/useActions"
import { useTypeSelector } from "@/hooks/useTypeSelector"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Cropper from "react-easy-crop"
import getCroppedImg from "./CropImage"

type Props = {}

const CropModal = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const { toggleCropModalShow, setCroppedImagePath } = useActions()
  const image = useTypeSelector((state) => state.qr.image)
  const { cropModalShow } = useTypeSelector((state) => state.qr)

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onCloseModal = () => {
    toggleCropModalShow()
  }
  const onSave = async () => {
    if (!image) {
      return
    }
    try {
      const croppedImage = await getCroppedImg(image.path, croppedAreaPixels)
      setCroppedImagePath(croppedImage)
      onCloseModal()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal size="xl" onHide={onCloseModal} show={cropModalShow} className="qr-crop-modal">
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: window.innerWidth > 1279 ? "648px" : window.innerWidth > 991 ? '415px' : '150px' }}>
        <Cropper
          image={image?.path}
          crop={crop}
          zoom={zoom}
          aspect={1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
        />
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={onCloseModal} variant="light">
          Cancel
        </Button>
        <Button onClick={onSave} variant="dark">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CropModal
