import { Modal } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import styles from "./VerifyModal.module.scss"

const VerifyModal = ({ show, setShow }: any) => {
  const navigate = useNavigate()
  const { event_id } = useParams()

  const openTest = () => {
    navigate(`/${event_id}/test-the-invitation`)
    setShow(false)
  }

  return (
    <Modal
      className={"event-page__verify-modal"}
      show={show}
      onHide={() => setShow(!show)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            Phone number successfully verified!
          </h2>
          <span className={styles.text}>
            You can test the invitation by following the link
          </span>
          <div className={styles.controls}>
            <span className="btn btn-simple" onClick={() => setShow(!show)}>
              stay here
            </span>
            <button onClick={openTest} className="btn btn-dark">
              Test the Invitation
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default VerifyModal
