import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const BackToLogin = () => {
  const { t } = useTranslation()

  return (
    <div
      className=" d-flex flex-column flex-md-row align-items-center justify-content-center  ms-5 me-5 mb-5 mt-5"
      style={{ minHeight: "60vh", margin: "0 5rem", gap: "5em" }} // Ensure full height and margin
    >
      <div className="d-flex flex-column gap-3 gap-md-3 ">
        <h2 className=""> {t("event.youAlreadyHaveAccountAtMazoom")}</h2>
        <h4 className="text-muted"></h4>
        <div className="d-flex flex-row gap-4">
          {/* <Link className="mt-0 w-100 bg-dark border-0" to="/">
            Go To Dashboard
          </Link> */}
          <Link
            style={{ borderRadius: "5px", padding: "10px" }}
            className=" mt-0 w-100 bg-dark text-white border- d-flex justify-content-center align-items-center reminders-page__link-container"
            to="/login"
          >
            {t("event.login")}
          </Link>
          {/* <Link
            style={{
              borderRadius: "5px",
              padding: "10px",
              border: "1px solid #e2e1e1",
            }}
            className=" mt-0 w-100 bg-light text-black border- d-flex justify-content-center align-items-center reminders-page__link-container"
            to="/"
            onClick={() => setStep(1)}
          >
            {t("event.sentMoreInvites")}{" "}
          </Link> */}

          {/* <Button
            className=" w-100 bg-light  text-dark border-none"
            style={{ border: "1px solid #e8e8e8" }}
          >
            Sent More Invites
          </Button> */}
        </div>
      </div>
    </div>
  )
}
export default BackToLogin
