// Skeleton.js
import React from "react"
import "./Skeleton.css" // Styles for skeleton animation

interface SkeletonProps {
  width: string
  height: string
  borderRadius?: string
}

const Skeleton: React.FC<SkeletonProps> = ({
  width,
  height,
  borderRadius = "8px",
}) => {
  return (
    <div
      className="skeleton"
      style={{
        width: width || "100%",
        height: height || "100%",
        borderRadius: borderRadius,
      }}
    ></div>
  )
}

export default Skeleton
