import { useState } from "react"
import { Modal } from "react-bootstrap"
import {Link, useParams} from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
    getContactsOnEvent,
    setLoading,
} from "../../../redux/slices/contacts/contactsSlices"
import { useAppDispatch } from "../../../redux/store"
import { updateInvites } from "../../../redux/slices/events/eventsSlice"
import styles from "./DownloadAppModal.module.scss"

const DownloadAppModal = ({ show, handleClose, filter, notify }: any) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const onHide = () => {
        handleClose();
    }

    return (
        <Modal
            className={`${styles.addPopup} download-app__modal`}
            show={show}
            onHide={onHide}
        >
            <Modal.Header className={styles.close} closeButton></Modal.Header>
            <Modal.Body>
                <div className={styles.body}>
                    <h2 className={`caption ${styles.caption}`}>
                        {t("event.downloadAppModal.title")}
                    </h2>
                    <div className="images-container">
                        <Link to="https://play.google.com/store/apps/details?id=com.mazoom.inviteSmartQr&hl=ar&gl=US" target="_blank">
                            <img
                                className=""
                                src="images/google_play.png"
                                alt=""
                            />
                        </Link>
                        <Link to="https://apps.apple.com/sa/app/scanner-by-mazoom/id6479370606" target="_blank">
                            <img
                                className=""
                                src="images/app_store.png"
                                alt=""
                            />
                        </Link>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DownloadAppModal
