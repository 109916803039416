import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  IconAlertCircle,
  IconArrowLeft,
} from "../../components/utils/Icons/CustomIcons"
import { useTranslation } from "react-i18next"

import { Card, Button, Row, Col, Badge } from "react-bootstrap"
import QRCode from "qrcode.react" // Make sure to install this package
import api from "@/api/api"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import Spinner from "react-bootstrap/Spinner"
import NoInviteFound from "./NoInvitesFound"
import Stack from "react-bootstrap/Stack"
import { PiCheck } from "react-icons/pi"
import { BiLogoTelegram } from "react-icons/bi"
import { CiLocationOn } from "react-icons/ci"
import { LiaSave } from "react-icons/lia"
import { saveAs } from "file-saver"
import { GrFormClose } from "react-icons/gr"
import { IoMdClose } from "react-icons/io"

import { toast, ToastContainer } from "react-toastify"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
// Zod schema for form validation
const messageSchema = z.object({
  message: z
    .string()
    .min(5, "Message must be at least 5 characters long")
    .max(500, "Message cannot exceed 500 characters"),
})
// import { LinearProgress, Chip } from "@mui/material"
const BASE_URL = import.meta.env.VITE_REACT_APP_API_BASE_URL

const MyInvitations = () => {
  const { t } = useTranslation()
  const StatusShow = (status: string) => {
    switch (status) {
      case "4":
        return (
          <Badge
            pill
            bg="b"
            style={{
              backgroundColor: "grey",
              color: "#F9B81A",
              fontSize: "13px",
              border: "1px solid #F9B81A1A",
            }}
          >
            {t("event.scanned")}
          </Badge>
        )
      case "3":
        return (
          <Badge
            pill
            bg="b"
            style={{
              backgroundColor: "#F9B81A1A",
              color: "#F9B81A",
              fontSize: "13px",
              border: "1px solid #F9B81A1A",
            }}
          >
            {t("event.waitingForConfirmation")}
          </Badge>
        )
      case "1":
        return (
          <Badge
            pill
            bg="b"
            style={{
              backgroundColor: "#46BA651A",
              color: "#46ba65",
              fontSize: "13px",
              border: "1px solid #46BA651A",
            }}
          >
            {t("event.approved")}
          </Badge>
        )

      case "2":
        return (
          <Badge
            pill
            bg="b"
            style={{
              backgroundColor: "#EC7A301A ",
              color: "#EC7A30",
              fontSize: "13px",
              border: "1px solid  #EC7A301A",
            }}
          >
            {t("event.rejected")}
          </Badge>
        )
      case "6":
        return (
          <Badge
            pill
            bg="b"
            style={{
              backgroundColor: "#EC7A301A ",
              color: "#EC7A30",
              fontSize: "13px",
              border: "1px solid  #EC7A301A",
            }}
          >
            {t("event.failed")}
          </Badge>
        )

      default:
        return (
          <Badge
            pill
            bg="b"
            style={{
              backgroundColor: "#F9B81A1A",
              color: "#F9B81A",
              fontSize: "13px",
              border: "1px solid #F9B81A1A",
            }}
          >
            {t("event.waitingForConfirmation")}
          </Badge>
        )
    }
  }
  const [loadingSaveQr, setLoadingSaveQr] = useState(false)
  const { phone } = useAppSelector((state) => state.auth)
  const [invitations, setInvitations] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [step, setStep] = useState(1)
  const [currentDetail, setCurrentDetail] = useState<any>(null)
  const [questOptions, setQuestOptions] = useState([
    { value: 0, label: "Only me" },
  ])
  const [selectedValue, setSelectedValue] = useState(0) // State for selected option
  const [currentStatus, setCurrentStatus] = useState<number | null>(0) // Stores accept or decline response
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(messageSchema),
  })

  useEffect(() => {
    const current = currentDetail?.people_going
    // const current = 7
    if (current > 0) {
      const newOptions = [{ value: 0, label: "Only me" }]
      let initial = 2
      for (let i = initial; i <= current; i++) {
        newOptions.push({ value: i, label: i.toString() })
      }
      setQuestOptions(newOptions)
    } else {
      setQuestOptions([{ value: 0, label: "Only me" }])
    }
    setCurrentStatus(currentDetail?.status)
  }, [currentDetail, step])
  // Example usage

  useEffect(() => {
    const userPhoneNumber = localStorage.getItem("userPhoneNumber")
    console.log("userPhoneNumber", userPhoneNumber)

    try {
      setLoading(true)
      api.events
        ?.getEventByPhone(userPhoneNumber ? userPhoneNumber : "")
        .then((inv: any) => {
          setLoading(false)
          if (inv?.status == 200) {
            setInvitations(inv?.data?.data)
            setLoading(false)
          } else {
            setInvitations([])
            setLoading(false)
          }
        })
        .catch((err) => {
          setLoading(false)
          setInvitations([])
          console.log("344", err)
        })
      // console.log("inv", inv)
      // console.log("inv", inv?.data?.data)
    } catch (err: any) {
      console.log("err", err)
      setInvitations([])
      setError("Something went wrong try again later")
      setLoading(false)
    }
  }, [step])



  const Invitation = () => {
    return (
      <div>
        {/* <div className="mb-3 ">
          <Link
            className="d-flex align-items-center reminders-page__link-container"
            to="/"
          >
            <IconArrowLeft />
            <span className="reminders-page__link-text">
              {t("buttons.backNew")}
            </span>
          </Link>
        </div>{" "} */}
        <h3 className="mb-3 "> {t("event.yourInvitations")}</h3>
        <div className="d-flex flex-column gap-3 p-2">
          <div className="d-flex flex-column gap-3 p-2">
            {invitations?.map((invitation: any, index) => (
              <div
                key={index}
                className="w-100 bg-white d-flex flex-column align-items-start flex-lg-row  p-4"
              >
                <div className="">
                  <img
                    className="d-none d-md-block"
                    src={`${
                      invitation?.event &&
                      invitation?.event &&
                      invitation?.event?.data[0]?.pattern
                    }`}
                    // src="/images/db.png"
                    // alt={invitation.title}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "5px",
                      marginRight: "15px",
                    }}
                  />
                  <img
                    className="d-block d-md-none"
                    src={`${
                      invitation?.event &&
                      invitation?.event &&
                      invitation?.event?.data[0]?.pattern
                    }`} // src="/images/db.png"
                    // alt={invitation.title}
                    style={{
                      width: "100px",
                      height: "100px",
                      // maxHeight: "100px",
                    }}
                  />
                </div>
                <div
                  // style={{ backgroundColor: "#000000" }}
                  className="w-100 d-flex flex-column flex-md-row align-items-start  gap-6 justify-content-between"
                >
                  <div className="mt-3 d-flex flex-column gap-2">
                    <h5 className="d-flex mb-2" style={{ fontSize: "0.85em" }}>
                      {invitation?.event?.data[0]?.name}
                    </h5>
                    <div style={{ maxWidth: "10px" }}>
                      {StatusShow(invitation?.status?.toString())}
                    </div>

                    {/* <div style={{ maxWidth: "10px" }}>{StatusShow("6")}</div> */}
                  </div>
                  <div className=" d-none d-md-flex align-items-center mt-3">
                    <Button
                      className="bg-dark text-white border-rounded border-0 "
                      onClick={async () => {
                        setCurrentDetail(invitation)
                        setStep(2)
                      }}
                    >
                      {t("event.eventDetailPage")}
                    </Button>
                  </div>
                </div>
                <div className=" w-100 d-  d-md-none align-items-center mt-3">
                  <Button
                    className="bg-dark text-white border-rounded border-0 d-flex w-100"
                    onClick={async () => {
                      setCurrentDetail(invitation)
                      setStep(2)
                    }}
                  >
                    {t("event.eventDetailPage")}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const MyInvitationsDetail = () => {
    const handleSave = (hash: string) => {
      setLoadingSaveQr(true)
      const imageUrl = `${BASE_URL}/events/qrcode-whatsapp/${currentDetail?.hash}`

      const downloadImage = async (imageUrl: any) => {
        try {
          const response = await fetch(imageUrl)
          const blob = await response.blob()
          saveAs(blob, "downloaded-image.jpg") // Save with the desired file name
        } catch (error) {
          console.error("Error downloading image:", error)
        }
      }
      downloadImage(imageUrl)

      setTimeout(() => {
        setLoadingSaveQr(false)
      }, 1000)
    }

    const handleReplyInvitation = (status: number) => {
      try {
        setLoadingResponse(true)
        const params = {
          hash: currentDetail?.hash,
          status: status,
          numberOfGuests: selectedValue,
        }
        api.events
          ?.sendReponseForInvite(params)
          .then((inv: any) => {
            setLoadingResponse(false)
            if (
              inv?.status == 200 ||
              inv?.status == 201 ||
              inv?.status == 202 ||
              inv?.status == 203 ||
              inv?.status == 204
            ) {
              setCurrentStatus(status)

              if (status == 1) {
                toast.success("Invitation accepted  successfully")
              } else if ((status = 2)) {
                toast.success("Invitation declined  successfully")
              }
            }
          })
          .catch((err) => {
            console.log("err", err)
            toast.error("Error occurred while recording response")

            setLoadingResponse(false)
          })
        // console.log("inv", inv)
        // console.log("inv", inv?.data?.data)
      } catch (err: any) {
        setError("Something went wrong try again later")
        toast.error("Error occurred while recording response")

        setLoadingResponse(false)
      }
    }

    const handleSubmitMessage = (data: any) => {
      try {
        setLoadingMessage(true)
        const param = {
          message: data?.message,
          sender: currentDetail?.contact?.id,
          event: currentDetail?.event?.data[0]?.event_id,
        }

        api?.messages
          ?.sendMessageToOwnerEvent(param)
          .then((inv: any) => {
            if (
              inv?.status == 200 ||
              inv?.status == 201 ||
              inv?.status == 202 ||
              inv?.status == 203 ||
              inv?.status == 204
            ) {
              reset()
              setLoadingMessage(false)
              toast.success("Message sent successfully")
            }
          })
          .catch((err) => {
            console.log("err", err)
            toast.error("Error occurred while sending  message")
            setLoadingMessage(false)
          })
        // console.log("inv", inv)
        // console.log("inv", inv?.data?.data)
      } catch (err: any) {
        setError("Something went wrong try again later")
        toast.error("Error occurred while recording response")

        setLoadingMessage(false)
      }
    }
    const handleShowOnTheMap = () => {
      if (
        currentDetail?.event?.data[0]?.address?.lat &&
        currentDetail?.event?.data[0]?.address?.lng
      ) {
        const googleMapsUrl = `https://www.google.com/maps?q=${currentDetail?.event?.data[0]?.address?.lat},${currentDetail?.event?.data[0]?.address?.lng}`
        window.open(googleMapsUrl, "_blank")
      } else {
        toast.error("Event map  location  is not available")
      }
    }

    console.log("currentDetail", currentDetail)

    return (
      <div className="container mt-3">
        <div className="mb-3 ">
          <Button
            className="d-flex bg-transparent  border-0  text-black align-items-center "
            // onClick={(() => setStep(1)

            //   setCurrentDetail(null))}

            onClick={async () => {
              setQuestOptions([{ value: 0, label: "Only me" }])
              setStep(1)
            }}
          >
            <IconArrowLeft />
            <span
              className="reminders-page__link-text"
              style={{ color: "#000000" }}
            >
              {t("buttons.backNew")}
            </span>
          </Button>
        </div>
        <h3 className="mb-3 ">{} </h3>
        <Row className="bg-white p-3 " style={{ borderRadius: "10px" }}>
          <Col sm={12} md={4}>
            <img
              src={`${
                currentDetail?.event &&
                currentDetail?.event &&
                currentDetail?.event?.data[0]?.pattern
              }`}
              alt={"event.title"}
              style={{
                width: "100%",
                maxHeight: "400px",
                borderRadius: "10px",
                objectFit: "cover", // Ensures the image covers the entire container, cropping overflow
                objectPosition: "center",
              }}
            />
          </Col>
          <Col sm={12} md={8}>
            <Card className="p-3" style={{ border: "none" }}>
              <Card.Title as="h4">
                <div className="w-100 gap-2 d-flex flex-column flex-md-row justify-content-between">
                  <div className="gap-3 d-flex pe-2 flex-column justify-content-between">
                    <h5>
                      {" "}
                      <span className="fs-4 fw-light"> {t("event.dear")} </span>
                      <span className=" fs-4 fw-light">
                        {currentDetail?.contact?.first_name}{" "}
                      </span>
                      <span className=" fs-4 fw-light">
                        {" "}
                        {currentDetail?.contact?.last_name}
                      </span>
                    </h5>

                    <h5 className="fs-5 fw-light">
                      {" "}
                      {t("event.youAreInvitedToAttend")}{" "}
                      {currentDetail?.event?.data[0]?.name}
                    </h5>
                  </div>
                  <h6>
                    {StatusShow(
                      currentStatus?.toString()
                        ? currentStatus?.toString()
                        : "",
                    )}
                  </h6>
                </div>
              </Card.Title>
              <div className="d-flex flex-row mt-4">
                <div className="d-flex flex-column flex-md-row gap-0 mb-2">
                  {currentStatus != 0 && currentStatus == 1 && (
                    <>
                      <div className="d-flex flex-column flex-md-row gap-3 align-items-center ">
                        <div>
                          {/* {currentStatus != 0 && currentStatus == 1 && ( */}
                          <>
                            <QRCode
                              value={`${BASE_URL}/events/qrcode/${currentDetail?.hash}`}
                              size={128}
                            />
                          </>
                          {/* )} */}
                        </div>
                        <div
                          className="d-flex flex-column gap-3"
                          style={{ maxWidth: "350px" }}
                        >
                          <h6 className="fw-bold">
                            {/* {currentDetail?.people_going} */}
                            {currentDetail?.people_going}
                            <span className="ms-1">{t("event.guests")}</span>
                          </h6>
                          <h6 className="text-muted ">
                            {/* You can save QR code on your device, to show it */}
                            {t("event.youCanSaveQRCodeOnYourDevice")}
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex flex-row gap-1 align-items-center ">
                        {loadingSaveQr ? (
                          <Spinner animation="border" role="status"></Spinner>
                        ) : (
                          <Button
                            className="  text-dark border-none "
                            style={{
                              border: "1px solid #e8e8e8",
                              // width: "100px",
                              fontSize: "12px",
                              backgroundColor: "#ffffff",
                            }}
                            onClick={() => handleSave(currentDetail?.hash)}
                          >
                            <LiaSave
                              className="me-3 mb-1"
                              // style={{ fontSize: "10px" }}
                            />
                            {t("event.saveQRCode")}
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div>
                {loadingResponse && (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )}
                {!loadingResponse && (
                  <div>
                    {" "}
                    {/* {(currentStatus == 0 || Number(currentStatus) > 6) && ( */}
                    <div>
                      {currentDetail && currentDetail?.people_going > 1 && (
                        <div>
                          <select
                            value={selectedValue}
                            onChange={(e: any) =>
                              setSelectedValue(e.target.value)
                            }
                            className={`form-select `}
                            style={{
                              border: "none",
                              // borderBottom: errors[name]
                              //   ? "1px solid red"
                              //   : "1px solid #ced4da",
                              boxShadow: "none",
                              // padding: "0.5rem 0", // Padding for better touch area
                              padding: "1em",
                              marginBottom: "0", // No margin between select and error
                            }}
                          >
                            {questOptions?.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                // style={{ padding: "10px" }}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <h6 className="text">
                            {t("event.selectTotalNumberOfGuestsAttending")}
                          </h6>

                          <hr />
                        </div>
                      )}

                      {currentStatus === 1 ? (
                        <div className="d-flex gap-2">
                          <Button
                            // type="button"
                            onClick={() => handleReplyInvitation(1)}
                            className=" w-100  text-light border-none"
                            style={{
                              border: "1px solid #e8e8e8",
                              backgroundColor: "green",
                              maxWidth: "150px",
                            }}
                          >
                            <PiCheck className="me-3 mb-1" />
                            {t("event.going")}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => handleReplyInvitation(2)}
                            className=" w-100 bg-light  text-dark border-none"
                            style={{
                              border: "1px solid #e8e8e8",
                              maxWidth: "150px",
                            }}
                          >
                            {/* <GrFormClose className="me-3 mb-1" /> */}
                            <IoMdClose className="me-3 mb-1  " />
                            {t("event.decline")}
                          </Button>
                        </div>
                      ) : currentStatus === 2 ? (
                        <div className="d-flex gap-2">
                          <Button
                            // type="button"
                            onClick={() => handleReplyInvitation(1)}
                            className=" w-100 bg-light  text-dark border-none"
                            style={{
                              border: "1px solid #e8e8e8",
                              maxWidth: "150px",
                            }}
                          >
                            <PiCheck className="me-3 mb-1" />
                            {t("event.going")}
                          </Button>
                          <Button
                            // type="button"
                            onClick={() => handleReplyInvitation(2)}
                            className=" w-100  text-white border-none"
                            style={{
                              border: "1px solid #e8e8e8",
                              backgroundColor: "red",
                              maxWidth: "150px",
                            }}
                          >
                            <IoMdClose
                              className="me-3 mb-1  "
                              style={{ color: "white" }}
                            />
                            {t("event.decline")}
                          </Button>
                        </div>
                      ) : (
                        <div className="d-flex gap-2">
                          <Button
                            // type="button"
                            onClick={() => handleReplyInvitation(1)}
                            className=" w-100 bg-light  text-dark border-none"
                            style={{
                              border: "1px solid #e8e8e8",
                              maxWidth: "150px",
                            }}
                          >
                            <PiCheck className="me-3 mb-1" />
                            {t("event.going")}
                          </Button>
                          <Button
                            // type="button"
                            onClick={() => handleReplyInvitation(2)}
                            className=" w-100 bg-light  text-dark border-none"
                            style={{
                              border: "1px solid #e8e8e8",
                              maxWidth: "150px",
                            }}
                          >
                            <IoMdClose className="me-3 mb-1  " />
                            {t("event.decline")}
                          </Button>
                        </div>
                      )}
                    </div>
                    {/* )} */}
                    {/* {currentStatus != 0 && currentStatus == 1 && (
                      <div>
                        <Badge
                          pill
                          bg="b"
                          style={{
                            backgroundColor: "#46BA651A",
                            color: "#46ba65",
                            fontSize: "13px",
                            border: "1px solid #46BA651A",
                          }}
                        >
                          {t("event.alreadyAccepted")}
                        </Badge>
                      </div>
                    )}
                    {currentStatus != 0 && currentStatus == 2 && (
                      <div>
                        <Badge
                          pill
                          bg="b"
                          style={{
                            backgroundColor: "#EC7A301A ",
                            color: "#EC7A30",
                            fontSize: "13px",
                            border: "1px solid  #EC7A301A",
                          }}
                        >
                          {t("event.alreadyRejected")}
                        </Badge>
                      </div>
                    )} */}
                  </div>
                )}
              </div>

              <div className="fs-6 fw-normal mt-4">
                {t("event.sendMessageToInvitationCreator")}
              </div>
              {/* <form onSubmit={handleSubmitMessage}> */}
              <form onSubmit={handleSubmit(handleSubmitMessage)}>
                <textarea
                  {...register("message")}
                  placeholder={`${t("event.typeYourMessageHere")}`}
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "10px",
                    borderRadius: "5px",
                    border: "1px solid #e6e6e6",
                    fontSize: "13px",
                    resize: "vertical",
                  }}
                  rows={4}
                />
                {errors.message && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors?.message?.message as string}
                  </p>
                )}
                <div className="d-flex justify-content-between mt-5">
                  {loadingMessage ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <div>
                      <Button
                        type="submit"
                        className="w-100 bg-dark text-light border-none"
                        style={{
                          border: "1px solid #e8e8e8",
                          maxWidth: "250px",
                        }}
                      >
                        <BiLogoTelegram className="me-3 mb-1" />
                        {t("event.sendMessage")}
                      </Button>
                    </div>
                  )}
                  <Button
                    type="button"
                    className="w-100 bg-light text-dark border-none"
                    style={{
                      border: "1px solid #e8e8e8",
                      maxWidth: "250px",
                    }}
                    onClick={handleShowOnTheMap}
                  >
                    <CiLocationOn className="me-3 mb-1" />
                    {t("event.showOnTheMap")}
                  </Button>
                </div>
              </form>
              <Card.Text></Card.Text>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className="container mt-3">
      {!loading && error && error !== null ? (
        <div>
          <div className="d-flex justify-content-center align-items-center">
            <IconAlertCircle />
          </div>
          <p>{error}</p>
        </div>
      ) : (
        <>
          {loading && (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {!loading && invitations && invitations?.length > 0 && (
            <>
              {step == 1 && <Invitation />}
              {step == 2 && <MyInvitationsDetail />}
            </>
          )}
          {!loading && invitations?.length == 0 && (
            <div>
              <NoInviteFound />
            </div>
          )}
        </>
      )}
      <ToastContainer
        style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
      />
    </div>
  )
}

export default MyInvitations
