import { useState } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Skeleton from "@/components/common/skeleton"
import failed from "@/assets/images/failed.svg" // Adjust the path as necessary

type SuccessMessageProps = {
  phoneNumber: string
  setStep: (step: number) => void
}
const FailedMessage = ({ phoneNumber, setStep }: SuccessMessageProps) => {
  const { t } = useTranslation()
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const handleImageLoad = () => {
    setIsLoadingImage(false)
  }
  const handleTryAgain = () => {
    // window.location.href = "/guest/create-event",
    setStep(2)
  }
  return (
    <div
      className=" d-flex flex-column flex-md-row align-items-center justify-content-center  ms-5 me-5 mb-5 mt-5"
      style={{ minHeight: "60vh", margin: "0 5rem", gap: "1em" }} // Ensure full height and margin
    >
      <div className="d-flex flex-column gap-3 gap-md-5  ms-0 ms-md-5 me-0 me-md-5 ">
        <h2 className="">Uh Oh!</h2>
        <h4 className="text-muted " style={{ maxWidth: "500px" }}>
          {`Seems like there was a problem sending an invitation to ${phoneNumber}
          `}
        </h4>
        <div className="d-flex flex-row gap-4">
          <Link
            style={{ borderRadius: "5px", padding: "10px" }}
            className=" mt-0 w-100 bg-dark text-white border- d-flex justify-content-center align-items-center reminders-page__link-container"
            to="/guest/create-event"
            onClick={handleTryAgain}
            // onClick={() => setStep(2)}
          >
            {t("event.tryDifferentNumber")}
          </Link>

          <Link
            style={{
              borderRadius: "5px",
              padding: "10px",
              border: "1px solid #e2e1e1",
            }}
            className=" mt-0 w-100 bg-light text-dark border- d-flex justify-content-center align-items-center reminders-page__link-container"
            to="https://api.whatsapp.com/send?phone=966505278757&text=i%20would%20like%20to%20know%20more%20about%20Mazoom"
            target={"_blank"}
            // onClick={() => setStep(1)}
          >
            {t("event.contactSupport")}{" "}
          </Link>
        </div>
        <Link
          style={{ borderRadius: "5px", padding: "10px" }}
          className=" mt-0 w-100 bg-dark text-white border- d-flex justify-content-center align-items-center reminders-page__link-container"
          to="/"
        >
          {t("event.goToDashboard")}
        </Link>
      </div>
      <div className="d-flex flex-column  ms-0 ms-md-5 me-0 me-md-5  ">
        {isLoadingImage && (
          <Skeleton width="180px" height="180px" borderRadius="8px" />
        )}
        <img
          src={failed}
          width="100%"
          height="180px"
          style={{ display: isLoadingImage ? "none" : "block" }}
          onLoad={handleImageLoad}
        />
      </div>{" "}
    </div>
  )
}
export default FailedMessage
