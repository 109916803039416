import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  IconAlertCircle,
  IconArrowLeft,
} from "../../components/utils/Icons/CustomIcons"
import { Container, Form, Button } from "react-bootstrap"
import QuizPass from "./QuizPass"
import ProgressBar from "react-bootstrap/ProgressBar"
import data from "./quiz.json"
import { getContactsOnEventMobile } from "@/redux/slices/contacts/contactsSlices"
import api from "@/api/api"
import Spinner from "react-bootstrap/Spinner"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import { useTranslation } from "react-i18next"

// import "./quiz.css"
// import "./quizStyle.css"
import { LiaCloneSolid } from "react-icons/lia"

// Define types for quiz data and selected answers
const checkboxStyle: React.CSSProperties = {
  borderRadius: "10%",
  width: "1.25rem",
  height: "1.25rem",
  position: "relative",
  // Remove 'appearance' from inline styles and add it via CSS directly to avoid TypeScript error
  border: "2px solid #ccc",
  cursor: "pointer",
}

const checkboxCheckedStyle: React.CSSProperties = {
  backgroundColor: "#000000",
}

const checkmarkStyle: React.CSSProperties = {
  content: '"\\2713"', // Unicode checkmark character
  color: "#fff",
  fontSize: "1rem",
  display: "block",
  textAlign: "center",
  position: "absolute",
  top: "-2px",
  left: "2px",
}

////for my stykles

const myCheckboxStyle: React.CSSProperties = {
  borderRadius: "50%",
  width: "1.25rem",
  height: "1.25rem",
  position: "relative",
  // Remove 'appearance' from inline styles and add it via CSS directly to avoid TypeScript error
  border: "2px solid #ccc",
  cursor: "pointer",
}

const myCheckboxCheckedStyle: React.CSSProperties = {
  backgroundColor: "#000000",
}

const myCheckmarkStyle: React.CSSProperties = {
  content: '"\\2713"', // Unicode checkmark character
  color: "#fff",
  fontSize: "1rem",
  display: "block",
  textAlign: "center",
  position: "absolute",
  top: "-2px",
  left: "2px",
}

// Additional CSS (you can add this to your CSS file or use a <style> tag)
const additionalStyles = `
.custom-checkbox {
  appearance: none; /* Adding this in CSS avoids TypeScript errors */
}
`

interface Question {
  id: number
  question: string
  options: { title: string; description: string }[] | []
  answer: string
}

interface SelectedAnswers {
  [key: number]: string
}

function getLetterByIndex(index: number) {
  return String.fromCharCode(64 + index) // 'A' has ASCII code 65
}

// Sample data

interface Option {
  label: string
  nextQuestionId: number | null
  action: string
}

// interface Question {
//   id: number
//   question: string
//   options: Option[]
// }

interface Answer {
  questionId: number
  answers: string[]
}
const Quiz = ({ setStep }: { setStep: (step: number) => void }) => {
   
   const { t } = useTranslation()

   const { lang } = useAppSelector((state) => state.auth)
   console.log("lang", lang)

   const [loading, setLoading] = useState(true)
   const [questionsFromBackEnd, setQuestionsFromBackend] = useState<any[]>([])

   const [progress, setProgress] = useState(data?.length)
   const [currentQuestionId, setCurrentQuestionId] = useState<number>(7)
   const [answers, setAnswers] = useState<any[]>([])
   const [selectedOptions, setSelectedOptions] = useState<any[]>([])
   const [quizComplete, setQuizComplete] = useState<boolean>(false) 
   const currentQuestion: any = questionsFromBackEnd?.find(
     (q: any) => q.id === currentQuestionId,
   )

   let quizAnswers: any
   const storedAnswers = localStorage.getItem("quizAnswers")
   if (storedAnswers) {
     quizAnswers = JSON.parse(storedAnswers)
   } else {
     quizAnswers = [] // or set to an appropriate default value
   }

   useEffect(() => {
     if (quizComplete) {
       const quizAnswer = localStorage.setItem(
         "quizAnswers",
         JSON.stringify(answers),
       )
     }
   }, [quizComplete])

   function areAllNextQuestionIdsSame(question: any) {
     if (question.choices.length === 0) return true

     const firstNextQuestionId = question.choices[0].next_question_id
     for (const choice of question.choices) {
       if (choice.next_question_id !== firstNextQuestionId) {
         return false
       }
     }
     return true
   }

   const handleCheckboxChange = (option: Option) => {
     // console.log("optiona", option)
     if (areAllNextQuestionIdsSame(currentQuestion)) {
       setSelectedOptions((prevSelected) =>
         prevSelected.includes(option)
           ? prevSelected.filter((item) => item !== option)
           : [...prevSelected, option],
       )
     } else {
       setSelectedOptions([option]) // Only allow one selection for singleSelect questions
     }

     ///this start
     // if (currentQuestion?.question_type === "single_choice") {
     //   setSelectedOptions([option]) // Only allow one selection for singleSelect questions
     // } else {
     //   // Toggle option for multiSelect
     //   setSelectedOptions((prevSelected) =>
     //     prevSelected.includes(option)
     //       ? prevSelected.filter((item) => item !== option)
     //       : [...prevSelected, option],
     //   )
     // }

     ///this end

     // setSelectedOptions((prevSelected) =>
     //   prevSelected.includes(option)
     //     ? prevSelected.filter((item) => item !== option)
     //     : [...prevSelected, option],
     // )
   }

   const handleNext = () => {
     if (selectedOptions.length === 0) return
     const nextAnswer = selectedOptions[0]
     const hasNullNextQuestion = selectedOptions.some(
       (option) => option.next_question_id === null,
     )
     setAnswers([
       ...answers,
       ...selectedOptions?.map((opt: any) => opt.id),
       // answers:[...selectedOptions?.map((opt:any) => opt.id)]
     ])

     if (hasNullNextQuestion) {
       setQuizComplete(true)
     } else if (nextAnswer.next_question_id) {
       setCurrentQuestionId(nextAnswer.next_question_id)
       setSelectedOptions([])
     }
   }

   const handleSkip = () => {
     if (currentQuestion?.choices?.length) {
       const randomOption = currentQuestion.choices[0] // Assuming first option for "Skip"
       setSelectedOptions([randomOption]) // Triggers the useEffect to handle quiz progress

       // const nextAnswer = selectedOptions[0]
       const nextAnswer = randomOption

       const hasNullNextQuestion = selectedOptions.some(
         (option) => option.next_question_id === null,
       )
       console.log(
         "hasNullNextQuestion-hasNullNextQuestion",
         hasNullNextQuestion,
       )

       setAnswers([
         ...answers,
         ...selectedOptions?.map((opt: any) => opt.id),
         // answers:[...selectedOptions?.map((opt:any) => opt.id)]
       ])

       if (hasNullNextQuestion) {
         setQuizComplete(true)
       } else if (nextAnswer.next_question_id) {
         setCurrentQuestionId(nextAnswer.next_question_id)
         setSelectedOptions([])
       }
     }
   }

   const handleSubmit = () => {
     console.log("Quiz completed with answers:", answers)
     setQuizComplete(true)
   }
   const handleSkipAll = () => {
     setQuizComplete(true) // Complete the quiz without answering remaining questions
   }
   const character = (index: number) => {
     const optionLabel = String.fromCharCode(65 + index)
     return optionLabel
   }

   useEffect(() => {
     setLoading(true)
     try {
       api.events
         .getQuestions()
         .then((data) => {
           console.log("data", data)
           setLoading(false)
           setQuestionsFromBackend(data?.data?.data)
         })
         .catch((err) => {
           setLoading(false)
         })
     } catch (err) {
       setLoading(false)
       console.log("err", err)
     }
   }, [])

   return (
     <div className="container mt-1">
       {loading ? (
         <div className="d-flex justify-content-center align-items-center">
           <Spinner animation="border" role="status">
             <span className="visually-hidden">Loading...</span>
           </Spinner>
         </div>
       ) : (
         <>
           {" "}
           <div className="mb-3 ">
             <Link
               className="d-flex align-items-center reminders-page__link-container"
               to="#"
               onClick={() => setStep(1)}
             >
               <IconArrowLeft />
               <span className="reminders-page__link-text ms-2 me-2">
                 {t("quiz.cancel")}
               </span>
             </Link>
           </div>
           <div className="quiz-container">
             {quizComplete ? (
               <QuizPass />
             ) : currentQuestion ? (
               <div className="">
                 <div>
                   {/* <ProgressBar
                    now={data?.findIndex((q) => q?.id === currentQuestionId)}
                    className="bg-white"
                    style={{
                      height: "8px", // Adjust to make it thinner
                      backgroundColor: "#000000", // Background color for unfilled part
                      border: "1px solid #767676",
                    }}
                    variant="black" // Use a Bootstrap variant if applicable
                  /> */}
                 </div>
                 {/* <div className="mt-4">
                  {" "}
                  {questionsFromBackEnd?.findIndex(
                    (q: any) => q?.id === currentQuestionId,
                  ) + 1}{" "}
                  / {questionsFromBackEnd?.length + 1}{" "}
                </div> */}
                 <h6 className="mt-3 fs-4 fw-medium ">
                   {/* {currentQuestion?.content}{" "} */}
                   {/* {currentQuestion?.content.replace(
                    "(Select all that apply)",
                    "",
                  )} */}

                   {lang == "ar"
                     ? currentQuestion?.content_ar
                     : currentQuestion?.content}
                 </h6>
                 <p className="mt-3 fs-6 fw-light text-muted">
                   {t("quiz.selectAllThatApply")}
                 </p>
                 {/* <ul className=""> */}
                 <div
                   className="d-flex flex-wrap col-12 align-items-center  "
                   style={{ minHeight: "25vh" }}
                 >
                   {currentQuestion?.choices?.map(
                     (option: any, index: number) => (
                       <>
                         {areAllNextQuestionIdsSame(currentQuestion) ? (
                           <div
                             key={index}
                             className="col-12 col-md-6 lg-6 pe-3  pb-3 "
                           >
                             {" "}
                             {/* Two options per row on medium screens */}
                             <div
                               // className="p-3 d-flex w-100"
                               className={` d-flex w-100`}
                               onClick={() => handleCheckboxChange(option)}
                               style={{
                                 backgroundColor: "white",
                                 border: selectedOptions.includes(option)
                                   ? "1px solid #767676"
                                   : "none",

                                 // padding: "100px",
                                 minHeight: "80px",
                                 padding: "15px",
                                 flexWrap: "wrap",
                                 alignItems: "stretch",
                                 cursor: "pointer",
                                 display: "flex",
                                 borderRadius: "10px",

                                 // boderRadius:"10px"
                               }}
                             >
                               <div className="d-flex align-items-center">
                                 <input
                                   // className="form-check-input custom-checkbox"
                                   className={`form-check-input custom-checkbox ${
                                     selectedOptions.includes(option)
                                       ? "checkbox-checked"
                                       : ""
                                   }`}
                                   type="checkbox"
                                   checked={selectedOptions.includes(option)}
                                   onChange={() => handleCheckboxChange(option)}
                                   style={{
                                     ...checkboxStyle,
                                     ...(selectedOptions.includes(option)
                                       ? checkboxCheckedStyle
                                       : {}),
                                   }}
                                 />
                                 <div
                                   className="ms-3 fw-light me-3"
                                   style={{
                                     fontSize: "0.60em",
                                     wordWrap: "break-word",
                                     whiteSpace: "normal",
                                     display: "flex",
                                     flexWrap: "wrap",
                                     marginTop: "5px",
                                   }}
                                 >
                                   {/* {option.content} */}

                                   {lang == "ar"
                                     ? option.content_ar
                                     : option.content}
                                 </div>
                               </div>
                             </div>
                           </div>
                         ) : (
                           <div
                             key={index}
                             className="col-12 col-md-6 lg-6 pe-3  pb-3 "
                           >
                             {" "}
                             {/* Two options per row on medium screens */}
                             <div
                               key={index}
                               // className="p-3 d-flex w-100"
                               className={` col-12 col-md-6 lg-6 pe-3  pb-3 w-100`}
                               onClick={() => handleCheckboxChange(option)}
                               style={{
                                 backgroundColor: "white",
                                 border: selectedOptions.includes(option)
                                   ? "1px solid #767676"
                                   : "none",
                                 // padding: "100px",
                                 minHeight: "80px",
                                 padding: "15px",
                                 flexWrap: "wrap",
                                 alignItems: "stretch",
                                 cursor: "pointer",
                                 display: "flex",
                                 borderRadius: "10px",
                                 // boderRadius:"10px"
                                 // padding: "50px",
                               }}
                             >
                               <div
                                 className="d-flex align-items-center"
                                 // checked={selectedOptions.includes(option)}
                               >
                                 <input
                                   type="checkbox"
                                   checked={selectedOptions.includes(option)}
                                   onChange={() => handleCheckboxChange(option)}
                                   className={`form-check-input custom-checkbox ${
                                     selectedOptions.includes(option)
                                       ? "checkbox-checked"
                                       : ""
                                   }`}
                                   style={{
                                     ...myCheckboxStyle,
                                     ...(selectedOptions.includes(option)
                                       ? myCheckboxCheckedStyle
                                       : {}),
                                   }}
                                 />
                                 <div
                                   className="ms-3 fw-light me-2"
                                   style={{
                                     fontSize: "0.60em",
                                     wordWrap: "break-word",
                                     whiteSpace: "normal",
                                     display: "flex",
                                     flexWrap: "wrap",
                                     marginTop: "5px",
                                   }}
                                 >
                                   {/* {option.content} */}
                                   {console.log("lang == ar", lang == "ar")}
                                   {lang == "ar"
                                     ? option.content_ar
                                     : option.content}
                                 </div>
                               </div>
                             </div>
                           </div>
                         )}
                       </>
                     ),
                   )}
                 </div>
                 {/* </ul> */}
                 <div className="mt-4 d-flex align-items-center gap-2 ">
                   {/* <Button
                    onClick={handleSkip}
                    className="btn-light  text-dark me-2 border-1"
                  >
                    Skip
                  </Button> */}

                   <Link
                     to="/guest/create-event"
                     style={{
                       borderRadius: "5px",
                       // padding: "10px",
                       textDecoration: "none",
                       textTransform: "none",
                     }}
                   >
                     <Button
                       className="text-dark d-block border-1"
                       style={{
                         backgroundColor: "transparent",
                         border: "1px solid #cfcfcf",
                       }}
                     >
                       {t("quiz.skipAll")}
                     </Button>
                   </Link>

                   <Button
                     onClick={handleNext}
                     disabled={selectedOptions.length === 0}
                     className="btn-dark border-0 text-white me-2"
                   >
                     {t("quiz.next")}
                   </Button>
                 </div>
               </div>
             ) : (
               <p>No more questions available.</p>
             )}
           </div>
         </>
       )}
     </div>
   )
}
export default Quiz
