import { useTypeSelector } from "@/hooks/useTypeSelector"
import { useEffect, useRef, useState } from "react"
import { Layer, Stage, Text, Transformer } from "react-konva"
import toast from "react-hot-toast"
import { useActions } from "@/hooks/useActions"
import { useNavigate, useParams } from "react-router-dom"
import Konva from "konva"
import Select, { SelectInstance } from "react-select"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import { QRCodeSVG } from "qrcode.react"
import {
  sendQrDesign,
  setLoading,
  updateQrDesign,
  uploadQRImage,
} from "@/redux/slices/events/eventsSlice"
import { URLImage } from "./QRCodeDownLoad"
import { QRCodeImageButton } from "./QRCodeImageButton"

const guests = [
  {
    value: "Mrs Sama Al-Rayez",
    label: "Mrs Sama Al-Rayez",
    type: "suffix_full_name",
  },
  { value: "Mrs Sama", label: "Mrs Sama", type: "suffix_name" },
  { value: "Mrs Al-Rayez", label: "Mrs Al-Rayez", type: "suffix_surname" },
  {
    value: "Sama Al-Rayez",
    label: "Sama Al-Rayez",
    type: "full_name",
  },
  { value: "Sama", label: "Sama", type: "first_name" },
  { value: "Al-Rayez", label: "Al-Rayez", type: "surname" },
]

const alignOptions = [
  {
    value: "left",
    label: <img src="/vector/QrDesign/alignt_format_left.svg" />,
  },
  {
    value: "center",
    label: <img src="/vector/QrDesign/alignt_format_center.svg" />,
  },
  {
    value: "right",
    label: <img src="/vector/QrDesign/alignt_format_right.svg" />,
  },
]

const fontOptions = [
  {
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    label: "Inter",
    value: "Inter",
  },
  {
    label: "Roboto",
    value: "Roboto",
  },
  {
    label: "SF Pro Display",
    value: "SF Pro Display",
  },
  {
    label: "Barlow",
    value: "Barlow",
  },
]

const getContent = (obj: any) => {
  if (
    obj.fontStyle.includes("bold") &&
    obj.fontStyle.includes("italic") &&
    obj.textDecoration === "underline"
  ) {
    return `<b><u><i>${obj.textValue}</i></u></b>`
  } else if (
    obj.fontStyle.includes("bold") &&
    obj.fontStyle.includes("italic") &&
    obj.textDecoration !== "underline"
  ) {
    return `<b><i>${obj.textValue}</i></b>`
  } else if (
    !obj.fontStyle.includes("bold") &&
    obj.fontStyle.includes("italic") &&
    obj.textDecoration === "underline"
  ) {
    return `<u><i>${obj.textValue}</i></u>`
  } else if (
    obj.fontStyle.includes("bold") &&
    !obj.fontStyle.includes("italic") &&
    obj.textDecoration === "underline"
  ) {
    return `<b><u>${obj.textValue}</u></b>`
  } else if (
    !obj.fontStyle.includes("bold") &&
    !obj.fontStyle.includes("italic") &&
    obj.textDecoration !== "underline"
  ) {
    return `${obj.textValue}`
  } else if (
    obj.fontStyle.includes("bold") &&
    !obj.fontStyle.includes("italic") &&
    obj.textDecoration !== "underline"
  ) {
    return `<b>${obj.textValue}</b>`
  } else if (
    !obj.fontStyle.includes("bold") &&
    obj.fontStyle.includes("italic") &&
    obj.textDecoration !== "underline"
  ) {
    return `<i>${obj.textValue}</i>`
  } else if (
    !obj.fontStyle.includes("bold") &&
    !obj.fontStyle.includes("italic") &&
    obj.textDecoration === "underline"
  ) {
    return `<u>${obj.textValue}</u>`
  }
}

interface iFile {
  name: string
  size: number
  type: string
}

type Props = {
  fontValue: string
  alignValue: string
  color: string
  colorText: string
  bold: boolean
  italic: boolean
  underlined: boolean
  textVisible: boolean
  qrVisible: boolean
  countVisible: boolean
  selectVisible: boolean
  resetFilters: any
  setShowOthers: any
  setShowModal: any
  setSavePreview: any
  savePreview: boolean
  qrDesignName: string
  file: iFile
  setEdit: any
  setQrDesignName: any
  deleteAll: boolean
  setDeleteAll: any
  show: boolean
  setColor: any
  setColorText: any
  setFontValue: any
  setAlignValue: any
  setCountVisible: any
  setShow: any
  updateText: boolean
  setUpdateText: any
  setTextVisible: any
  setQrVisible: any
  setSelectVisible: any
  edit: boolean
}

type TextBlock = {
  x: number
  y: number
  width: number
  height: number
  font_size: number
  font_family: string
  color: string
  background_color: string
  align: string
  content: string | undefined
}

type QRBlock = {
  x: number
  y: number
} | null

type SelectBlock = {
  x: number
  y: number
} | null

type CountBlock = {
  x: number
  y: number
} | null

const QrCodeCanvasInput = ({
  fontValue,
  alignValue,
  color,
  colorText,
  bold,
  italic,
  underlined,
  textVisible,
  qrVisible,
  countVisible,
  setCountVisible,
  setTextVisible,
  setQrVisible,
  setSelectVisible,
  selectVisible,
  resetFilters,
  setShowOthers,
  setShowModal,
  setSavePreview,
  savePreview,
  qrDesignName,
  setColor,
  setColorText,
  file,
  setEdit,
  setQrDesignName,
  deleteAll,
  setDeleteAll,
  show,
  setFontValue,
  setAlignValue,
  setShow,
  updateText,
  setUpdateText,
  edit,
}: Props) => {
  const dispatch = useAppDispatch()
  const croppedImagePath = useTypeSelector((state) => state.qr.croppedImagePath)
  const { currentEvent, qr_image, qr_design, qr_id, updated_qr_design } =
    useAppSelector((state) => state.events)
  const [guest, setGuest] = useState({
    value: "Mrs Sama Al-Rayez",
    label: "Mrs Sama Al-Rayez",
  })
  const [showSelect, setShowSelect] = useState(false)
  const [showText, setShowText] = useState(false)
  const [textBlock, setTextBlock] = useState<TextBlock[]>([])
  const [qrBlock, setQrBlock] = useState<QRBlock>()
  const [countBlock, setCountBlock] = useState<CountBlock>()
  const [selectBlock, setSelectBlock] = useState<SelectBlock>()
  const [height, setHeight] = useState(36)
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  const { deleteImage, setImage, setCroppedImagePath } = useActions()
  const { event_id } = useParams()
  const canvasRef = useRef<any>(null)
  const textNodeRef = useRef<any>(null)
  const trRef = useRef<any>(null)
  const inputRef = useRef<any>(null)
  const ref = useRef<SelectInstance>(null)
  const notify = (text: string) => toast.error(text)
  const notifySuccess = (text: string) => toast.success(text)
  const navigate = useNavigate()
  const imageClose = new Image()
  imageClose.src = "/vector/QrDesign/removing.svg"
  imageClose.alt = "Close"
  const imageEdit = new Image()
  imageEdit.src = "/vector/QrDesign/editing.svg"
  imageEdit.alt = "Edit"
  const imageArrow = new Image()
  imageArrow.src = "/vector/QrDesign/down-arrow.svg"
  imageArrow.alt = "Arrow"
  const qrContainerRef = useRef<HTMLDivElement | null>(null)
  const [imageSrc, setImageSrc] = useState<HTMLImageElement | null>(null)

  const getStyle = () => {
    if (bold && italic) {
      return "italic bold"
    } else if (bold && !italic) {
      return "bold"
    } else if (!bold && italic) {
      return "italic"
    } else {
      return "normal"
    }
  }

  const [newTextObj, setNewTextObj] = useState({
    textEditVisible: false,
    isEditable: false,
    x: 50,
    y: 50,
    color: color,
    fill: colorText,
    textValue: "Add text",
    fontSize: width > 767 ? (width < 1280 ? 24 : 36) : 18,
    fontFamily: fontValue,
    fontStyle: getStyle(),
    width: width > 767 ? 200 : 125,
    height: width > 767 ? (width < 1280 ? 38 : 52) : 31,
    align: alignValue,
    id: 0,
    textDecoration: underlined ? "underline" : "empty string",
  })

  const [countObj, setCountObj] = useState({
    x: 50,
    y: 50,
    textEditVisible: false,
    isEditable: false,
    color: color,
    fill: colorText,
    textValue: "1",
    fontSize: width > 767 ? 18 : 12,
    fontFamily: fontValue,
    fontStyle: getStyle(),
    width: width > 767 ? (width < 1280 ? 41 : 49) : 38,
    height: width > 767 ? 44 : 36,
    align: alignValue,
    textDecoration: underlined ? "underline" : "empty string",
  })

  const [selectObj, setSelectObj] = useState({
    textEditVisible: false,
    isEditable: false,
    x: 50,
    y: 50,
    fill: colorText,
    color: color,
    textValue: guest.value,
    fontSize: width > 767 ? 18 : 12,
    fontFamily: fontValue,
    fontStyle: getStyle(),
    width: width > 767 ? 212 : 157,
    height: width > 767 ? 44 : 38,
    align: alignValue,
    id: 0,
    textDecoration: underlined ? "underline" : "empty string",
  })

  const [qrObj, setQrObj] = useState({
    textEditVisible: false,
    isEditable: false,
    width: width > 1279 ? 187 : width > 767 ? 125 : 72,
    height: width > 1279 ? 187 : width > 767 ? 125 : 72,
    x: 150,
    y: 150,
    color: color,
    fill: colorText,
  })

  const [selectX, setSelectX] = useState({ x: selectObj.x, y: selectObj.y })
  const [textX, setTextX] = useState({ x: newTextObj.x, y: newTextObj.y })

  useEffect(() => {
    if (qrContainerRef.current) {
      const svgElement = qrContainerRef.current.querySelector("svg")
      if (svgElement) {
        const svgString = new XMLSerializer().serializeToString(svgElement)
        const svgBlob = new Blob([svgString], {
          type: "image/svg+xml;charset=utf-8",
        })
        const url = URL.createObjectURL(svgBlob)

        const img = new Image()
        img.onload = () => {
          setImageSrc(img)
          URL.revokeObjectURL(url)
        }
        img.src = url
      }
    }
  }, [color, colorText, qrObj.color, qrObj.fill])

  const onDelete = () => {
    deleteImage()
    setImage(null)
    setCroppedImagePath(null)
  }

  useEffect(() => {
    const updateWidowSizes = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", updateWidowSizes)
    return () => {
      window.removeEventListener("resize", updateWidowSizes)
    }
  }, [])

  useEffect(() => {
    if (qr_design !== null || updated_qr_design !== null) {
      setSavePreview(true)
      if (
        qr_design !== null &&
        qr_design.design.guests_amount_block.display === true &&
        updated_qr_design === null
      ) {
        setCountVisible(true)
        setCountBlock({
          x: qr_design.design.guests_amount_block.x,
          y: qr_design.design.guests_amount_block.y,
        })
        setCountObj({
          ...countObj,
          x:
            (qr_design.design.guests_amount_block.x / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          y:
            (qr_design.design.guests_amount_block.y / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          color: qr_design.design.guests_amount_block.background_color,
          fill: qr_design.design.guests_amount_block.color,
          fontSize: width > 767 ? 18 : 12,
          fontFamily: qr_design.design.guests_amount_block.font_family,
          fontStyle:
            qr_design.design.guests_amount_block.bold === true
              ? qr_design.design.guests_amount_block.italic === true
                ? "italic bold"
                : "bold"
              : qr_design.design.guests_amount_block.italic === true
              ? "italic"
              : "normal",
          width: width > 767 ? (width < 1280 ? 41 : 49) : 38,
          height: width > 767 ? 44 : 36,
          align: qr_design.design.guests_amount_block.align,
          textDecoration: qr_design.design.guests_amount_block.underline
            ? "underline"
            : "empty string",
        })
      } else if (
        updated_qr_design !== null &&
        updated_qr_design.guests_amount_block.display === true
      ) {
        setCountVisible(true)
        setCountBlock({
          x: updated_qr_design?.guests_amount_block.x,
          y: updated_qr_design?.guests_amount_block.y,
        })
        setCountObj({
          ...countObj,
          x:
            (updated_qr_design?.guests_amount_block.x / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          y:
            (updated_qr_design?.guests_amount_block.y / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          color: updated_qr_design?.guests_amount_block.background_color,
          fill: updated_qr_design?.guests_amount_block.color,
          fontSize: width > 767 ? 18 : 12,
          fontFamily: updated_qr_design?.guests_amount_block.font_family,
          fontStyle:
            updated_qr_design?.guests_amount_block.bold === true
              ? updated_qr_design?.guests_amount_block.italic === true
                ? "italic bold"
                : "bold"
              : updated_qr_design?.guests_amount_block.italic === true
              ? "italic"
              : "normal",
          width: width > 767 ? (width < 1280 ? 41 : 49) : 38,
          height: width > 767 ? 44 : 36,
          align: updated_qr_design?.guests_amount_block.align,
          textDecoration: updated_qr_design?.guests_amount_block.underline
            ? "underline"
            : "empty string",
        })
      }

      if (
        qr_design !== null &&
        qr_design.design.name_block.display === true &&
        updated_qr_design === null
      ) {
        setSelectVisible(true)
        setSelectBlock({
          x: qr_design.design.name_block.x,
          y: qr_design.design.name_block.y,
        })
        setSelectObj({
          ...selectObj,
          x:
            (qr_design.design.name_block.x / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          y:
            (qr_design.design.name_block.y / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          color: qr_design.design.name_block.background_color,
          fill: qr_design.design.name_block.color,
          fontSize: width > 767 ? 18 : 12,
          fontFamily: qr_design.design.name_block.font_family,
          fontStyle:
            qr_design.design.name_block.bold === true
              ? qr_design.design.name_block.italic === true
                ? "italic bold"
                : "bold"
              : qr_design.design.name_block.italic === true
              ? "italic"
              : "normal",
          width: width > 767 ? 212 : 157,
          height: width > 767 ? 44 : 38,
          align: qr_design.design.name_block.align,
          textDecoration: qr_design.design.name_block.underline
            ? "underline"
            : "empty string",
        })
      } else if (
        updated_qr_design !== null &&
        updated_qr_design.name_block.display === true
      ) {
        setSelectVisible(true)
        setSelectBlock({
          x: updated_qr_design?.name_block.x,
          y: updated_qr_design?.name_block.y,
        })
        setSelectObj({
          ...selectObj,
          x:
            (updated_qr_design?.name_block.x / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          y:
            (updated_qr_design?.name_block.y / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          color: updated_qr_design?.name_block.background_color,
          fill: updated_qr_design?.name_block.color,
          fontSize: width > 767 ? 18 : 12,
          fontFamily: updated_qr_design?.name_block.font_family,
          fontStyle:
            updated_qr_design?.name_block.bold === true
              ? updated_qr_design?.name_block.italic === true
                ? "italic bold"
                : "bold"
              : updated_qr_design?.name_block.italic === true
              ? "italic"
              : "normal",
          width: width > 767 ? 212 : 157,
          height: width > 767 ? 44 : 38,
          align: updated_qr_design?.name_block.align,
          textDecoration: updated_qr_design?.name_block.underline
            ? "underline"
            : "empty string",
        })
      }

      if (
        qr_design !== null &&
        qr_design.design.qr_code_block.display === true &&
        updated_qr_design === null
      ) {
        setQrVisible(true)
        setQrObj({
          ...qrObj,
          width: width > 1279 ? 187 : width > 767 ? 125 : 72,
          height: width > 1279 ? 187 : width > 767 ? 125 : 72,
          x:
            (qr_design.design.qr_code_block.x / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          y:
            (qr_design.design.qr_code_block.y / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          color: qr_design.design.qr_code_block.color,
          fill: qr_design.design.qr_code_block.background_color,
        })
        setQrBlock({
          x: qr_design.design.qr_code_block.x,
          y: qr_design.design.qr_code_block.y,
        })
      } else if (
        updated_qr_design !== null &&
        updated_qr_design?.qr_code_block.display === true
      ) {
        setQrVisible(true)
        setQrObj({
          ...qrObj,
          width: width > 1279 ? 187 : width > 767 ? 125 : 72,
          height: width > 1279 ? 187 : width > 767 ? 125 : 72,
          x:
            (updated_qr_design?.qr_code_block.x / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          y:
            (updated_qr_design?.qr_code_block.y / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          color: updated_qr_design?.qr_code_block.color,
          fill: updated_qr_design?.qr_code_block.background_color,
        })
        setQrBlock({
          x: updated_qr_design?.qr_code_block.x,
          y: updated_qr_design?.qr_code_block.y,
        })
      }

      if (
        qr_design !== null &&
        qr_design.design.text_blocks.length > 0 &&
        updated_qr_design === null
      ) {
        setTextVisible(true)
        setNewTextObj({
          ...newTextObj,
          x:
            (qr_design.design.text_blocks[0].x / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          y:
            (qr_design.design.text_blocks[0].y / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          color: qr_design.design.text_blocks[0].background_color,
          fill: qr_design.design.text_blocks[0].color,
          fontSize: width > 767 ? 18 : 12,
          fontFamily: qr_design.design.text_blocks[0].font_family,
          textValue: qr_design.design.text_blocks[0].content.replace(
            /<[^>]+>/g,
            "",
          ),
          fontStyle:
            qr_design.design.text_blocks[0].content.includes("<b>") === true
              ? qr_design.design.text_blocks[0].content.includes("<i>") === true
                ? "italic bold"
                : "bold"
              : qr_design.design.text_blocks[0].content.includes("<i>") === true
              ? "italic"
              : "normal",
          width:
            (qr_design.design.text_blocks[0].width / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          height:
            (qr_design.design.text_blocks[0].height / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          align: qr_design.design.text_blocks[0].align,
          textDecoration: qr_design.design.text_blocks[0].content.includes(
            "<u>",
          )
            ? "underline"
            : "empty string",
        })
      } else if (
        updated_qr_design !== null &&
        updated_qr_design?.text_blocks.length > 0
      ) {
        setTextVisible(true)
        setNewTextObj({
          ...newTextObj,
          x:
            (updated_qr_design?.text_blocks[0].x / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          y:
            (updated_qr_design?.text_blocks[0].y / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          color: updated_qr_design?.text_blocks[0].background_color,
          fill: updated_qr_design?.text_blocks[0].color,
          fontSize: width > 767 ? 18 : 12,
          fontFamily: updated_qr_design?.text_blocks[0].font_family,
          textValue: updated_qr_design?.text_blocks[0].content.replace(
            /<[^>]+>/g,
            "",
          ),
          fontStyle:
            updated_qr_design?.text_blocks[0].content.includes("<b>") === true
              ? updated_qr_design?.text_blocks[0].content.includes("<i>") ===
                true
                ? "italic bold"
                : "bold"
              : updated_qr_design?.text_blocks[0].content.includes("<i>") ===
                true
              ? "italic"
              : "normal",
          width:
            (updated_qr_design?.text_blocks[0].width / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          height:
            (updated_qr_design?.text_blocks[0].height / 100) *
            (width > 1279 ? 640 : width > 767 ? 408 : 268),
          align: updated_qr_design?.text_blocks[0].align,
          textDecoration: updated_qr_design?.text_blocks[0].content.includes(
            "<u>",
          )
            ? "underline"
            : "empty string",
        })
      }
    }
  }, [qr_design, qr_image, updated_qr_design])

  useEffect(() => {
    if (updateText === true) {
      setNewTextObj({
        ...newTextObj,
        x: 50,
        y: 50,
        width: width > 767 ? 200 : 125,
        height: width > 767 ? (width < 1280 ? 38 : 52) : 31,
      })
      setUpdateText(false)
    }
  }, [updateText])

  useEffect(() => {
    if (newTextObj.textEditVisible === true) {
      trRef.current && trRef.current.nodes([textNodeRef.current])
    } else {
      trRef.current && trRef.current.nodes([])
    }
  }, [newTextObj])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length,
      )
    }
  }, [showText])

  useEffect(() => {
    if (deleteAll === true) {
      setNewTextObj({
        textEditVisible: false,
        isEditable: false,
        x: 50,
        y: 50,
        color: "white",
        fill: "black",
        textValue: "Add text",
        fontSize: width > 767 ? (width < 1280 ? 24 : 36) : 18,
        fontFamily: fontValue,
        fontStyle: getStyle(),
        width: width > 767 ? 200 : 125,
        height: width > 767 ? (width < 1280 ? 38 : 52) : 31,
        align: alignValue,
        id: 0,
        textDecoration: underlined ? "underline" : "empty string",
      })
      setSelectObj({
        textEditVisible: false,
        isEditable: false,
        x: 50,
        y: 50,
        color: "white",
        fill: "black",
        textValue: guest.value,
        fontSize: width > 767 ? 18 : 12,
        fontFamily: fontValue,
        fontStyle: getStyle(),
        width: width > 767 ? 212 : 157,
        height: width > 767 ? 44 : 36,
        align: alignValue,
        id: 0,
        textDecoration: underlined ? "underline" : "empty string",
      })
      setQrObj({
        textEditVisible: false,
        isEditable: false,
        width: width > 767 ? (width < 1280 ? 125 : 187) : 72,
        height: width > 767 ? (width < 1280 ? 125 : 187) : 72,
        x: 150,
        y: 150,
        color: "white",
        fill: "black",
      })
      setCountObj({
        x: 50,
        y: 50,
        textEditVisible: false,
        isEditable: false,
        color: "white",
        fill: "black",
        textValue: "1",
        fontSize: width > 767 ? 18 : 12,
        fontFamily: fontValue,
        fontStyle: getStyle(),
        width: width > 767 ? (width < 1280 ? 41 : 49) : 38,
        height: width > 767 ? 44 : 36,
        align: alignValue,
        textDecoration: underlined ? "underline" : "empty string",
      })
      setCountBlock(null)
      setQrBlock(null)
      setSelectBlock(null)
      setTextBlock([])
    } else {
      trRef.current && trRef.current.nodes([])
    }
  }, [deleteAll])

  const handleTextEdit = (e: any) => {
    setNewTextObj({
      ...newTextObj,
      textValue: e.target.value,
      height: Math.round(textNodeRef.current.height()),
    })
    setHeight(Math.round(textNodeRef.current.height()))
  }

  useEffect(() => {
    textNodeRef.current && setHeight(textNodeRef.current.height())
    newTextObj.textEditVisible &&
      setNewTextObj({
        ...newTextObj,
        fill: colorText,
        color: color,
        height: height,
        fontFamily: fontValue,
        align: alignValue,
        fontStyle: getStyle(),
        textDecoration: underlined ? "underline" : "empty string",
      })
    countObj.textEditVisible &&
      setCountObj({
        ...countObj,
        fill: colorText,
        color: color,
        fontFamily: fontValue,
        align: alignValue,
        fontStyle: getStyle(),
        textDecoration: underlined ? "underline" : "empty string",
      })
    selectObj.textEditVisible &&
      setSelectObj({
        ...selectObj,
        fill: colorText,
        color: color,
        fontFamily: fontValue,
        align: alignValue,
        fontStyle: getStyle(),
        textDecoration: underlined ? "underline" : "empty string",
        textValue: guest.value,
      })
    qrObj.textEditVisible &&
      setQrObj({
        ...qrObj,
        color: color,
        fill: colorText,
      })
  }, [
    textNodeRef,
    fontValue,
    colorText,
    color,
    alignValue,
    bold,
    italic,
    underlined,
    guest,
    height,
    canvasRef,
  ])

  const handleTextareaKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      setShowText(false)
    }
  }

  const handleCloseTextArea = (e: any) => {
    const absPos = e.target.getAbsolutePosition()
    setNewTextObj({ ...newTextObj, isEditable: false, textEditVisible: false })
    resetFilters()
    setTextBlock([
      {
        x:
          absPos.x > 0
            ? (absPos.x / (width > 1279 ? 640 : width > 767 ? 408 : 268)) * 100
            : 0,
        y:
          absPos.y > 0
            ? (absPos.y / (width > 1279 ? 640 : width > 767 ? 408 : 268)) * 100
            : 0,
        width:
          (newTextObj.width / (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
          100,
        height:
          (newTextObj.height / (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
          100,
        font_size:
          (newTextObj.fontSize /
            (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
          100,
        font_family: newTextObj.fontFamily,
        color: newTextObj.fill,
        background_color: newTextObj.color,
        align: newTextObj.align,
        content: getContent(newTextObj),
      },
    ])
  }

  const handleCloseQR = (e: any) => {
    const absPos = e.target.getAbsolutePosition()
    setQrObj({ ...qrObj, isEditable: false, textEditVisible: false })
    resetFilters()
    setShowOthers(true)
    setQrBlock({
      x:
        absPos.x > 0
          ? (absPos.x / (width > 1279 ? 640 : width > 767 ? 408 : 268)) * 100
          : 0,
      y:
        absPos.y > 0
          ? (absPos.y / (width > 1279 ? 640 : width > 767 ? 408 : 268)) * 100
          : 0,
    })
  }

  const handleCloseSelect = (e: any) => {
    const absPos = e.target.getAbsolutePosition()
    setShowSelect(false)
    setSelectObj({ ...selectObj, isEditable: false, textEditVisible: false })
    resetFilters()
    setSelectBlock({
      x:
        absPos.x > 0
          ? (absPos.x / (width > 1279 ? 640 : width > 767 ? 408 : 268)) * 100
          : 0,
      y:
        absPos.y > 0
          ? (absPos.y / (width > 1279 ? 640 : width > 767 ? 408 : 268)) * 100
          : 0,
    })
    setMenuIsOpen(false)
  }

  const handleKeyDown = (e: any) => {
    const absPos = e.target.getAbsolutePosition()
    countObj.isEditable &&
      setCountObj({ ...countObj, isEditable: false, textEditVisible: false })
    resetFilters()
    setCountBlock({
      x:
        absPos.x > 0
          ? (absPos.x / (width > 1279 ? 640 : width > 767 ? 408 : 268)) * 100
          : 0,
      y:
        absPos.y > 0
          ? (absPos.y / (width > 1279 ? 640 : width > 767 ? 408 : 268)) * 100
          : 0,
    })
  }

  // const handleQrKeyDown = (e) => {
  //   resetFilters()
  //   setShowOthers(true)
  //   qrObj.isEditable &&
  //     setQrObj({
  //       ...qrObj,
  //       isEditable: false,
  //       textEditVisible: false,
  //       color: color,
  //     })
  //   resetFilters()
  // }

  const handleSelectKeyDown = (e: any) => {
    selectObj.isEditable && setGuest(e)
    setSelectObj({
      ...selectObj,
      textValue: guest.value,
    })
    setMenuIsOpen(false)
  }

  const handleQRDblClick = () => {
    setShowOthers(true)
    if (!savePreview) {
      setSelectObj({
        ...selectObj,
        isEditable: false,
        textEditVisible: false,
      })
      setNewTextObj({
        ...newTextObj,
        isEditable: false,
        textEditVisible: false,
      })
      setCountObj({ ...countObj, isEditable: false, textEditVisible: false })
      setQrObj({
        ...qrObj,
        isEditable: true,
        textEditVisible: true,
      })
      setShow(true)
      setColor(qrObj.color)
      setColorText(qrObj.fill)
      setShowOthers(false)
    }
  }

  const handleTextDblClick = (e: any, obj: any, func: any) => {
    setShowOthers(true)
    if (!savePreview) {
      if (obj === newTextObj) {
        setSelectObj({
          ...selectObj,
          isEditable: false,
          textEditVisible: false,
        })
        setShow(true)
        setQrObj({ ...qrObj, isEditable: false, textEditVisible: false })
        setCountObj({ ...countObj, isEditable: false, textEditVisible: false })
        setColor(newTextObj.color)
        setColorText(newTextObj.fill)
        setFontValue(
          fontOptions.find((item) => item.label === newTextObj.fontFamily),
        )
        setAlignValue(
          alignOptions.find((item) => item.value === newTextObj.align),
        )
      } else if (obj === selectObj) {
        setNewTextObj({
          ...newTextObj,
          isEditable: false,
          textEditVisible: false,
        })

        setShow(true)
        setQrObj({ ...qrObj, isEditable: false, textEditVisible: false })
        setCountObj({ ...countObj, isEditable: false, textEditVisible: false })
        setColor(selectObj.color)
        setColorText(selectObj.fill)
        setFontValue(
          fontOptions.find((item) => item.label === selectObj.fontFamily),
        )
        setAlignValue(
          alignOptions.find((item) => item.value === selectObj.align),
        )
      } else if (obj === qrObj) {
        setSelectObj({
          ...selectObj,
          isEditable: false,
          textEditVisible: false,
        })
        setNewTextObj({
          ...newTextObj,
          isEditable: false,
          textEditVisible: false,
        })
        setCountObj({ ...countObj, isEditable: false, textEditVisible: false })
        setQrObj({
          ...qrObj,
          isEditable: true,
          textEditVisible: true,
        })
        setShow(true)
        setColor(qrObj.color)
        setShowOthers(false)
      } else {
        setNewTextObj({
          ...newTextObj,
          isEditable: false,
          textEditVisible: false,
        })
        setSelectObj({
          ...selectObj,
          isEditable: false,
          textEditVisible: false,
        })
        setShow(true)
        setQrObj({ ...qrObj, isEditable: false, textEditVisible: false })
        setColor(countObj.color)
        setColorText(countObj.fill)
        setFontValue(
          fontOptions.find((item) => item.label === selectObj.fontFamily),
        )
        setAlignValue(
          alignOptions.find((item) => item.value === selectObj.align),
        )
      }

      func({
        ...obj,
        isEditable: true,
        textEditVisible: true,
      })
    }
  }

  // const deleteText = () => {
  //   setTextVisible(false)
  //   setNewTextObj({
  //     ...newTextObj,
  //     textValue: "Add text",
  //   })
  // }

  // const deleteSelect = () => {
  //   setSelectVisible(false)
  //   setSelectObj({
  //     ...selectObj,
  //     textValue: guests[0].value,
  //   })
  // }

  // const deleteCount = () => {
  //   setCountVisible(false)
  //   resetFilters()
  // }

  // const deleteQr = (e) => {
  //   if (e.key === 'Delete') {
  //     setQrVisible(false)
  //     setQrObj({
  //       ...qrObj,
  //       color: "white",
  //     })
  //   }
  // }

  const openPreview = () => {
    setShowOthers(true)
    setSelectObj({ ...selectObj, isEditable: false, textEditVisible: false })
    setNewTextObj({ ...newTextObj, isEditable: false, textEditVisible: false })
    setQrObj({ ...qrObj, isEditable: false, textEditVisible: false })
    setCountObj({ ...countObj, isEditable: false, textEditVisible: false })
    const guest = guests.find((item) => item.value === selectObj.textValue)
    ;(currentEvent && currentEvent.qr_code_design_id !== null) || qr_id !== null
      ? dispatch(
          updateQrDesign({
            id:
              currentEvent && currentEvent.qr_code_design_id !== null
                ? currentEvent.qr_code_design_id
                : qr_id,
            params: {
              name: qrDesignName,
              guests_amount_block: {
                display: countVisible ? true : false,
                x: countBlock
                  ? countBlock.x
                  : countObj.x > 0
                  ? (countObj.x /
                      (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
                y: countBlock
                  ? countBlock.y
                  : countObj.y > 0
                  ? (countObj.y /
                      (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
                width:
                  (countObj.width /
                    (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100,
                height:
                  (countObj.height /
                    (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100,
                font_size:
                  (countObj.fontSize /
                    (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100,
                font_family: countObj.fontFamily,
                color: countObj.fill,
                background_color: countObj.color,
                align: countObj.align,
                bold: countObj.fontStyle.includes("bold") ? true : false,
                italic: countObj.fontStyle.includes("italic") ? true : false,
                underline:
                  countObj.textDecoration === "underline" ? true : false,
              },
              name_block: {
                display: selectVisible ? true : false,
                x: selectBlock
                  ? selectBlock.x
                  : selectObj.x > 0
                  ? (selectObj.x /
                      (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
                y: selectBlock
                  ? selectBlock.y
                  : selectObj.y > 0
                  ? (selectObj.y /
                      (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
                width:
                  (selectObj.width /
                    (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100,
                height:
                  (selectObj.height /
                    (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100,
                font_size:
                  (selectObj.fontSize /
                    (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100,
                font_family: selectObj.fontFamily,
                color: selectObj.fill,
                background_color: selectObj.color,
                align: selectObj.align,
                bold: selectObj.fontStyle.includes("bold") ? true : false,
                italic: selectObj.fontStyle.includes("italic") ? true : false,
                underline:
                  selectObj.textDecoration === "underline" ? true : false,
                option: guest ? guest.type : "suffix_full_name",
              },
              qr_code_block: {
                display: qrVisible ? true : false,
                x: qrBlock
                  ? qrBlock.x
                  : qrObj.x > 0
                  ? (qrObj.x / (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
                y: qrBlock
                  ? qrBlock.y
                  : qrObj.y > 0
                  ? (qrObj.y / (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
                width:
                  (qrObj.width /
                    (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100,
                height:
                  (qrObj.height /
                    (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100,
                color: qrObj.color,
                background_color: qrObj.fill,
              },
              text_blocks:
                textBlock.length === 0 && textVisible === true
                  ? [
                      {
                        x:
                          textNodeRef.current && textNodeRef.current.attrs.x > 0
                            ? (textNodeRef.current.attrs.x /
                                (width > 1279
                                  ? 640
                                  : width > 767
                                  ? 408
                                  : 268)) *
                              100
                            : 0,
                        y:
                          textNodeRef.current && textNodeRef.current.attrs.y > 0
                            ? (textNodeRef.current.attrs.y /
                                (width > 1279
                                  ? 640
                                  : width > 767
                                  ? 408
                                  : 268)) *
                              100
                            : 0,
                        width:
                          (newTextObj.width /
                            (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                          100,
                        height:
                          (newTextObj.height /
                            (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                          100,
                        font_size:
                          (newTextObj.fontSize /
                            (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                          100,
                        font_family: newTextObj.fontFamily,
                        color: newTextObj.fill,
                        background_color: newTextObj.color,
                        align: newTextObj.align,
                        content: getContent(newTextObj),
                      },
                    ]
                  : textBlock,
            },
          }),
        ).then((result: any) => {
          if (result.error) {
            result.payload.name && notify(result.payload.name[0])
          } else {
            setSavePreview(true)
          }
        })
      : dispatch(
          sendQrDesign({
            name: qrDesignName,
            guests_amount_block: {
              display: countVisible ? true : false,
              x: countBlock ? countBlock.x :
                countObj.x > 0
                  ? (countObj.x /
                      (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
              y: countBlock ? countBlock.y :
                countObj.y > 0
                  ? (countObj.y /
                      (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
              width:
                (countObj.width /
                  (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                100,
              height:
                (countObj.height /
                  (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                100,
              font_size: countObj.fontSize,
              font_family: countObj.fontFamily,
              color: countObj.fill,
              background_color: countObj.color,
              align: countObj.align,
              bold: countObj.fontStyle.includes("bold") ? true : false,
              italic: countObj.fontStyle.includes("italic") ? true : false,
              underline: countObj.textDecoration === "underline" ? true : false,
            },
            name_block: {
              display: selectVisible ? true : false,
              x: selectBlock ? selectBlock.x :
                selectObj.x > 0
                  ? (selectObj.x /
                      (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
              y: selectBlock ? selectBlock.y :
                selectObj.y > 0
                  ? (selectObj.y /
                      (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                    100
                  : 0,
              width:
                (selectObj.width /
                  (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                100,
              height:
                (selectObj.height /
                  (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                100,
              font_size: selectObj.fontSize,
              font_family: selectObj.fontFamily,
              color: selectObj.fill,
              background_color: selectObj.color,
              align: selectObj.align,
              bold: selectObj.fontStyle.includes("bold") ? true : false,
              italic: selectObj.fontStyle.includes("italic") ? true : false,
              underline:
                selectObj.textDecoration === "underline" ? true : false,
              option: guest ? guest.type : "suffix_full_name",
            },
            qr_code_block: {
              display: qrVisible ? true : false,
              x: qrBlock
                ? qrBlock.x
                : qrObj.x > 0
                ? (qrObj.x / (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100
                : 0,
              y: qrBlock
                ? qrBlock.y
                : qrObj.y > 0
                ? (qrObj.y / (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                  100
                : 0,
              width:
                (qrObj.width / (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                100,
              height:
                (qrObj.height /
                  (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                100,
              color: qrObj.color,
              background_color: qrObj.fill,
            },
            text_blocks:
              textBlock.length === 0 && textVisible === true
                ? [
                    {
                      x:
                        textNodeRef.current && textNodeRef.current.attrs.x > 0
                          ? (textNodeRef.current.attrs.x /
                              (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                            100
                          : 0,
                      y:
                        textNodeRef.current && textNodeRef.current.attrs.y > 0
                          ? (textNodeRef.current.attrs.y /
                              (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                            100
                          : 0,
                      width:
                        (newTextObj.width /
                          (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                        100,
                      height:
                        (newTextObj.height /
                          (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                        100,
                      font_size:
                        (newTextObj.fontSize /
                          (width > 1279 ? 640 : width > 767 ? 408 : 268)) *
                        100,
                      font_family: newTextObj.fontFamily,
                      color: newTextObj.fill,
                      background_color: newTextObj.color,
                      align: newTextObj.align,
                      content: getContent(newTextObj),
                    },
                  ]
                : textBlock,
          }),
        ).then((result: any) => {
          if (result.error) {
            result.payload.name && notify(result.payload.name[0])
          } else {
            setSavePreview(true)
          }
        })
  }

  const onCompleted = async () => {
    try {
      const response: Response =
        croppedImagePath !== null
          ? await fetch(croppedImagePath)
          : await fetch("")
      const blob = await response.blob()
      const formData = new FormData()
      formData.append("image", blob, "filename.ext")

      dispatch(
        uploadQRImage({
          id:
            currentEvent && currentEvent.qr_code_design_id !== null
              ? currentEvent.qr_code_design_id
              : qr_design !== null
              ? qr_design
              : qr_id,
          params: formData,
        }),
      ).then(() => {
        dispatch(setLoading())
        notifySuccess("Design successfully created")
        onDelete()
        deleteImage()
        setImage(null)
        setCroppedImagePath(null)
        setQrDesignName("")
        setSavePreview(false)
        setTimeout(() => {
          navigate(`/event-edit/${event_id}`)
        }, 1500)
      })
    } catch (error) {
      console.error("Error while sending file:", error)
    }
  }

  const onTextShow = (e: any) => {
    if (newTextObj.textEditVisible) {
      setShowText(true)
      const absPos = e.target.getAbsolutePosition()
      setTextX({
        ...textX,
        x: absPos.x > 0 ? absPos.x : 0,
        y: absPos.y > 0 ? absPos.y : 0,
      })
      inputRef.current && inputRef.current.focus()
    }
  }

  const onTransform = () => {
    setNewTextObj({
      ...newTextObj,
      width: Math.round(
        textNodeRef.current.attrs.width * textNodeRef.current.attrs.scaleX,
      ),
      x:
        textNodeRef.current.attrs.x > 0
          ? Math.round(textNodeRef.current.attrs.x)
          : 0,
      y:
        textNodeRef.current.attrs.y > 0
          ? Math.round(textNodeRef.current.attrs.y)
          : 0,
      height: textNodeRef.current.height(),
    })
  }

  const onEdit = () => {
    setSavePreview(false)
    setEdit(true)
  }

  const toggleMenuIsOpen = (e: any) => {
    const absPos = e.target.getAbsolutePosition()
    setSelectX({
      ...selectX,
      x: absPos.x > 0 ? absPos.x : 0,
      y: absPos.y > 0 ? absPos.y : 0,
    })
    setMenuIsOpen(menuIsOpen === true ? false : true)
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "24px",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: `${width > 1279 ? "640px" : width > 767 ? "408px" : "268px"}`,
          width: "100%",
          maxWidth: `${
            width > 1279 ? "640px" : width > 767 ? "408px" : "268px"
          }`,
          margin: "0 auto",
          marginBottom: "24px",
          position: "relative",
          gap: window.innerWidth < 992 ? "24px" : "0",
        }}
      >
        {croppedImagePath !== null && croppedImagePath !== undefined && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "100%",
              maxHeight: `${
                width > 1279 ? "640px" : width > 767 ? "408px" : "268px"
              }`,
              height: `${
                width > 1279 ? "640px" : width > 767 ? "408px" : "268px"
              }`,
            }}
          >
            <img width="100%" src={croppedImagePath} ref={canvasRef} />
          </div>
        )}
        <Stage
          width={width > 1279 ? 640 : width > 767 ? 408 : 268}
          height={width > 1279 ? 640 : width > 767 ? 408 : 268}
          style={{
            position: "absolute",
            zIndex: 2,
            maxWidth: `${
              width > 1279 ? "640px" : width > 767 ? "408px" : "268px"
            }`,
            width: "100%",
            height: `${
              width > 1279 ? "640px" : width > 767 ? "408px" : "268px"
            }`,
          }}
        >
          {textVisible && (
            <Layer
              draggable={
                newTextObj.textEditVisible ? (showText ? false : true) : false
              }
              opacity={newTextObj.textEditVisible && show === false ? 0 : 1}
            >
              <URLImage
                opacity={newTextObj.textEditVisible ? (showText ? 0 : 1) : 0}
                src={imageClose}
                x={newTextObj.x}
                y={newTextObj.y - 27}
                funcClick={handleCloseTextArea}
                width={22}
                height={22}
              />
              <Text
                fontFamily={newTextObj.fontFamily}
                fontStyle={newTextObj.fontStyle}
                fontSize={newTextObj.fontSize}
                textDecoration={newTextObj.textDecoration}
                align={newTextObj.align}
                padding={5}
                lineHeight={1.2}
                ref={textNodeRef}
                y={newTextObj.y}
                x={newTextObj.x}
                fill={newTextObj.fill}
                text={newTextObj.textValue}
                opacity={showText ? 0 : 1}
                wrap="word"
                width={newTextObj.width}
                onClick={onTextShow}
                onTap={onTextShow}
                onTransform={onTransform}
                onDblTap={(e) =>
                  handleTextDblClick(e, newTextObj, setNewTextObj)
                }
                onDblClick={(e) =>
                  handleTextDblClick(e, newTextObj, setNewTextObj)
                }
                sceneFunc={(context, shape) => {
                  const radius = 4
                  context.fillStyle = newTextObj.color
                  context.beginPath()
                  context.moveTo(0 + radius, 0)
                  context.lineTo(0 + shape.width() - radius, 0)
                  context.arcTo(
                    0 + shape.width(),
                    0,
                    0 + shape.width(),
                    0 + radius,
                    radius,
                  )
                  context.lineTo(0 + shape.width(), 0 + shape.height() - radius)
                  context.arcTo(
                    0 + shape.width(),
                    0 + shape.height(),
                    0 + shape.width() - radius,
                    0 + shape.height(),
                    radius,
                  )
                  context.lineTo(0 + radius, 0 + shape.height())
                  context.arcTo(
                    0,
                    0 + shape.height(),
                    0,
                    0 + shape.height() - radius,
                    radius,
                  )
                  context.lineTo(0, 0 + radius)
                  context.arcTo(0, 0, 0 + radius, 0, radius)
                  context.closePath()
                  context.fill()
                  ;(shape as Konva.Text)._sceneFunc(context)
                }}
              />
              <URLImage
                opacity={newTextObj.textEditVisible ? (showText ? 0 : 1) : 0}
                src={imageEdit}
                x={newTextObj.x + newTextObj.width - 21}
                y={newTextObj.y + newTextObj.height + 5}
                width={22}
                height={22}
              />
              <Transformer
                opacity={showText ? 0 : 1}
                ref={trRef}
                borderStroke="#6D6D6E"
                anchorStroke="#6D6D6E"
                rotateEnabled={false}
                enabledAnchors={["middle-left", "middle-right"]}
                boundBoxFunc={(oldBox, newBox) => {
                  newBox.width = Math.max(30, newBox.width)
                  return newBox
                }}
              />
            </Layer>
          )}
          {qrVisible && (
            <Layer
              draggable={qrObj.textEditVisible ? true : false}
              opacity={qrObj.textEditVisible && show === false ? 0 : 1}
            >
              <URLImage
                opacity={qrObj.textEditVisible ? 1 : 0}
                src={imageClose}
                x={qrObj.x}
                y={qrObj.y - 28}
                funcClick={handleCloseQR}
                width={22}
                height={22}
              />
              <QRCodeImageButton
                opacity={1}
                src={imageSrc}
                x={qrObj.x}
                y={qrObj.y}
                width={width > 1279 ? 187 : width > 767 ? 125 : 72}
                height={width > 1279 ? 187 : width > 767 ? 125 : 72}
                funcDblClick={handleQRDblClick}
              />
              <URLImage
                opacity={qrObj.textEditVisible ? 1 : 0}
                src={imageEdit}
                x={qrObj.x + qrObj.width - 22}
                y={qrObj.y + qrObj.height + 5}
                width={22}
                height={22}
              />
            </Layer>
          )}

          {countVisible && (
            <Layer
              draggable={countObj.textEditVisible ? true : false}
              opacity={countObj.textEditVisible && show === false ? 0 : 1}
            >
              <URLImage
                opacity={countObj.textEditVisible ? 1 : 0}
                src={imageClose}
                x={countObj.x}
                y={countObj.y - 42}
                funcClick={handleKeyDown}
                width={22}
                height={22}
              />
              <Text
                fontFamily={countObj.fontFamily}
                fontStyle={countObj.fontStyle}
                fontSize={countObj.fontSize}
                textDecoration={countObj.textDecoration}
                align={countObj.align}
                y={countObj.y}
                x={countObj.x}
                fill={countObj.fill}
                text={countObj.textValue}
                wrap="word"
                width={countObj.width}
                height={countObj.height}
                onDblTap={(e) => handleTextDblClick(e, countObj, setCountObj)}
                onDblClick={(e) => handleTextDblClick(e, countObj, setCountObj)}
                sceneFunc={(context, shape) => {
                  const radius = 4
                  context.fillStyle = countObj.color
                  context.beginPath()
                  context.moveTo(0 + radius, -15)
                  context.lineTo(0 + shape.width() - radius, -15)
                  context.arcTo(
                    0 + shape.width(),
                    -15,
                    0 + shape.width(),
                    -15 + radius,
                    radius,
                  )
                  context.lineTo(
                    0 + shape.width(),
                    -15 + shape.height() - radius,
                  )
                  context.arcTo(
                    0 + shape.width(),
                    -15 + shape.height(),
                    0 + shape.width() - radius,
                    -15 + shape.height(),
                    radius,
                  )
                  context.lineTo(0 + radius, -15 + shape.height())
                  context.arcTo(
                    0,
                    -15 + shape.height(),
                    0,
                    -15 + shape.height() - radius,
                    radius,
                  )
                  context.lineTo(0, -15 + radius)
                  context.arcTo(0, -15, 0 + radius, -15, radius)
                  context.closePath()
                  context.fill()
                  ;(shape as Konva.Text)._sceneFunc(context)
                }}
              />
              <URLImage
                opacity={countObj.textEditVisible ? 1 : 0}
                src={imageEdit}
                x={countObj.x + countObj.width - 22}
                y={countObj.y + countObj.height - 10}
                width={22}
                height={22}
              />
            </Layer>
          )}
          {selectVisible && (
            <Layer
              draggable={
                !showSelect
                  ? selectObj.textEditVisible
                    ? !menuIsOpen
                      ? true
                      : false
                    : false
                  : false
              }
              opacity={selectObj.textEditVisible && show === false ? 0 : 1}
            >
              <URLImage
                opacity={selectObj.textEditVisible ? 1 : 0}
                src={imageClose}
                x={selectObj.x}
                y={selectObj.y - 28}
                funcClick={handleCloseSelect}
                width={22}
                height={22}
              />
              <Text
                fontFamily={selectObj.fontFamily}
                fontStyle={selectObj.fontStyle}
                fontSize={selectObj.fontSize}
                textDecoration={selectObj.textDecoration}
                opacity={selectObj.textEditVisible ? 1 : 1}
                align={selectObj.align}
                y={selectObj.y}
                x={selectObj.x}
                fill={selectObj.fill}
                text={selectObj.textValue}
                color={selectObj.color}
                wrap="word"
                width={selectObj.width - 20}
                onDblClick={(e) =>
                  handleTextDblClick(e, selectObj, setSelectObj)
                }
                onDblTap={(e) => handleTextDblClick(e, selectObj, setSelectObj)}
                padding={10}
                sceneFunc={(context, shape) => {
                  const radius = 4
                  context.fillStyle = selectObj.color
                  context.beginPath()
                  context.moveTo(0 + radius, 0)
                  context.arcTo(
                    0 + shape.width(),
                    0,
                    0 + shape.width(),
                    0 + shape.height(),
                    0,
                  )
                  context.arcTo(
                    0 + shape.width(),
                    0 + shape.height(),
                    0,
                    0 + shape.height(),
                    0,
                  )
                  context.arcTo(0, 0 + shape.height(), 0, 0, radius)
                  context.arcTo(0, 0, 0 + radius, 0, radius)
                  context.closePath()
                  context.fill()
                  ;(shape as Konva.Text)._sceneFunc(context)
                }}
              />
              <Text
                opacity={selectObj.textEditVisible ? 1 : 1}
                y={selectObj.y}
                x={selectObj.x + selectObj.width - 20}
                fill={selectObj.fill}
                wrap="word"
                width={20}
                height={selectObj.height}
                onDblClick={(e) =>
                  handleTextDblClick(e, selectObj, setSelectObj)
                }
                onDblTap={(e) => handleTextDblClick(e, selectObj, setSelectObj)}
                sceneFunc={(context, shape) => {
                  const radius = 4
                  context.fillStyle = selectObj.color
                  context.beginPath()
                  context.moveTo(0 + radius, 0)
                  context.arcTo(
                    0 + shape.width(),
                    0,
                    0 + shape.width(),
                    0 + width < 767 ? shape.height() - 6 : shape.height() - 6,
                    radius,
                  )
                  context.arcTo(
                    0 + shape.width(),
                    0 + width < 767 ? shape.height() - 6 : shape.height() - 6,
                    0,
                    0 + width < 767 ? shape.height() - 6 : shape.height() - 6,
                    radius,
                  )
                  context.lineTo(
                    0,
                    0 + width < 767 ? shape.height() - 6 : shape.height() - 6,
                  )
                  context.lineTo(0, 0)
                  context.closePath()
                  context.fill()
                  ;(shape as Konva.Text)._sceneFunc(context)
                }}
              />
              <URLImage
                opacity={selectObj.textEditVisible ? 1 : 0}
                src={imageArrow}
                x={selectObj.x + selectObj.width - 25}
                y={width > 768 ? selectObj.y + 12 : selectObj.y + 8}
                funcClick={toggleMenuIsOpen}
                width={16}
                height={16}
              />
              <URLImage
                opacity={selectObj.textEditVisible ? 1 : 0}
                src={imageEdit}
                x={selectObj.x + selectObj.width - 23}
                y={selectObj.y + selectObj.height - 1}
                width={22}
                height={22}
              />
            </Layer>
          )}
        </Stage>
        <div ref={qrContainerRef} style={{ display: "none" }}>
          <QRCodeSVG
            value="https://app2.mazoom.sa"
            size={width > 767 ? (width < 1280 ? 125 : 187) : 72}
            bgColor={qrObj.color}
            fgColor={qrObj.fill}
            level="L"
            includeMargin={true}
          />
        </div>
        {showText && (
          <textarea
            className="canvas__textaria"
            value={newTextObj.textValue}
            ref={inputRef}
            style={{
              display: newTextObj.textEditVisible ? "block" : "none",
              position: "relative",
              zIndex: 3,
              outline: "none",
              border: "0px",
              padding: "3px 5px 0px 5px",
              margin: "0px",
              top: textX.y + "px",
              left: textX.x + "px",
              fontSize: newTextObj.fontSize + "px",
              resize: "none",
              background: newTextObj.color,
              width: newTextObj.width,
              height: newTextObj.height,
              fontFamily: newTextObj.fontFamily,
              fontStyle:
                newTextObj.fontStyle.indexOf("italic") !== -1
                  ? "italic"
                  : "normal",
              fontWeight:
                newTextObj.fontStyle.indexOf("bold") !== -1 ? "bold" : "normal",
              textDecoration:
                newTextObj.textDecoration === "underline"
                  ? "underline"
                  : "none",
              color: newTextObj.fill,
              lineHeight:
                width > 767 ? (width < 1280 ? "29px" : "43px") : "22px",
              textUnderlineOffset: "8px",
              textDecorationThickness: "3px",
              textAlign:
                newTextObj.align &&
                (newTextObj.align === "left" ||
                  newTextObj.align === "center" ||
                  newTextObj.align === "right")
                  ? newTextObj.align
                  : "center",
              overflow: "hidden",
              // border: "1px solid #6D6D6E",
            }}
            onChange={(e) => handleTextEdit(e)}
            onBlur={() => setShowText(false)}
            onKeyDown={(e) => handleTextareaKeyDown(e)}
          />
        )}
        <div>
          {menuIsOpen && (
            <Select
              defaultValue={guests[0]}
              options={guests}
              value={guest}
              ref={ref}
              menuIsOpen={menuIsOpen}
              onChange={(e) => handleSelectKeyDown(e)}
              isSearchable={false}
              className="canvas__select"
              styles={{
                container: () => ({
                  display: selectObj.textEditVisible ? "block" : "none",
                  position: "relative",
                  opacity: selectObj.textEditVisible && show === false ? 0 : 1,
                  top:
                    Number(selectX.y) -
                    selectObj.height +
                    (width > 767 ? 28 : 20) +
                    40 +
                    "px",
                  left: Number(selectX.x) - selectObj.width + 25 + "px",
                  width: width > 767 ? "212px" : "157px",
                  zIndex: 3,
                  color: "black",
                }),
                singleValue: () => ({
                  color: "black",
                }),
                control: () => ({
                  width: width > 767 ? "212px" : "157px",
                  display: "none",
                  fontSize: selectObj.fontSize,
                  fontFamily: selectObj.fontFamily,
                  color: "black",
                  border: 0,
                  height: width > 767 ? "44px" : "36px",
                  borderRadius: "4px",
                  zIndex: 100,
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                dropdownIndicator: (base: any, state) => ({
                  ...base,
                  transform: state.selectProps.menuIsOpen
                    ? "rotate(180deg)"
                    : null,
                }),
                option: () => ({
                  display: "flex",
                  alignItems: "center",
                  padding: 10,
                  borderBottom: "1px solid #F2F4F7",
                  cursor: "pointer",
                  fontSize: width > 767 ? 14 : 12,
                  zIndex: 100,
                  width: width > 767 ? "212px" : "157px",
                }),
                menuList: () => ({
                  width: width > 767 ? "212px" : "157px",
                  maxWidth: width > 767 ? "212px" : "157px",
                  opacity: selectObj.textEditVisible && show === false ? 0 : 1,
                  padding: 0,
                  zIndex: 100,
                }),
              }}
            />
          )}
        </div>
      </div>
      {!savePreview ? (
        <div style={{ display: "flex", justifyContent: "center", gap: "16px" }}>
          <span
            className="btn btn-simple"
            style={{ padding: "0px 28px" }}
            onClick={() => {
              setShowModal(true)
            }}
          >
            Cancel
          </span>
          <button
            type="submit"
            className="btn btn-dark"
            style={{ padding: "0px 28px" }}
            onClick={openPreview}
          >
            Save
          </button>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", gap: "16px" }}>
          <span
            className="btn btn-simple"
            style={{ padding: "0px 28px" }}
            onClick={onEdit}
          >
            Edit Design
          </span>
          <button
            type="submit"
            className="btn btn-dark"
            style={{ padding: "0px 28px" }}
            onClick={onCompleted}
          >
            Complete
          </button>
        </div>
      )}
    </div>
  )
}

export default QrCodeCanvasInput
