import NewCreateEventPage from "./NewCreateEventPage"
import WhatsapNumber from "./WhatsapNumber"
import RecieveOtp from "./RecieveOtp"
import SuccessMessage from "./SuccessMessage"
import FailedMessage from "./FailedMessage"
import Subscription from "./Subscription"
import MyInvitations from "./MyInvitations"
// import EventDetail from "./MyInvitationsDetail"
import Welcome from "./WelcomeHome"
import Quiz from "./Quiz"
import QuizPass from "./QuizPass"

const NewCreateEvent = () => {
  return (
    <div className="b">
      <NewCreateEventPage />
      {/* <WhatsapNumber /> */}
      {/* <RecieveOtp /> */}

      {/* <SuccessMessage phoneNumber={"251946951726"} /> */}
      {/* <FailedMessage phoneNumber={"251946951726"} /> */}
      {/* <Subscription /> */}
      {/* <MyInvitations /> */}
      {/* <EventDetail /> */}
      {/* <Welcome /> */}
      {/* <Quiz /> */}
      {/* <QuizPass /> */}
    </div>
  )
}

export default NewCreateEvent
