import { Dropdown } from "react-bootstrap"
import {
  IconBtnCreate,
  IconImport,
  IconOptions,
} from "../utils/Icons/CustomIcons"

const ActionsPopup = ({ show, setShow, setImportShow }: any) => {
  return (
    <Dropdown className="dropdown-block dropdown--not-arrow dropdown-filter actions-popup">
      <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent event-page__btn">
        <IconOptions />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setShow(!show)}>
          <IconBtnCreate />
          <span className="dropdown-text">Create contact</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setImportShow(true)}>
          <IconImport />
          <span className="dropdown-text">Import contact</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ActionsPopup
