import { createSlice } from "@reduxjs/toolkit"

// Create a slice to manage locally stored files
const fileSlice = createSlice({
  name: "localFile",
  initialState: {
    file: null,
  },
  reducers: {
    storeFileLocally: (state, action) => {
      state.file = action.payload
      // Optional: Store it in localStorage if needed
      localStorage.setItem("localFile", JSON.stringify(action.payload))
    },
    deleteFileLocally: (state) => {
      state.file = null
      // Remove the file from localStorage as well if it exists
      localStorage.removeItem("localFile")
    },
  },
})

export const { storeFileLocally, deleteFileLocally } = fileSlice.actions
export default fileSlice.reducer
