import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import DownloadInvitationAppModal from "@/components/Event/DownloadInvitationAppModal/DownloadInvitationAppModal"
import styles from "./FrontPagesImages.module.scss"
import Carousel from "react-bootstrap/Carousel"
import FormatDate from "@/components/utils/FromateDate"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { useAppDispatch, useAppSelector } from "@/redux/store"

const EventImagesModal = ({
  setImages,
  show,
  handleClose,
  images,
  setShowUpgrade,
  showUpgrade,
  event,
  setFromPhotos,
}: any) => {
  const isShowOovScreen = useFeatureFlagEnabled("show_pov_screen")

  // event_creatd_date
  const { t } = useTranslation()

  const [showModalDownloadApp, setShowModalDownloadApp] =
    useState<boolean>(false)

  const { enablePovLoading, enablePovError } = useAppSelector(
    (state) => state.events,
  )
  const {
    isSubscriptionLoading,
    errorSubscription,
    isSubscriptionCreatingLoading,
    errorCreateSubscription,
    entitlements,
    currentPovPlan,
  } = useAppSelector((state) => state.payments)
  const downloadAppModalClose = () => {
    setShowModalDownloadApp(false)
  }
  const handleSelect = (selectedIndex: any) => {
    // If selectedIndex is the last one, go back to the first one
    if (selectedIndex < 0) {
      // If we're going backwards from the first image, wrap to the last
      setActiveIndex(images.length - 1)
    } else if (selectedIndex >= images.length) {
      // If we're going forwards from the last image, wrap to the first
      setActiveIndex(0)
    } else {
      // Normal case: just set the new index
      setActiveIndex(selectedIndex)
    }
  }

  const [activeIndex, setActiveIndex] = useState(0)

  const handleThumbnailClick = (index: any) => {
    setActiveIndex(index)
  }

  const onHide = () => {
    handleClose()
    setImages([])
  }
  const handleOpenUpgrade = () => {
    handleClose()
    setShowUpgrade(true)
    setFromPhotos(true)
  }

  return (
    <Modal
      className={`${styles.addPopup} add-guest__modal`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body className="p-3">
        <div className={styles.body}>
          <h2
            className={`caption ${styles.caption}`}
            style={{ marginBottom: "10px" }}
          >
            {t("event.photos.eventPhotos")}
            {/* Event Photos */}
          </h2>

          <div className="d-flex justify-content-between align-items-center">
            <div>
              {!isSubscriptionLoading && (
                <>
                  <h2 style={{ marginBottom: "0px", fontSize: "25px" }}>
                    {/* {t("event.photos.basicPack")} */}
                    {currentPovPlan === "basic_pov_plan"
                      ? t("event.photos.basicPack")
                      : currentPovPlan === "standard_pov_plan"
                      ? t("event.photos.standardPack")
                      : currentPovPlan === "unlimited_pov_plan"
                      ? t("event.photos.unlimitedPack")
                      : t("event.photos.basicPack")}
                  </h2>
                  <h6 className={styles.text}>
                    {/* {t("event.photos.guestsCanUploadUpTo10Photos")} */}
                    {currentPovPlan === "basic_pov_plan"
                      ? t("event.photos.guestsCanUploadUpTo10Photos")
                      : currentPovPlan === "standard_pov_plan"
                      ? t("event.photos.guestsCanUploadUpTo100Photos")
                      : currentPovPlan === "unlimited_pov_plan"
                      ? t(
                          "event.photos.guestsCanUploadAnUnlimitedNumberOfPhotos",
                        )
                      : t("event.photos.guestsCanUploadUpTo10Photos")}
                  </h6>
                </>
              )}
            </div>

            {/* {isShowOovScreen && ( */}
            <Link
              className="fs-6"
              to="#"
              // onClick={() => setShowUpgrade(true)}
              onClick={handleOpenUpgrade}
            >
              {t("event.photos.upgrade")}
              {/* Upgrade */}
            </Link>
          </div>
        </div>
        {images && images?.length > 0 ? (
          <>
            <div className="main-carousel">
              <div
                style={{
                  position: "relative",
                  maxWidth: "100%",
                  margin: "0 auto",
                }}
              >
                <Carousel
                  activeIndex={activeIndex}
                  onSelect={handleSelect}
                  indicators={false}
                  controls={false}
                  interval={null}
                  className={styles.carousalImage}
                >
                  {images?.map((photo: any, index: any) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100 m img-fluid"
                        src={photo?.image_url}
                        alt={`Slide ${index}`}
                        style={{
                          maxHeight: "500px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    </Carousel.Item>
                  ))}
                  <div
                    className="position-absolute bottom-0 mb-2 start-50 translate-middle-x text-center text-light bg-dark bg-opacity-50 py-2 px-3"
                    style={{ fontSize: "15px", borderRadius: "5px" }}
                  >
                    {activeIndex + 1}/{images?.length}
                  </div>
                </Carousel>

                {/* Custom prev control */}
                <div
                  onClick={() => handleSelect(activeIndex - 1)}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    width: "41px",

                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    // background:
                    //   "linear-gradient(to right, rgba(0,0,0,0.5), transparent)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                    padding: "0 10px",
                  }}
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                </div>

                {/* Custom next control */}
                <div
                  onClick={() => handleSelect(activeIndex + 1)}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    width: "41px",

                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    padding: "0 10px",
                  }}
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between ">
              <div className="flex-column ">
                <h2 style={{ fontSize: "17px", fontWeight: "bold" }}>
                  {/* {t("event.photos.SharedBy")} */}
                  {t("event.photos.sharedBy")}
                  {images?.uploaded_by}
                </h2>
                <h2 style={{ marginBottom: "0px", fontSize: "15px" }}>
                  {/* {t("event.photos.descriptionOfThePhoto")} */}
                  {t("event.photos.descriptionOfThePhoto")}
                </h2>
              </div>
              <h2 style={{ marginBottom: "0px", fontSize: "14px" }}>
                {FormatDate(event?.event_created_date)}
                {/* {t("event.photos.eventPhotos")} */}
              </h2>
            </div>

            {/* Thumbnail Row */}
            <div
              // className="thumbnail-row d-flex justify-content-center mt-2"
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                gap: "5px",
                overflowX: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "rgba(0,0,0,.2) transparent",
              }}
            >
              {images?.map((photo: any, index: any) => (
                <div
                  key={index}
                  className={`thumbnail-item ${
                    activeIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                  style={{
                    cursor: "pointer",
                    padding: "2px",
                  }}
                >
                  <img
                    className="d-block"
                    src={photo?.image_url}
                    alt={`Thumbnail ${index}`}
                    style={{
                      width: "100px",
                      // height: "auto",
                      maxHeight: "100px",
                      objectFit: "cover",
                      borderRadius: "8px", // Adjust the value as needed
                      // border: activeIndex === index ? "2px solid g" : "none", // Highlight active thumbnail
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <img src="/images/noImage.png" alt="no image" />
          </div>
        )}
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-dark mt-4 mb-4 ps-5 pe-5 d-flex align-items-center"
            onClick={() => {
              setShowModalDownloadApp(true)
            }}
          >
            {t("event.buttons.downloadApp")}
            {/* Download App */}
          </button>
        </div>

        <DownloadInvitationAppModal
          show={showModalDownloadApp}
          handleClose={downloadAppModalClose}
        />
      </Modal.Body>
    </Modal>
  )
}

export default EventImagesModal
