import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./redux/store"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./18n"
import "~/bootstrap/dist/css/bootstrap.css"
import "@/assets/scss/index.scss"
import Preloader from "./components/utils/Preloader/Preloader"
import { GoogleOAuthProvider } from "@react-oauth/google"
import posthog from "posthog-js"

posthog.init("phc_FYZhUJR6DHib55TiqeHDOAp31GKwFhyVJ0A4mXyTPKK", {
  api_host: "https://app.posthog.com", // or your self-hosted PostHog URL
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <GoogleOAuthProvider
    clientId={import.meta.env.VITE_REACT_APP_GOOGLE_CLIENT_ID}
  >
    <BrowserRouter>
      <Suspense fallback={<Preloader />}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    </BrowserRouter>
  </GoogleOAuthProvider>,
)
