import { Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IconCopyDoc } from "@/components/utils/Icons/CustomIcons"
import { useAppSelector } from "@/redux/store"
import styles from "./RegisterLinkModal.module.scss"

const RegisterLinkModal = ({
  show,
  setShow,
}: {
  show: boolean
  setShow: any
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { currentEvent } = useAppSelector((state) => state.events)

  return (
    <Modal
      className={`${styles.addPopup} ${"register-modal"}`}
      show={show}
      onHide={() => {
        setShow(!show)
        navigate("/events")
      }}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>{t("openModal.link")}</h2>

          <p className={styles.linkDescription}>{t("openModal.copy")}</p>
          <div className={styles.linkContainer}>
            <a
              target="_blank"
              href={`/register/${currentEvent && currentEvent.open_event_hash}`}
              className={styles.link}
            >
              app.mazoom.sa/register/{currentEvent && currentEvent.open_event_hash}
            </a>
            <div
              className={styles.linkImage}
              onClick={() =>
                navigator.clipboard.writeText(
                  `app.mazoom.sa/register/${currentEvent && currentEvent.open_event_hash}`,
                )
              }
            >
              <IconCopyDoc />
            </div>
          </div>

          <div className={styles.controls}>
            <span
              className="btn btn-simple"
              onClick={() => navigate("/events")}
            >
              {"Go to events"}
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RegisterLinkModal
