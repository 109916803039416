import { useState } from "react"
import { Link } from "react-router-dom"
import {
  IconAlertCircle,
  IconArrowLeft,
} from "../../components/utils/Icons/CustomIcons"
import { Database } from "lucide-react"
import { Card, Button, Row, Col } from "react-bootstrap"
import Quiz from "./Quiz"
import { useTranslation } from "react-i18next"
import Skeleton from "@/components/common/skeleton"
import quizImage from "@/assets/images/quiz.svg" // Adjust the path as necessary

const WelcomeQuiz = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState(1)
  const handleBack = () => {
    window.location.reload()
    // window.history.back()
  }

  const Welcome = () => {
    const [isLoadingHome, setIsLoadingHome] = useState(true)
    const handleImageLoad = () => {
      setIsLoadingHome(false)
    }
    return (
      <div className="container mt-1">
        {/* <div className="mb-3 ">
          <Link
            className="d-flex align-items-center reminders-page__link-container"
            to="/"
            onClick={handleBack}
          >
            <IconArrowLeft />
            <span className="reminders-page__link-text">Back</span>
          </Link>
        </div> */}
        <div className="d-flex flex-column align-items-center justify-content-center gap-3 mt-3">
          <div className="d-flex flex-column  ">
            {isLoadingHome && (
              <Skeleton width="180px" height="190px" borderRadius="8px" />
            )}
            <img
              src={quizImage}
              width="180px"
              height="auto"
              style={{ display: isLoadingHome ? "none" : "block" }}
              onLoad={handleImageLoad}
            />
          </div>{" "}
          <h3 className="mb-1 fs-4   text-black ">
            {t("event.welcomeToMazoomApp")}
          </h3>
          <h6 className="fw-light me-3 ms-3 text-muted">
            {t("event.weHaveAFewQuestionsToLearnAboutYouAndYourGoals")}{" "}
          </h6>
          <Link
            to="#"
            onClick={() => {
              setStep(2)
            }}
          >
            <Button
              className=" bg-dark text-dark text-white border-0"
              style={{ width: "200px" }}
            >
              {t("event.startNow")}{" "}
            </Button>
          </Link>
        </div>
      </div>
    )
  }
  return (
    <div>
      {step == 1 && <Welcome />}
      {step == 2 && <Quiz setStep={setStep} />}
    </div>
  )
}

export default WelcomeQuiz
