import { useEffect, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import { FileUploader } from "react-drag-drop-files"
import {
  IconCompletedFile,
  IconLoadFile,
  IconisLoadingFile,
} from "@/components/utils/Icons/CustomIcons"
import { useActions } from "@/hooks/useActions"
import { useTypeSelector } from "@/hooks/useTypeSelector"
import QrDesignInput from "../UI/input/QrDesignInput"
import UploadIcon from "@/assets/icons/Upload"
import { useAppSelector } from "@/redux/store"

interface iFile {
  name: string
  size: number
  type: string
}

const QrDesignFileLoader = ({
  croppedImagePath,
  setQrDesignName,
  qrDesignName,
  setFile,
  savePreview,
}: {
  croppedImagePath: any
  setQrDesignName: any
  qrDesignName: string
  setFile: any
  savePreview: any
}) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { qr_image, qr_design } = useAppSelector((state) => state.events)
  const { image } = useTypeSelector((state) => state.qr)
  const {
    setImage,
    toggleCropModalShow,
    setCroppedImagePath,
    setCreatedImage,
  } = useActions()
  let timer: any = null

  const handleError = (size: number) => {
    setError(true)
  }

  useEffect(() => {
    if (qr_design !== null && qr_image !== null) {
      setCreatedImage({
        path: qr_design.image_url,
        image: qr_image,
        name: qr_design.name,
      })
    }
    if (qr_design !== null) {
      setCroppedImagePath(qr_design.image_url)
    }
    if (qr_design !== null && qr_image !== null) {
      setFile(qr_image)
    }
  }, [qr_design, qr_image])

  const handleChange = (file: iFile) => {
    setProgress(0)
    let { name, size, type } = file
    size = Number((size / 1024).toFixed(0))
    setLoading(true)
    timer =
      !timer &&
      setInterval(() => {
        setProgress((prevProgress) => prevProgress + 10)
      }, Number((size / 10).toFixed(0)))

    setTimeout(() => {
      clearInterval(timer)
      setProgress(100)
      setLoading(false)
    }, size)

    setFile(file)
    setImage(file)
    setError(false)
  }

  useEffect(() => {
    if (progress == 100) {
      toggleCropModalShow()
    }
  }, [progress])

  const onChange = (e: any) => {
    setQrDesignName(e.target.value)
  }

  return (
    <div>
      <QrDesignInput
        placeholder="Name of QR design"
        type="text"
        value={qrDesignName}
        disabled={!savePreview ? false : true}
        onChange={(e) => {
          onChange(e)
        }}
      />
      {image === null && qr_image === null && (
        <div className="loadFile mt-3">
          <FileUploader
            name="file"
            maxSize={5}
            types={["JPEG", "JPG", "PNG"]}
            multiple={false}
            required={true}
            handleChange={handleChange}
            onTypeError={handleError}
            onSizeError={handleError}
          >
            <input type="file" ref={inputRef} accept=".jpeg,.jpg,.png" />
            <div className="loadFile-text">
              <IconLoadFile />
              <span className="title">
                Drag and Drop or choose file <br></br>to upload QR design image
              </span>
              <span className="desc">
                Allowed image formats are JPG, PNG, JPEG. Up to 5 Mb
              </span>
              <span className="text-required">Need to upload a file</span>
              {error && (
                <span className="error">
                  The format and size of the uploaded file exceeds the
                  permissible value
                </span>
              )}
            </div>
          </FileUploader>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="secondary"
              onClick={() => {
                inputRef.current && inputRef.current.click()
              }}
              style={{ padding: "0px 32px" }}
              className="mt-3 btn-dark"
            >
              Upload File
            </Button>
          </div>
        </div>
      )}
      {image && croppedImagePath === null && qr_image === null && (
        <div className="loadFileComplated">
          {loading ? (
            <IconisLoadingFile />
          ) : (
            <UploadIcon className="icon-upload" />
          )}
          <div className="loadFileComplated__left">
            <span className="name">{image?.name}</span>
            <span className="size">
              {image && (image?.size / 1024).toFixed(0)}KB
            </span>
            <span className="file-status">
              {loading && progress < 100
                ? `${
                    image &&
                    Math.floor(
                      ((image.size / 1024 / 1024) * 100 * (100 - progress)) /
                        100,
                    )
                  } seconds left`
                : "Done"}
            </span>
          </div>
          <div className="loadFileComplated__right">
            <span className="percent">{`${progress}%`}</span>
            {!loading && <IconCompletedFile />}
          </div>
          {
            <div className="loadFileProgress">
              <div
                className={
                  progress < 100 ? "progress" : "progress progress--load"
                }
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          }
        </div>
        // <QrDesignLoadBar isLoading={loading} setShowLoading={setShowLoading} />
      )}
    </div>
  )
}

export default QrDesignFileLoader
