import { useTranslation } from "react-i18next"
import {
  IconArrowDownTriangle,
  IconBattery,
  IconChevronUp,
  IconClose,
  IconConfirm,
  IconFooterIcons,
  IconLock,
  IconRefresh,
  IconSignal,
  IconTextSize,
  IconTime,
  IconTitle,
  IconWifi,
} from "../../utils/Icons/CustomIcons"
import "./AcceptPhone.scss"

const AcceptPhone = () => {
  const { t } = useTranslation()

  return (
    <div className="accept-phone">
      <div className="phone">
        <div className="phone-inner">
          <section className="phone-header">
            <IconTime />
            <div className="phone-header__icons">
              <IconSignal />
              <IconWifi />
              <IconBattery />
            </div>
          </section>

          <section className="phone-title">
            <IconTitle />
            <div className="phone-title__lang">
              <span>{t("header.language2")}</span>
              <IconArrowDownTriangle />
            </div>
          </section>

          <section className="phone-main">
            <div className="phone-main__form">
              <div className="phone-main__header">
                <IconConfirm />
                <span className="phone-main__header-title">
                  Accept invitation
                </span>
                <IconClose className="phone-main__header-close" />
              </div>
              <div className="phone-main__card">
                <p className="phone-main__card-description">
                  Please provide the following information:
                </p>
                <p className="phone-main__card-input">Alfred</p>
                <p className="phone-main__card-label">Name*</p>
                <p className="phone-main__card-input">962021870187</p>
                <p className="phone-main__card-label">Phone number*</p>
                <p className="phone-main__card-input">alfreddavis@gmail.com</p>
                <p className="phone-main__card-label">Email</p>
                <p className="phone-main__card-input">100</p>
                <p className="phone-main__card-label">Number of guests</p>
                <p className="phone-main__card-description">
                  Please answer the questions below:
                </p>
                <p className="phone-main__card-question">
                  1. How many people will be with you at the event?
                </p>
                <p className="phone-main__card-label question-label">
                  Your answer
                </p>
                <div className="phone-main__card-textarea">100|</div>
                <p className="phone-main__card-question">
                  2. How many people will be with you at the event?
                </p>
                <p className="phone-main__card-label question-label">
                  Your answer (1 or many options)
                </p>
                <div className="phone-main__card-cards">
                  <div className="phone-main__card-card">100</div>
                  <div className="phone-main__card-card">200</div>
                  <div className="phone-main__card-card">300</div>
                  <div className="phone-main__card-card">400</div>
                  <div className="phone-main__card-card">500</div>
                  <div className="phone-main__card-card">600</div>
                  <div className="phone-main__card-card">700</div>
                  <div className="phone-main__card-card">800</div>
                  <div className="phone-main__card-card">900</div>
                  <div className="phone-main__card-card">1000</div>
                </div>
                <p className="phone-main__card-question">
                  3. How many people will be with you at the event?
                </p>
                <p className="phone-main__card-label question-label">
                  Select answer option
                </p>
                <div className="phone-main__card-select">
                  <p className="phone-main__card-input">1</p>
                  <div className="phone-main__card-chevron">
                    <IconChevronUp />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-dark">Send</button>
              </div>
            </div>
          </section>

          <section className="phone-footer">
            <div className="phone-footer__http">
              <IconTextSize />
              <IconLock />
              <span className="phone-footer__http-text">
                app.mazoom.sa/register/819
              </span>
              <IconRefresh />
            </div>
            <IconFooterIcons />
            <div className="phone-footer__http-bottom">
              <div className="phone-footer__http-line" />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default AcceptPhone
