import { Modal } from "react-bootstrap"
import { useAppDispatch } from "../../../redux/store"
import { sendInvitationAll } from "../../../redux/slices/events/eventsSlice"
import { useParams } from "react-router-dom"
import styles from "./SendModal.module.scss"

const SendModal = ({
  show,
  setShow,
  title,
  type,
  notify
}: any) => {
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const onSubmit = () => {
    dispatch(sendInvitationAll(Number(event_id)))
    setShow(!show)
    notify("Invitations sent", false)
  }

  return (
    <Modal
      className={`${styles.addPopup} ${type === 'delete' ? 'event-page__delete-modal' : 'event-page__invite-modal'}`}
      show={show}
      onHide={() => setShow(!show)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            {title}
          </h2>

          <div className={styles.controls}>
            <span className="btn btn-simple" onClick={onSubmit}>
              {"Yes"}
            </span>
            <button onClick={() => setShow(!show)} className="btn btn-dark">
            {"No"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SendModal