import { Modal } from "react-bootstrap"
import { useAppDispatch } from "../../../redux/store"
import { useNavigate, useParams } from "react-router-dom"
import { deleteEvent } from "../../../redux/slices/events/eventsSlice"
import styles from "./DeleteEventModal.module.scss"

const DeleteEventModal = ({ show, setShow, title, type, notify}: any) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { event_id } = useParams()

  const onDeleteEvent = () => {
    dispatch(deleteEvent(Number(event_id))).then((result: any) => {
      if (result.error) {
        console.log(result)
      } else {
        navigate("/events")
        setShow(!show)
        notify('The event has been deleted', false)
      }
    })
  }

  return (
    <Modal
      className={`${styles.addPopup} ${
        type === "delete"
          ? "event-page__delete-modal"
          : "event-page__invite-modal"
      }`}
      show={show}
      onHide={() => setShow(!show)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>{title}</h2>

          <div className={styles.controls}>
            <span className="btn btn-simple" onClick={onDeleteEvent}>
              {"Yes"}
            </span>
            <button onClick={() => setShow(!show)} className="btn btn-dark">
              {"No"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteEventModal
