import { useEffect, useState } from "react"

import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Skeleton from "@/components/common/skeleton"
import success from "@/assets/images/success.svg" // Adjust the path as necessary

type SuccessMessageProps = {
  phoneNumber: string
  setStep: (step: number) => void
}

const SuccessMessage = ({ phoneNumber, setStep }: SuccessMessageProps) => {
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const handleImageLoad = () => {
    setIsLoadingImage(false)
  }
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleRedirect = () => {
    // Navigate to the first URL
    navigate("/", { replace: true })
    // Then navigate to the second URL
    setTimeout(() => {
      navigate("/subscription")
    }, 50) // Ensure it happens immediately after
  }
  return (
    <div
      className=" d-flex flex-column flex-md-row align-items-center justify-content-center  ms-5 me-5 mb-5 mt-5"
      style={{ minHeight: "60vh", margin: "0 5rem", gap: "5em" }} // Ensure full height and margin
    >
      <div className="d-flex flex-column gap-3 gap-md-3 ">
        <h2 className=""> {t("event.congratulations")}!</h2>
        <h4 className="text-muted">
          {t("event.weveSentAnInvitationTo")} {phoneNumber}
        </h4>
        <div className="d-flex flex-row gap-4">
          {/* <Link className="mt-0 w-100 bg-dark border-0" to="/">
            Go To Dashboard
          </Link> */}
          <Button
            style={{ borderRadius: "5px", padding: "10px" }}
            className="d-none d-sm-block border-0 mt-0 w-100 bg-dark text-white border- d-flex justify-content-center align-items-center reminders-page__link-container"
            // to="/subscription"
            // to="#"
            onClick={handleRedirect}

            // onClick={() => setStep(7)}
          >
            {t("event.goToDashboard")}
          </Button>
          {/* <Link
            style={{
              borderRadius: "5px",
              padding: "10px",
              border: "1px solid #e2e1e1",
            }}
            className=" mt-0 w-100 bg-light text-black border- d-flex justify-content-center align-items-center reminders-page__link-container"
            to="/"
            onClick={() => setStep(1)}
          >
            {t("event.sentMoreInvites")}{" "}
          </Link> */}

          {/* <Button
            className=" w-100 bg-light  text-dark border-none"
            style={{ border: "1px solid #e8e8e8" }}
          >
            Sent More Invites
          </Button> */}
        </div>
      </div>
      <div className="d-flex flex-column  ">
        {isLoadingImage && (
          <Skeleton width="250px" height="250px" borderRadius="8px" />
        )}
        <img
          src={success}
          width="100%"
          height="250px"
          style={{ display: isLoadingImage ? "none" : "block" }}
          onLoad={handleImageLoad}
        />
      </div>{" "}
      <Button
        style={{ borderRadius: "5px", padding: "10px" }}
        className="d-block d-sm-none border-0 mt-0 w-100 bg-dark text-white border- d-flex justify-content-center align-items-center reminders-page__link-container"
        // to="/subscription"
        // to="#"
        onClick={handleRedirect}

        // onClick={() => setStep(7)}
      >
        {t("event.goToDashboard")}
      </Button>
    </div>
  )
}
export default SuccessMessage
