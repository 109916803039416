import { Dropdown } from "react-bootstrap"
import {
  IconArrowDownFat,
  IconBook,
  IconBtnCreate,
  IconImport,
} from "../utils/Icons/CustomIcons"
import {t} from "i18next";

const GuestsPopup = ({
  showAddGuest,
  setShowAddGuest,
  showImport,
  setShowImport,
  id
}: any) => {
  return (
    <Dropdown className="d-inline add-guest__dropdown" align={"end"}>
      <Dropdown.Toggle id="dropdown-autoclose-true" className="btn btn-dark">
        <span className="dropdown-text">{t("event.addGuestsDropdown.title")}</span>
        <IconArrowDownFat />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setShowAddGuest(!showAddGuest)}>
          <IconBtnCreate />
          <span className="dropdown-text">{t("event.addGuestsDropdown.addNewGuest")}</span>
        </Dropdown.Item> 
        <Dropdown.Item href={`/${id}/contacts`}>
          <IconBook />
          <span className="dropdown-text">{t("event.addGuestsDropdown.selectFromContactsList")}</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowImport(!showImport)}>
          <IconImport />
          <span className="ms-2 me-2">{t("event.addGuestsDropdown.importGuests")}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default GuestsPopup
