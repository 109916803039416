import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  getContacts,
  deleteContacts,
  setLoading,
  setCurrentPage,
} from "../../redux/slices/contacts/contactsSlices"
import ContactsHead from "../../components/Contacts/ContactsHead"
import ContactsBody from "../../components/Contacts/ContactsBody"
import Preloader from "../../components/utils/Preloader/Preloader"
import { IFilter } from "../../components/Contacts/types"

const ContactsPage = () => {
  const dispatch = useAppDispatch()
  const { data, isLoading, current_page } = useAppSelector((state) => state.contacts)
  const [addShow, setAddShow] = useState(false)
  const [importShow, setImportShow] = useState(false)
  const [filter, setFilter] = useState<IFilter>({
    sort: "",
    order: "",
    searchValue: "",
  })

  const notify = (text: string) => {
    toast.success(text, {
      position: "top-right",
      autoClose: 3000,
    })
  }

  const deleteQuest = (id: any) => {
    dispatch(deleteContacts([id])).then((item) => {
      dispatch(getContacts(filter)).then((res) => {
        notify('Contact successfully deleted')
      })
    })
  }

  const deleteChecked = (id: any) => {
    dispatch(deleteContacts(id)).then((item) => {
      dispatch(getContacts(filter)).then((res) => {
        notify('Contacts are successfully deleted')
      })
    })
  }

  useEffect(() => {
    dispatch(setLoading())
  }, [])

  useEffect(() => {
    dispatch(setCurrentPage(current_page))
    dispatch(getContacts(filter))
  }, [filter.sort, filter.searchValue, filter.order, current_page])

  return (
    <>
      {!isLoading ? (
        <div className="contacts-page">
          <div className="container">
            {data && data.length > 0 && (
              <ContactsHead
                filter={filter}
                setFilter={setFilter}
                setAddShow={setAddShow}
                setImportShow={setImportShow}
              />
            )}

            <ContactsBody
              guests={data}
              filter={filter}
              setFilter={setFilter}
              deleteQuest={deleteQuest}
              addShow={addShow}
              setAddShow={setAddShow}
              importShow={importShow}
              setImportShow={setImportShow}
              deleteChecked={deleteChecked}
            />
          </div>
          <ToastContainer
            style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
          />
        </div>
      ) : (
        <Preloader />
      )}
    </>
  )
}

export default ContactsPage
