import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import styles from "./FrontPagesImages.module.scss"
import Spinner from "react-bootstrap/Spinner"
import Alert from "react-bootstrap/Alert"
import FormatDate from "@/components/utils/FromateDate"
import {
  subscribePov,
  getPovSubscription,
} from "@/redux/slices/payments/paymentsSlices"
import { createStripeCheckoutSession } from "@/redux/slices/payments/stripeSlices"
import PaymentStripe from "./PaymentStripe"
import api from "@/api/api"
import {
  Check,
  IconArrowLeft,
  Dollar,
} from "@/components/utils/Icons/CustomIcons"
import { loadStripe } from "@stripe/stripe-js"
import { Stripe } from "@stripe/stripe-js"

const UpgradeImagePack = ({
  setGuest,
  show,
  handleClose,
  setValidation,
  images,
  showUpgrade,
  setShowUpgrade,
  handleCloseUpgrade,
  setShow,
  fromPhotos,
}: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const [selectedCheckoutPackage, setSelectedCheckoutPackage] = useState<
    string | null
  >("basic_pov_plan")
  const { email } = useAppSelector((state) => state.auth)
  const {
    isSubscriptionLoading,
    errorSubscription,
    isSubscriptionCreatingLoading,
    errorCreateSubscription,
    entitlements,
    currentPovPlan,
    currentPovPlanDetails,
  } = useAppSelector((state) => state.payments)
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false)

  // console.log("is_premium", is_premium)
  const [selectedPackage, setSelectedPackage] = useState<string | null>(
    currentPovPlan && currentPovPlan != "basic_pov_plan"
      ? currentPovPlan
      : "basic_pov_plan",
  )

  useEffect(() => {
    setSelectedPackage(currentPovPlan ? currentPovPlan : "basic_pov_plan")
  }, [dispatch, isSubscriptionLoading])

  ///iadd showUpgrade
  useEffect(() => {
    if (email) dispatch(getPovSubscription(email))
  }, [show, showUpgrade])

  //ADD showUpgrade ad dependancy
  useEffect(() => {
    if (
      currentPovPlan &&
      currentPovPlan != "basic_pov_plan" &&
      !isSubscriptionLoading
    )
      setSelectedPackage(currentPovPlan)
  }, [isSubscriptionLoading, showUpgrade])
  // Function to check if a package is selected
  const handleSelectPackage = (packageName: any) => {
    // if (!isSubscriptionLoading && currentPovPlan == "basic_pov_plan") {
    //   setSelectedPackage(packageName)
    // }
    setSelectedPackage(packageName)
  }

  const onHide = () => {
    handleCloseUpgrade()
  }
  const handleBackToEvent = () => {
    setSelectedCheckoutPackage(selectedPackage)
    handleCloseUpgrade()
    setShow(true)
  }

  // const handlePayPack = () => {
  //   setSelectedCheckoutPackage(selectedPackage)
  //   setShowUpgrade(false)
  //   setShowConfirmDialog(true)
  // }
  const handleCheckout = async (): Promise<void> => {
    try {
      setIsLoadingCheckout(true)

      if (
        selectedPackage &&
        selectedPackage !== "" &&
        selectedPackage !== "basic_pov_plan"
      ) {
        const response = await api.payments.createStripeCheckoutSession({
          priceId:
            selectedPackage === "standard_pov_plan" ? "standard" : "unlimited",
        })

        if (response?.status) {
          setIsLoadingCheckout(false)
          const url = response?.data?.checkout_url

          if (url) {
            window.location.href = url
          } else {
            toast.error("Something went wrong: Invalid URL")
          }
        } else {
          toast.error("Something went wrong: Response status not OK")
        }
      }
    } catch (error: any) {
      setIsLoadingCheckout(false) // Stop loading spinner

      // Check for various error types
      if (error.response) {
        // Server responded with a status code outside the 2xx rang
        toast.error(
          `Error: ${error.response.status} - ${
            error.response.data?.message || "Server error"
          }`,
        )
      } else if (error.request) {
        // Request was made but no response was received
        toast.error(
          "Network Error: No response from the server. Please try again.",
        )
      } else {
        // Something happened in setting up the request
        toast.error(`Error: ${error.message}`)
      }
    }
  }

  return (
    <>
      <Modal
        className={`${styles.addPopup} add-guest__modal`}
        show={showUpgrade}
        onHide={onHide}
      >
        <Modal.Header className={styles.close} closeButton></Modal.Header>
        <Modal.Body className="p-3">
          {isSubscriptionLoading && (
            <div
              style={{
                minHeight: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          )}

          {!isSubscriptionLoading && currentPovPlan && (
            <div className={styles.body}>
              {fromPhotos && (
                <button
                  className=" btn btn-link d-flex align-items-center event-page__link-container"
                  // to={"/events"}
                  onClick={handleBackToEvent}
                >
                  <IconArrowLeft />
                  <span className="event-page__link-text">
                    {t("event.photos.backToEventsPhotos")}
                    {/* back to events photos */}
                  </span>
                </button>
              )}

              <h2
                className={`caption ${styles.caption}`}
                style={{ marginBottom: "10px" }}
              >
                {t("event.photos.imagePacks")}
                {/* Images Packs */}
              </h2>
              <h2
                // className={`caption ${styles.caption}`}
                className={styles.text}
                style={{ marginBottom: "10px" }}
              >
                {t("event.photos.youCanChooseOneOfThreePacks")}
                {/* You can choose one of three packs */}
              </h2>
              <div className=" ">
                <div>
                  {" "}
                  <>
                    {isSubscriptionLoading && (
                      <>
                        <Spinner className="spinner-border spinner-border-sm d-md-none" />
                        <Spinner className="spinner-border d-none d-md-block" />
                      </>
                    )}
                  </>
                </div>
                {!isSubscriptionLoading && (
                  <>
                    <div className="row">
                      {/* Basic Package */}
                      <div className="col-xs-12 col-md-6 mt-2 mb-2">
                        <div
                          className="card pt-4 text-center"
                          onClick={() => handleSelectPackage("basic_pov_plan")}
                          style={{
                            cursor: "pointer",
                            border:
                              selectedPackage === "basic_pov_plan"
                                ? "2px solid black"
                                : "1px solid #ddd",
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center p-2">
                            {currentPovPlan == "basic_pov_plan" ? (
                              <Check />
                            ) : (
                              <Dollar />
                            )}
                            <h5 className="d-flex align-items-center mb-3">
                              {t("event.photos.basic")}
                              {/* Basic */}
                            </h5>
                            <p
                              className={styles.text}
                              style={{ fontSize: "16px" }}
                            >
                              {/* {t("event.photos.inThisPackGuestsCanUploadUpTo")} */}
                              {t("event.photos.guestsCanUploadUpTo10Photos")}
                              {/* Guests can upload up to 10 photos */}

                              {/* {t("event.photos.photos")} */}
                            </p>
                            <h6
                              className="f"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                marginTop: "5px",
                              }}
                            >
                              {t("event.photos.free")}
                              {/* Free */}
                            </h6>
                          </div>
                        </div>
                      </div>

                      {/* Standard Package */}
                      <div className="col-xs-12 col-md-6 mt-2 mb-2">
                        <div
                          className="card pt-4 text-center"
                          onClick={() =>
                            handleSelectPackage("standard_pov_plan")
                          }
                          style={{
                            cursor: "pointer",
                            border:
                              selectedPackage === "standard_pov_plan"
                                ? "2px solid black"
                                : "1px solid #ddd",
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center p-2">
                            {currentPovPlan ===
                            ("standard_pov_plan" as string) ? (
                              <Check />
                            ) : (
                              <Dollar />
                            )}
                            <h5 className="d-flex align-items-center mb-3">
                              {t("event.photos.standard")}
                              {/* Standard */}
                            </h5>
                            <p
                              className={styles.text}
                              style={{ fontSize: "16px" }}
                            >
                              {/* {t("event.photos.inThisPackGuestsCanUploadUpTo")} */}
                              {t("event.photos.guestsCanUploadUpTo100Photos")}
                              {/* Guests can upload up to 100 photos */}

                              {/* {t("event.photos.photos")} */}
                            </p>
                            <h6
                              className="f"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                marginTop: "5px",
                              }}
                            >
                              $ 10 {t("event.photos.month")}
                              {/* $ 10 / month */}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Unlimited Package */}
                    <div className="col-xs-12 mt-1 mb-4">
                      <div
                        className="card pt-4 text-center"
                        onClick={() =>
                          handleSelectPackage("unlimited_pov_plan")
                        }
                        style={{
                          cursor: "pointer",
                          border:
                            selectedPackage === "unlimited_pov_plan"
                              ? "2px solid black"
                              : "1px solid #ddd",
                        }}
                      >
                        <div className="d-flex flex-column justify-content-center align-items-center p-2">
                          {currentPovPlan == "unlimited_pov_plan" ? (
                            <Check />
                          ) : (
                            <Dollar />
                          )}
                          <h5 className="d-flex align-items-center mb-3">
                            {t("event.photos.unlimited")}
                            {/* Unlimited */}
                          </h5>
                          <p className={styles.text}>
                            {/* {t(
                        "event.photos.inThisPackageGuestsCanUploadAnUnlimitedNumberOfPhotos",
                      )} */}
                            {t(
                              "event.photos.guestsCanUploadAnUnlimitedNumberOfPhotos",
                            )}
                            {/* Guests can upload an unlimited number of photos */}
                          </p>
                          <h6
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              marginTop: "5px",
                            }}
                          >
                            {/* $ 50 {t("event.photos.month")} */}$ 50 / month
                          </h6>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {selectedPackage == "basic_pov_plan" ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "16px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-lg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                    </svg>
                    <h6
                      className="f"
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                    >
                      {t("event.photos.youAlreadyHaveThisPack")}
                      {/* You already have this pack */}
                    </h6>
                  </div>
                ) : (
                  <>
                    {selectedPackage == currentPovPlan ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "16px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-check-lg"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                          </svg>
                          <h6
                            className="f"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              marginTop: "5px",
                            }}
                          >
                            {t("event.photos.youAlreadyHaveThisPack")}
                            {/* You already have this pack */}
                          </h6>
                        </div>
                        {/* {currentPovPlanDetails && (
                          <Alert
                            variant="light"
                            className="mt-3 mb-0"
                            style={{
                              fontSize: "0.75rem",
                              color: "#8a8a8a",
                              padding: "0.4rem 0.6rem",
                              borderColor: "transparent",
                              backgroundColor: "#f8f9fa",
                              textAlign: "center",
                              marginBottom: "0",
                              borderRadius: "0 0 4px 4px",
                            }}
                          >
                            {currentPovPlanDetails}
                          </Alert>
                        )} */}
                      </>
                    ) : (
                      <>
                        {isLoadingCheckout ? (
                          <div className="btn btn-dark w-100 h-100 d-flex flex-column justify-content-center align-items-center py-3">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                                {t("common.loading")}
                              </span>
                            </Spinner>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              className="btn btn-dark mt-4 mb-4 ps-5 pe-5 d-flex align-items-center"
                              onClick={handleCheckout}
                            >
                              {t("event.photos.buyAPack")}
                              {/* Buy a pack */}
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* {showConfirmDialog && (
        <PaymentStripe
          showConfirmDialog={showConfirmDialog}
          setShowConfirmDialog={setShowConfirmDialog}
          showUpgrade={showUpgrade}
          setShowUpgrade={setShowUpgrade}
          selectedPackage={selectedPackage}
          selectedCheckoutPackage={selectedCheckoutPackage}
          setSelectedCheckoutPackage={setSelectedCheckoutPackage}
        />
      )} */}
    </>
  )
}

export default UpgradeImagePack
