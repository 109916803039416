import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useEffect, useRef, useState } from "react"
import { IconChat2 } from "../../utils/Icons/CustomIcons"
import {
  getConversation,
  markAsRead,
  setNewUser,
} from "../../../redux/slices/messages/messagesSlice"
import HoverTooltip from "../../Event/HoverTooltip"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import {
  clearDataOnEvent,
  getContactsOnEvent,
  setCurrentPage,
} from "../../../redux/slices/contacts/contactsSlices"
import { IContact } from "../../Contacts/types"
import styles from "./ChooseUser.module.scss"

type Props = {
  show: boolean
  setShow: any
  eventId: number | undefined
  setEventId: any
  setName: any
  setRight: any
  setContactId: any
  scrollChat: any
  setCanSend: any
}

const ChooseUser = ({
  show,
  setShow,
  eventId,
  setEventId,
  setName,
  setRight,
  setContactId,
  scrollChat,
  setCanSend,
}: Props) => {
  const { t } = useTranslation()
  const { current_page, total_pages } = useAppSelector(
    (state) => state.contacts,
  )
  const dataOnEvent = useAppSelector((state) => state.contacts.data_on_event)
  const [dataLoadMore, setDataLoadMore] = useState<IContact[]>([])
  const [isLast, setIsLast] = useState(false)
  const [user, setUser] = useState("")
  const dispatch = useAppDispatch()
  const ref = useRef<HTMLDivElement>(null)

  const loadMore = () => {
    dispatch(
      setCurrentPage(current_page === total_pages ? 1 : current_page + 1),
    )
    dispatch(
      getContactsOnEvent({
        sort: "",
        order: "",
        searchValue: "",
        statusNumber: "",
        reminderNumber: "",
        id: Number(eventId),
      }),
    )
  }

  const handleScroll = () => {
    if (ref.current) {
      if (
        ref.current.scrollTop ===
        (ref.current.scrollHeight <= 261 ? 32 : ref.current.scrollHeight - 360)
      ) {
        setIsLast(false)
      } else {
        setIsLast(true)
      }
    }
  }

  useEffect(() => {
    if (total_pages === 1) {
      setIsLast(false)
    } else {
      setIsLast(true)
    }
  }, [total_pages])

  useEffect(() => {
    dataOnEvent.forEach((item: any) => {
      const exists = dataLoadMore.find(
        (existingItem: any) => existingItem.id === item.id,
      )
      if (!exists) {
        return setDataLoadMore([...dataLoadMore, item])
      }
    })
  }, [dataOnEvent, dataLoadMore])

  const onHide = () => {
    setShow(false)
    dispatch(setCurrentPage(1))
    setDataLoadMore([])
    setEventId(0)
    setIsLast(false)
    dispatch(clearDataOnEvent())
  }

  const onChoose = (chosenUser: any) => {
    setUser(chosenUser)
    dispatch(
      setNewUser({
        eventId: eventId,
        contactId: chosenUser.id,
        first_name: chosenUser.first_name,
        last_name: chosenUser.last_name,
        suffix: chosenUser.suffix,
        email: chosenUser.email,
        phone: chosenUser.phone,
      }),
    )
    dispatch(getConversation(chosenUser.id))
    dispatch(markAsRead(chosenUser.id))
    setName(`${chosenUser.first_name} ${chosenUser.last_name}`)
    setEventId(eventId)
    setRight(true)
    setContactId(chosenUser.id)
    window.scrollTo(0, 1000)
    scrollChat()
    setCanSend(chosenUser.whatsapp_message_can_be_sent)
    onHide()
  }

  return (
    <Modal
      className={`${styles.addPopup} choose-user__modal`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body className={styles.body}>
        <div>
          <h2 className={`${styles.title}`}>Start a conversation</h2>
          <h4 className={`${styles.description}`}>
            With any of the guests of the selected event
          </h4>
          <div className={`${styles.wrapper}`}>
            <div className={`${!isLast ? styles.lastNone : styles.last}`}></div>
            <div
              className={`${styles.container}`}
              ref={ref}
              onScroll={(e) =>
                e.target.addEventListener("scroll", handleScroll)
              }
            >
              {dataLoadMore.length > 0 &&
                dataLoadMore.map((user) => {
                  return dataLoadMore[dataLoadMore.length - 1].id !==
                    user.id ? (
                    <div key={user.id} className={`${styles.user}`}>
                      <span className={`${styles.name}`}>
                        {user.suffix} {user.first_name} {user.last_name}
                      </span>
                      {user.whatsapp_message_can_be_sent === false ? (
                        <HoverTooltip
                          text="You cannot send messages to this contact"
                          id={"t-22"}
                        >
                          <span
                            style={{ background: "#dde2ee" }}
                            className={`${styles.icon}`}
                          >
                            <IconChat2 />
                          </span>
                        </HoverTooltip>
                      ) : (
                        <span
                          style={{ background: "#FFF" }}
                          className={`${styles.icon}`}
                          onClick={() => onChoose(user)}
                        >
                          <IconChat2 />
                        </span>
                      )}
                    </div>
                  ) : (
                    <div key={user.id}>
                      <div className={`${styles.user}`}>
                        <span className={`${styles.name}`}>
                          {user.suffix} {user.first_name} {user.last_name}
                        </span>
                        {user.whatsapp_message_can_be_sent === false ? (
                          <HoverTooltip
                            text="You cannot send messages to this contact"
                            id={"t-22"}
                          >
                            <span
                              style={{ background: "#dde2ee" }}
                              className={`${styles.icon}`}
                            >
                              <IconChat2 />
                            </span>
                          </HoverTooltip>
                        ) : (
                          <span
                            style={{ background: "#FFF" }}
                            className={`${styles.icon}`}
                            onClick={() => onChoose(user)}
                          >
                            <IconChat2 />
                          </span>
                        )}
                      </div>
                      <div
                        style={{
                          height: "10px",
                          display: total_pages > 1 ? "block" : "none",
                        }}
                      >
                        <button
                          type="button"
                          className={`${styles.btn}`}
                          key={user.id + "key"}
                          onClick={() => loadMore()}
                        >
                          Load more...
                        </button>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ChooseUser
