import { useTranslation } from "react-i18next"
import {
  IconConfirm,
  IconFailed,
  IconRecieved,
  IconReject,
  IconScanned,
  IconSending,
  IconSendingBg,
  IconSent,
  IconWaiting,
} from "../../components/utils/Icons/CustomIcons"
import { useAppSelector } from "../../redux/store"

const invitationStatuses = [
  { text: "Confirmed", image: <IconConfirm />, id: 1 },
  { text: "Rejected", image: <IconReject />, id: 2 },
  { text: "Waiting", image: <IconWaiting />, id: 0 },
  { text: "Scanned", image: <IconScanned />, id: 4 },
  { text: "Failed", image: <IconFailed />, id: 6 },
  { text: "Sending", image: <IconSendingBg />, id: 7 },
]

const messageStatuses = [
  { text: "Sent", image: <IconSent />, id: 1 },
  { text: "Delevered", image: <IconSent />, id: 2 },
  { text: "Received", image: <IconRecieved color="#B3B2AD" />, id: 3 },
  { text: "Read", image: <IconRecieved color="#44BAF2" />, id: 4 },
  { text: "Failed", image: <IconFailed />, id: 5 },
  { text: "Sending", image: <IconSending />, id: 0 },
]

const StatusOk = () => {
  const { t } = useTranslation()
  const { message_status, invitation_status, phone } = useAppSelector(
    (state) => state.events,
  )

  return (
    <>
      <div className="description-page">
        <span className="test-description">
          {t("test.sent")} +{phone}
        </span>
      </div>

      <div className="test-row test-status-row">
        <div className="test-col test-col--balance bl-default">
          <span className="test-status">{t("test.invitationStatus")}</span>
          <div className="test-right">
            <div className="test-right__status">
              <span className="test-right__text">
                {invitationStatuses.find(
                  (item) => item.id === invitation_status,
                )
                  ? invitationStatuses.find(
                      (item) => item.id === invitation_status,
                    )?.text
                  : ""}
              </span>
              {invitationStatuses.find((item) => item.id === invitation_status)
                ? invitationStatuses.find(
                    (item) => item.id === invitation_status,
                  )?.image
                : ""}
            </div>
          </div>
        </div>

        <div className="test-col test-col--balance bl-default">
          <span className="test-status">{t("test.messageStatus")}</span>
          <div className="test-right">
            <div className="test-right__status">
              <span className="test-right__text">
                {messageStatuses.find((item) => item.id === message_status)
                  ? messageStatuses.find((item) => item.id === message_status)
                      ?.text
                  : ""}
              </span>
              {messageStatuses.find((item) => item.id === message_status)
                ? messageStatuses.find((item) => item.id === message_status)
                    ?.image
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StatusOk
