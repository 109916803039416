import { Dropdown } from "react-bootstrap"
import { useParams } from "react-router-dom"
import {
  deleteGuest,
  getContactsOnEvent,
  setLoading,
} from "../../redux/slices/contacts/contactsSlices"
import { sendWAInvite } from "../../redux/slices/events/eventsSlice"
import { useAppDispatch } from "../../redux/store"
import { sendInvitation } from "../../api/events"
import {
  IconContactDelete,
  IconContactsChange,
  IconCopyDoc,
  IconEnvelope,
  IconOptions,
  IconWhatsapp,
} from "../utils/Icons/CustomIcons"
import HoverTooltip from "./HoverTooltip"
import {t} from "i18next";

const GuestActionsPopup = ({
  editPopupOpen,
  guest,
  filter,
  notify,
}: any) => {
  const dispatch = useAppDispatch()
  const { event_id } = useParams()

  const onDeleteGuest = () => {
    dispatch(
      deleteGuest({ eventId: event_id, guestId: [{ contact_id: guest.id }] }),
    ).then((result: any) => {
      if (result.error) {
        console.log(result)
      } else {
        dispatch(setLoading())
        dispatch(getContactsOnEvent(filter))
        notify("Guest was successfully deleted", false)
      }
    })
  }

  const onSendWAInvite = () => {
    if (guest.can_send_invitation) {
      dispatch(
        sendWAInvite({ eventId: Number(event_id), contactIds: [guest.id] }),
      ).then((result) => {
        notify("Messages sent", false)
      })
    } else {
      notify("Messages sent", true)
    }
  }

  const onCopyInvitationMessage = () => {
    navigator.clipboard.writeText(guest.message);
    notify(t("guests.actionsDropdown.copyInvitationMessage.resultNotifyText"), false);
  }

  return (
    <Dropdown className="dropdown-block dropdown--not-arrow dropdown-filter guest-actions-popup">
      <Dropdown.Toggle className="dropdown-block-text border-0 event-page__btn">
        <IconOptions />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-my">
        <Dropdown.Item onClick={onSendWAInvite}>
          <IconWhatsapp />
          <span className="dropdown-text">Send Whatsapp invite</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={onCopyInvitationMessage}>
          <IconCopyDoc />
          <span className="dropdown-text">{t("guests.actionsDropdown.copyInvitationMessage.title")}</span>
        </Dropdown.Item>

        <Dropdown.Item href={sendInvitation(guest.phone, guest.message)} target="_blank">
          <IconEnvelope />
          <span className="dropdown-text">Send invite manually</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => editPopupOpen(guest)}>
          <IconContactsChange />
          <span className="dropdown-text">Edit</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={onDeleteGuest}>
          <IconContactDelete />
          <span className="dropdown-text">Delete</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default GuestActionsPopup
