import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  IconArrowPreview,
  IconArrowRight,
  IconCrown,
} from "../../components/utils/Icons/CustomIcons"
import { Tab, Tabs } from "react-bootstrap"
import InvitationPhone from "@/components/CreateEvent/InvitationPhone/InvitationPhone"
import AcceptPhone from "@/components/CreateEvent/AcceptPhone/AcceptPhone"
import DeclinePhone from "@/components/CreateEvent/DeclinePhone/DeclinePhone"

const CreateEventPreviewOpen = ({
  event,
  new_event_img,
  loadPreview,
  language,
  onSubmit,
}: any) => {
  const { t } = useTranslation()

  const [preview, setPreview] = useState(loadPreview)

  // tabs
  const [key, setKey] = useState("tab1")
  const [opened, setOpened] = useState(false)

  const handleLanguage = () => {
    if (language == "English" || language == "en") {
      setPreview(loadPreview.en)
    }

    if (language == "Arabic" || language == "ar") {
      setPreview(loadPreview.ar)
    }
  }

  useEffect(() => {
    handleLanguage()
  }, [])

  useEffect(() => {
    handleLanguage()
  }, [loadPreview, language])

  return (
    <div className="createEventForm__right createEventForm-open">
      <div className="preview">
        <h3
          className={`caption caption-three ${opened && "opened"}`}
          onClick={() => {
            setOpened(!opened)
          }}
        >
          Preview
          <IconArrowPreview />
        </h3>
        <div className={`preview-controls ${opened && "opened"}`}>
          {event.invitation_show_qr_code && (
            <span
              onClick={(e) => {
                onSubmit(e, "qr")
              }}
              className="editTextQR"
            >
              {/*<IconCrown /> Edit QR code design*/}
            </span>
          )}
          <Tabs activeKey={key} onSelect={(k: any) => setKey(k)}>
            <Tab
              className={`preview-controls__line ${
                event.invitation_show_qr_code ? "qr" : ""
              }`}
              eventKey="tab1"
              title={
                <div className="preview-name">
                  <span>Invitation Page</span>
                  <IconArrowRight />
                </div>
              }
            >
              <InvitationPhone />
            </Tab>
            <Tab
              className={`preview-controls__line ${
                event.invitation_show_qr_code ? "qr" : ""
              }`}
              eventKey="tab2"
              title={
                <div className="preview-name">
                  <span>Accept</span>
                  <IconArrowRight />
                </div>
              }
            >
              <AcceptPhone />
            </Tab>
            <Tab
              className={`preview-controls__line ${
                event.invitation_show_qr_code ? "qr" : ""
              }`}
              eventKey="tab4"
              title={
                <div className="preview-name">
                  <span>Decline</span>
                  <IconArrowRight />
                </div>
              }
            >
              <DeclinePhone />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default CreateEventPreviewOpen
