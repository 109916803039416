import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"
import api from "@/api/api"
import { CheckoutStripeSessionParams } from "@/components/Payments/types"

const TIMEOUT_DURATION = 30000 // 30 seconds timeout

const timeoutPromise = (ms: number) =>
  new Promise((_, reject) =>
    setTimeout(() => reject(new Error("Request timed out")), ms),
  )

interface CheckoutState {
  checkoutStripeLoading: boolean
  checkout_url: string | null
  errorStripeLoading: string | null
}

const initialState: CheckoutState = {
  checkoutStripeLoading: true,
  checkout_url: null,
  errorStripeLoading: null,
}

export const createStripeCheckoutSession = createAsyncThunk(
  "payments/createStripeCheckoutSession",
  async (params: CheckoutStripeSessionParams, { rejectWithValue }) => {
    const response = await api.payments.createStripeCheckoutSession(params)
    if (response.data) {
      return response.data
    } else {
      return rejectWithValue("Something went wrong")
    }
  },
)

const striperPaymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    resetCheckoutState: (state) => {
      state.checkout_url = null
      state.errorStripeLoading = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStripeCheckoutSession.pending, (state) => {
        state.checkoutStripeLoading = true
      })
      .addCase(createStripeCheckoutSession.fulfilled, (state, action) => {
        state.checkoutStripeLoading = false
        state.checkout_url = action.payload
        state.errorStripeLoading = null
      })
      .addCase(createStripeCheckoutSession.rejected, (state, action) => {
        state.checkoutStripeLoading = false
        state.errorStripeLoading = action.payload as string
      })
  },
})

export const { resetCheckoutState } = striperPaymentsSlice.actions
export default striperPaymentsSlice.reducer
