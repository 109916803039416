import Select from "react-select"

const options = [
  {
    value: "Text",
    label: "Text",
  },
  {
    value: "Number",
    label: "Number",
  },
  {
    value: "Dropdown options",
    label: "Dropdown options",
  },
  {
    value: "URL",
    label: "URL",
  },
]

const TypeSelect = ({ values, setValues, item, value }: any) => {
  return (
    <Select
      // menuIsOpen={true}
      // inputValue="huhu"
      isSearchable={false}
      classNamePrefix=""
      options={options}
      value={options.find((e) => e.value === value)}
      onChange={(option) => {
        setValues(
          values.map((value: any) => {
            return value.id === item.id
              ? { ...value, type: option?.value }
              : value
          }),
        )
      }}
      styles={{
        container: (state: any) => ({
          ...state,
          display: "block",
          width: "100%",
          minHeight: "26px",
          "@media (max-width: 1279px)": {
            // maxWidth: "356px",
          },
          "@media (max-width: 991px)": {
            display: "block",
            maxWidth: "100%",
          },
        }),
        valueContainer: (state: any) => ({
          ...state,
          padding: "0px 10px 0px 0px",
          color: "red",
          "@media (max-width: 991px)": {
            maxWidth: "90%",
          },
        }),
        control: (state: any) => ({
          ...state,
          fontSize: "14px",
          lineHeight: "26px",
          minHeight: "20px",
          border: 0,
          boxShadow: "none",
          borderBottom: "1px solid #E3E2E2",
          "&:hover": {
            borderBottom: "1px solid #E3E2E2",
          },
          borderRadius: "",
          paddingBottom: "11px",
          marginBottom: "5px",
          "@media (max-width: 1279px)": {
            fontSize: "12px",
            lineHeight: "16px",
          },
        }),
        singleValue: (state: any) => ({
          ...state,
          color: "rgba(11, 12, 14, 1)",
          "@media (max-width: 991px)": {
            whiteSpace: "wrap",
          },
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),

        dropdownIndicator: (base: any, state) => ({
          ...base,
          padding: "0px",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        }),
        option: (defaultStyles: any, state: any) => ({
          ...defaultStyles,
          fontSize: "16px",
          "@media (max-width: 1279px)": {
            fontSize: "12px",
          },
          lineHeight: "22.4px",
          position: "relative",
          backgroundColor: state.isSelected ? "#F2F4F7" : "#FFF",
          "&:hover": {
            background: "#F2F4F7",
          },
          "&::after": {
            content: state.isSelected ? "url(/vector/check.svg)" : '""',
            position: "absolute",
            right: "10px",
            top: "calc(50% - 8px)",
            width: "16px",
            height: "16px",
          },
          padding: "13.5px 10px",
          "@media (max-width: 991px)": {
            padding: "6px 36px 6px 10px",
          },
          color: "#0B0C0E",
        }),

        menu: (state: any) => ({
          ...state,
          boxShadow:
            "0 0 0 0px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
          borderRadius: "0",
        }),
        menuList: (state: any) => ({
          ...state,
          padding: "0",
        }),
      }}
    />
  )
}

export default TypeSelect
