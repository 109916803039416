import { useState, useEffect, SyntheticEvent } from "react"
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  getPreview,
  clearImg,
  createEvent,
  eventDesign,
  clearEventId,
  clearQrId,
  clearCurrentEvent,
} from "../../redux/slices/events/eventsSlice"
import Spinner from "react-bootstrap/Spinner"
import {
  IPhoneStepOneRequest,
  IPhoneStepTwoRequest,
} from "@/components/Auth/types"
import { Link } from "react-router-dom"
import {
  IconAlertCircle,
  IconArrowLeft,
} from "../../components/utils/Icons/CustomIcons"
import { loginPhoneStepFour } from "@/redux/slices/auth/authSlice"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import PhoneInput from "react-phone-input-2"
import { Button } from "react-bootstrap"
import Preloader from "@/components/utils/Preloader/Preloader"
import CodeInput from "react-code-input"

import {
  StringInput,
  // DateEvent,
  // ChooseFileEvent,
  FileDropzone,
  FileDropzoneWithIcon,
  // DateInput,
  SelectInput,
  GoogleAutocompleteInput,
} from "@/components/common/formElements"
import { NewDateEvent } from "@/components/EditEvent/DateEvent/NewDateEvent"
import SuccessMessage from "./SuccessMessage"
import FailedMessage from "./FailedMessage"
import api from "@/api/api"
import moment from "moment"

dayjs.extend(utc)
import Message from "../Auth/Message"
import { getContactsOnEventMobile } from "@/redux/slices/contacts/contactsSlices"

const eventType = [
  { label: "wedding", value: 0 },
  { label: "engagement", value: 1 },
  { label: "graduation", value: 2 },
  { label: "corporate event", value: 3 },
  { label: "opening", value: 4 },
  { label: "birthday", value: 5 },
  { label: "new born", value: 6 },
  { label: "new house", value: 7 },
  {
    label: "family or friends gathering",
    value: 8,
  },
  {
    label: "national holiday party",
    value: 9,
  },
  { label: "reunion", value: 10 },
  { label: "other", value: 11 },
]
const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
// Define Zod validation schema for event form

const eventSchema = z.object({
  image: z
    .any()
    .refine((files) => files && files.length > 0, {
      message: "Image is required.",
    })
    .refine(
      (files) => {
        if (!files || files.length === 0) return true // Skip if no file is provided
        const file = files[0] // Since multiple is false, only one file is expected
        const validTypes = ["image/jpeg", "image/png", "image/jpg"]
        return validTypes.includes(file?.type)
      },
      {
        message: "Allowed formats are JPG, PNG, JPEG.",
      },
    )
    .refine(
      (files) => {
        if (!files || files.length === 0) return true // Skip if no file is provided
        const file = files[0]
        return file?.size <= 5 * 1024 * 1024 // 5MB size limit
      },
      {
        message: "Image size should be less than 5 MB.",
      },
    ),
  eventName: z.string().min(1, "Event name is required"),
  location: z
    .object({
      address: z.string().nonempty("Address is required."),
      latitude: z.number().refine((val) => !isNaN(val), {
        message: "Latitude must be a valid number.",
      }),
      longitude: z.number().refine((val) => !isNaN(val), {
        message: "Longitude must be a valid number.",
      }),
      name: z.string().nonempty("Name is required."),
    })
    .refine(
      (data) => {
        // Check if all fields are valid
        return !!(data.address && data.latitude && data.longitude && data.name)
      },
      {
        message: "All fields are required and must be valid.",
      },
    ),

  start_datetime: z
    .date()
    .refine(
      (val) => !isNaN(new Date(val).getTime()), // check if it's a valid date
      {
        message: "Invalid date format",
      },
    )
    .transform((val) => new Date(val)), // transforms the string into a Date object
  eventType: z.string().min(1, "Event type is required"),
})

// Define Zod validation schema for WhatsApp Form
const whatsappSchema = z.object({
  phone: z
    .string()
    .min(9, { message: "Enter a valid WhatsApp number" })
    .regex(/^[0-9]+$/, "Invalid number"),
})

// Define the Zod schema for OTP validation
// const otpSchema = z.object({
//   otp: z
//     .array(z.string().length(1, { message: "Must be 1 character" }))
//     .length(3, { message: "Must enter 3  digits" }),
// })
// type OtpFormData = {
//   otp: string[]
// }

const otpSchema = z.object({
  otp: z
    .string()
    .length(3, "OTP must be exactly 3 digits")
    .refine((val) => /^[0-9]+$/.test(val), "OTP must be numeric"), // Ensure OTP is numeric  // .string()
  // .min(3, "OTP must be exactly 6 digits")
  // .max(3, "OTP must be exactly 6 digits"),
})

// Define TypeScript types based on schema
type OtpFormData = z.infer<typeof otpSchema>

type EventFormData = z.infer<typeof eventSchema>
// type IPhoneStepOneRequest = z.infer<typeof whatsappSchema>

const PhoneNumberLogin = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [imageOd, setImageId] = useState(null)
  const [eventId, setEventId] = useState(null)
  const [step, setStep] = useState(1)
  const [whatsappData, setWhatsAppData] = useState<IPhoneStepOneRequest | null>(
    null,
  )
  const [loadingOtp, setLoadingOtp] = useState(false)
  const WhatsAppForm = ({
    onNext,
    setStep,
  }: {
    onNext: (data: IPhoneStepOneRequest) => void
    setStep: (data: number) => void
  }) => {
    const [loadingWhatsap, setLoadingWhatsap] = useState(false)
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<IPhoneStepOneRequest>({
      resolver: zodResolver(whatsappSchema),
    })

    // Handle form submission
    const onSubmit = async (total: IPhoneStepOneRequest) => {
      try {
        setLoadingWhatsap(true)
        // setSteps("whatsapNumber")
        // const data = eventData
        const resSent = await api.auth.loginPhoneStepOne(total)
        setLoadingWhatsap(false)

        if (resSent.status === 200) {
          onNext(total)
        } else {
          toast.error("Error while sending OTP")
        }
      } catch (err) {
        setLoadingWhatsap(false)

        console.error("Error while creating event:", err)
        toast.error("Error while sending otp")
      }
    }

    return (
      <div className=" d-flex align-items-center justify-content-center ">
        <div
          className=" bg-white p-5  align-items-center justify-content-center "
          style={{ maxWidth: "500px" }}
        >
          <div className="  ">
            <Link
              to="/welcome"
              className="justify-content-center align-align-center "
              style={{ textTransform: "none", textDecoration: "none" }}
            >
              <Button
                className="d-flex flex-row  align-items-center text-dark text-white border-0"
                style={{ backgroundColor: "transparent" }}
              >
                <div>
                  <IconArrowLeft />
                </div>
                <span
                  className="reminders-page__link-text"
                  style={{
                    fontSize: "17px",
                    color: "black",
                    marginBottom: "-5px",
                  }}
                >
                  {t("buttons.backNew")}
                </span>{" "}
              </Button>
            </Link>
          </div>
          <div className="flex flex-column align-items-center  d-flex align-items-center justify-content-center p-3 ">
            <img
              src="/images/whatsapp.jpg"
              width="50px"
              height="50px"
              alt=""
              className="d-flex justify-content-center align-items-center"
            />
            <h3 className="mt-3 fs-3 text-center fw-normal text-dark">
              {t("event.logInUsingWhatsAppNumber")}
            </h3>

            <h6 className="mt-3 fs-9 text-center text-muted opacity-75 fw-light">
              {t("event.weWillSendTheCode")}
            </h6>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    country={"sa"} // Default country
                    placeholder={"999-99-99"}
                    inputStyle={{
                      fontSize: "16px", // Increase font size
                      height: "50px", // Increase height
                      paddingLeft: "40px",
                      margin: "5px",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      borderBottom: "1px solid #ced4da",
                      backgroundColor: "transparent", // Make input background transparent
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent", // Make flag background transparent
                      border: "none", // Remove border if needed
                      marginRight: "2px",
                      height: "50px", // Increase flag button height
                      fontSize: "16px", // Increase font size for the flag
                    }}
                    value={field.value}
                    onChange={field.onChange} // Hook into react-hook-form
                  />
                )}
              />
              {errors.phone && (
                <span className=" error mt-1">{errors.phone.message}</span>
              )}
              {/* <Button type="submit" className="mt-5 w-100 bg-dark border-0">
                Verify
              </Button> */}
              {loadingWhatsap ? (
                <div className="mt-4 btn btn-dark w-100 d-flex flex-column justify-content-center align-items-center ">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                      Loading...
                      {t("common.loading")}
                    </span>
                  </Spinner>
                </div>
              ) : (
                <Button
                  type="submit"
                  className="mt-4 w-100 bg-dark border-0"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("event.verify")}
                </Button>
              )}{" "}
            </form>
          </div>

          <div></div>
        </div>
      </div>
    )
  }

  // Component definition
  const OtpVerification = ({
    onVerify,
    setStep,
  }: {
    onVerify: (otp: string) => void
    setStep: (data: number) => void
  }) => {
    const [resendLoading, setResendLoading] = useState(false)
    const [cooldown, setCooldown] = useState(60) // Cooldown in seconds
    const [userId, setUserId] = useState(null)
    // Setup the useForm hook with Zod resolver
    const {
      control,
      handleSubmit,
      setValue,
      getValues,
      watch,
      setError,

      formState: { errors },
    } = useForm<OtpFormData>({
      resolver: zodResolver(otpSchema),
      defaultValues: {
        otp: "",
      },
    })

    const otpValues = watch("otp")

    // Function to handle OTP resend
    const handleResend = async () => {
      setResendLoading(true)
      // setCooldown(60) // Reset cooldown timer to 60 seconds

      try {
        // Simulate API call for resending OTP
        await new Promise((resolve) => setTimeout(resolve, 2000)) // Simulate 2-second delay
        const resSent = await api.auth.loginPhoneStepOne({
          phone: whatsappData?.phone ? whatsappData?.phone : null,
        })

        if (resSent.status === 200) {
          toast.success("OTP code sent successfully")
        }
        setResendLoading(false)

        // After successful resend, reset loading state
      } catch (error) {
        console.error("Error resending OTP:", error)
        setResendLoading(false)
      }
    }

    let quizAnswers: any
    const storedAnswers = localStorage.getItem("quizAnswers")
    if (storedAnswers) {
      quizAnswers = JSON.parse(storedAnswers)
    } else {
      quizAnswers = [] // or set to an appropriate default value
    }

    // Handle form submission
    const onSubmit: SubmitHandler<OtpFormData> = (data) => {
      setLoadingOtp(true)
      const otpFinal = {
        phone: whatsappData?.phone ? whatsappData?.phone : null,
        otp: data.otp?.toString() || "",
      }
      try {
        // Simulate OTP verification delay
        // const otpResponse: any = await api.auth.loginPhoneStepThree(otpFinal)
        dispatch(loginPhoneStepFour(otpFinal)).then((data: any) => {
          if (data?.payload?.result === 5 && data?.payload?.user != null) {
            // setStep(4)
            setUserId(data?.payload?.user?.id)
            localStorage.setItem(
              "userPhoneNumber",
              whatsappData?.phone ? whatsappData?.phone : "",
            )

            try {
              const params = {
                userId: data?.payload?.user?.id,
                answersId: quizAnswers,
              }
              if (quizAnswers) {
                api.events
                  .quizAnswers(params)
                  .then((data: any) => {
                    navigate("/my-invites")
                  })
                  .catch((err) => {
                    navigate("/my-invites")

                    console.log("err", err)
                  })
              }
            } catch (err) {
              navigate("/my-invites")

              console.log("err", err)
            }
            navigate("/my-invites")

            // onVerify("265252")
            // toast.success("valid OTP")
            ////login them in
          } else {
            setLoadingOtp(false)

            toast.error("Invalid OTP")
          }
        })
        // console.log("otpResponse slice", otpResponse)

        // setTimeout(() => {
        //   setLoadingOtp(false)
        // }, 3000)
      } catch (err) {
        setLoadingOtp(false)
        console.error("Error while verifying OTP:", err)
      }
    }
    const otpValue = watch("otp")
    // Automatically submit the form when OTP is filled
    useEffect(() => {
      if (otpValue && otpValue.length === 3) {
        const timeoutId = setTimeout(() => {
          onSubmit({ otp: otpValue })
        }, 400) // Set timeout here directly

        return () => clearTimeout(timeoutId) // Clear timeout on cleanup to avoid memory leaks
      }
    }, [otpValue, onSubmit]) // Only dependencies related to your logic

    // Handle input change and auto-submit if all fields are filled
    // const handleChange = (index: number, value: string) => {
    //   if (value.length <= 1) {
    //     setValue(`otp.${index}`, value.replace(/\D/g, "")) // Only allow numbers

    //     if (value && index < 2) {
    //       document.getElementById(`otp-input-${index + 1}`)?.focus()
    //     }
    //   }

    //   const currentOtpValues = getValues("otp")
    //   if (currentOtpValues.every((digit) => digit !== "")) {
    //     handleSubmit(onSubmit)()
    //   }
    // }

    // Countdown for resend cooldown

    useEffect(() => {
      if (cooldown > 0) {
        const timer = setTimeout(() => setCooldown((prev) => prev - 1), 1000)
        return () => clearTimeout(timer)
      }
    }, [cooldown])

    // Handle paste event to automatically fill in the OTP fields
    // Handle paste event to automatically fill in the OTP fields
    // const handlePaste = (event: React.ClipboardEvent) => {
    //   const pasteData = event.clipboardData.getData("text")
    //   if (/^\d{3}$/.test(pasteData)) {
    //     // If the pasted data matches the 8-digit OTP format, fill in the OTP inputs
    //     pasteData.split("").forEach((char, index) => {
    //       setValue(`otp.${index}`, char, {
    //         shouldValidate: true,
    //         shouldDirty: true,
    //       })
    //     })
    //   } else {
    //     setError("otp", { message: "Invalid OTP format" })
    //   }
    // }

    return (
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="bg-white p-5 align-items-center justify-content-center"
          style={{ maxWidth: "500px" }}
        >
          <div>
            <button
              className="d-flex align-items-center"
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "none",
              }}
              onClick={() => setStep(1)}
            >
              <IconArrowLeft />
              <span
                className="reminders-page__link-text "
                style={{ fontSize: "15px" }}
              >
                {t("buttons.backNew")}
              </span>
            </button>
          </div>
          <div className="flex flex-column align-items-center d-flex align-items-center justify-content-center p-3">
            <h3 className="mt-4 fs-3 text-center fw-normal text-dark">
              {t("event.pleaseEnterACode")}
            </h3>
            <h6
              className="mt-4 text-center text-muted opacity-75 fw-light"
              style={{ fontSize: "17px" }}
            >
              {t("event.weSentACodeTo")}
              <span className="fw-bold text-dark">{whatsappData?.phone}</span>
            </h6>
            {/* <form
              className="d-flex justify-content-center mt-4"
              onPaste={handlePaste}
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <Controller
                  key={index}
                  name={`otp.${index}` as const}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      id={`otp-input-${index}`}
                      type="text"
                      // value={otpValues[index]} // Ensure the value is correctly set from `watch`
                      value={field.value} // Use field.value to ensure the input is correctly controlled
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Backspace" &&
                          !field.value &&
                          index > 0
                        ) {
                          document
                            .getElementById(`otp-input-${index - 1}`)
                            ?.focus()
                        }
                      }}
                      style={{
                        width: "40px",
                        height: "40px",
                        fontSize: "20px",
                        textAlign: "center",
                        margin: "0 5px",
                        border: "1px solid #ced4da",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                />
              ))}
            </form> */}

            <form>
              <div>
                {/* OTP input field */}
                <CodeInput
                  name="otp"
                  type="text"
                  fields={3}
                  onChange={(value) => setValue("otp", value)} // update react-hook-form value
                  inputMode="numeric"
                  isValid={!errors.otp}
                />
                {/* Display validation error */}
                {errors.otp && (
                  <p style={{ color: "red" }}>{errors.otp.message}</p>
                )}
              </div>
            </form>

            {errors.otp && (
              <span className="error mt-1">
                {t("event.pleaseFillAllTheFields")}
              </span>
            )}
            {loadingOtp && (
              <div className="mt-4 w-100 d-flex flex-column justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            <h6
              className="mt-4 text-center text-muted opacity-75 fw-light"
              style={{ fontSize: "15px" }}
            >
              {t("event.theCodeWillExpireIn")} {cooldown} {t("event.seconds")}
            </h6>
            <h6
              className="mt-4 text-center text-muted opacity-75 fw-light"
              style={{ fontSize: "15px", color: "#000000" }}
            >
              {t("event.didntReceiveTheCode")}
              <button
                className="ms-2 mt-3 border-0 fw-bold"
                style={{
                  background: "none",
                  padding: 0,
                  cursor: cooldown === 0 ? "pointer" : "not-allowed",
                  font: "inherit",
                  textDecoration: "underline",
                  color: "#000000",
                }}
                onClick={handleResend}
                disabled={resendLoading || cooldown > 0}
              >
                {resendLoading
                  ? t("resending")
                  : cooldown > 0
                  ? `${t("event.resendIn")} in ${cooldown}s`
                  : t("event.sendTheCodeAgain")}
              </button>
            </h6>
          </div>
        </div>
      </div>
    )
  }

  const handleWhatsAppNext = (data: IPhoneStepOneRequest) => {
    setWhatsAppData(data)
    setStep(2)
  }

  const handleOtpVerify = async (otp: string) => {
    // You can add your OTP validation logic here
    // For demo purposes, we will just log the event data and WhatsApp data

    // console.log("Event Data:", eventData)
    // console.log("WhatsApp Number:", whatsappData)
    // console.log("OTP:", otp)
    try {
      console.log("try")
      // setSteps("whatsapNumber")
      // formData.append("file", data?.image[0])
    } catch (err) {
      setLoadingOtp(false)
      setStep(6)

      console.error("Error while creating event:", err)
      toast.error("Error while creating event")
    }
    // toast.success("Event submitted successfully!")
    // Reset steps after submission or navigate as needed

    // setStep(1)
    // setEventData(null)
    // setWhatsAppData(null)
  }

  return (
    <div className="createEventPage">
      {/* event step */}

      {step === 1 && (
        <WhatsAppForm onNext={handleWhatsAppNext} setStep={setStep} />
      )}
      {step === 2 && (
        <OtpVerification onVerify={handleOtpVerify} setStep={setStep} />
      )}

      <ToastContainer
        style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
      />
    </div>
  )
}

export default PhoneNumberLogin
