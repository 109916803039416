import React, { useState, useEffect, useRef } from "react"
import { Controller, Control } from "react-hook-form"
import DatePicker from "react-datepicker"
import { FileUploader } from "react-drag-drop-files"
import { useTranslation } from "react-i18next"
import moment from "moment"
import CustomTimeInput from "./DateEventField" // Update this to your actual component
import { IconCalendar } from "@/components/utils/Icons/CustomIcons"
import "react-datepicker/dist/react-datepicker.css"
import { useFormContext } from "react-hook-form"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import { BiImageAdd } from "react-icons/bi"

import { useDropzone } from "react-dropzone"
import { parse, format } from "date-fns" // date-fns for parsing and formatting
import Select from "react-select"
import { Autocomplete } from "@react-google-maps/api"

import {
  IconCompletedFile,
  IconDeleteFile,
  IconLoadFile,
  IconisLoadingFile,
} from "../utils/Icons/CustomIcons"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  deleteFileEvent,
  loadFileEvent,
} from "../../redux/slices/events/eventsSlice"
import {
  storeFileLocally,
  deleteFileLocally,
} from "../../redux/slices/localFileStore/localFileStore"

interface DateEventProps {
  control: Control<any> // Replace `any` with your specific form type if available
  name: string // The name of the field in the form
  event: any // Define a more specific type if available
  setEvent: (event: any) => void
  error?: any
}

export const DateEvent: React.FC<DateEventProps> = ({
  control,
  name,
  event,
  setEvent,
  error,
}) => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [focus, setFocus] = useState(false)

  return (
    <div
      className={
        error
          ? "profile-input input-item datepicker-item input-error"
          : "profile-input datepicker-item input-item"
      }
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <DatePicker
            autoComplete="off"
            selected={startDate}
            minDate={moment().toDate()}
            onChange={(date: Date | null) => {
              setStartDate(date)
              setEvent({ ...event, start_datetime: date })
              onChange(date) // Notify the parent about the change
            }}
            dateFormat={`EEEE d MMM, yyyy, HH:mm`}
            showTimeInput={true}
            customTimeInput={
              <CustomTimeInput
                date={startDate}
                value={startDate}
                onChange={onChange}
                setFocus={setFocus}
              />
            }
            onFocus={() => setFocus(true)}
            onClickOutside={() => setFocus(false)}
            showDisabledMonthNavigation
            open={focus}
          />
        )}
      />

      <label className={startDate || focus ? "active" : ""} htmlFor="">
        {startDate || focus ? "Date*" : "Pick date*"}
      </label>

      <IconCalendar className="icon" />

      {error && (
        <span className="input-item__error error mt-1">
          {typeof error === "string" ? error : error.message}
        </span>
      )}
    </div>
  )
}

interface iFile {
  name: string
  size: number
  type: string
}

interface ChooseFileEventProps {
  control: Control<any>
  name: string
  label: string
  error?: any
  isButton?: boolean
}

export const ChooseFileEvent: React.FC<ChooseFileEventProps> = ({
  control,
  name,
  label,
  error,
  isButton = false,
}) => {
  const { t } = useTranslation()
  const { new_event_img, new_event_id } = useAppSelector(
    (state) => state.events,
  )
  const dispatch = useAppDispatch()

  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  let timer: NodeJS.Timeout | null = null

  const clearFile = (id: string) => {
    dispatch(deleteFileEvent(id))
    dispatch(deleteFileLocally())
    if (timer) clearInterval(timer)
    setProgress(0)
    setFile(null)
    setLoading(false)
  }

  const handleError = () => {
    if (timer) clearInterval(timer)
    setProgress(0)
    setLoading(false)
  }

  const handleChange = (file: File) => {
    const formData = new FormData()
    formData.append("file", file)

    // Calculate file size in KB
    const sizeInKB = Math.ceil(file.size / 1024)

    setLoading(true)
    setFile(file)

    // Simulate file upload progress based on file size
    timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer as NodeJS.Timeout)
          setLoading(false)
          return 100
        }
        return prevProgress + 10
      })
    }, sizeInKB / 10)

    dispatch(storeFileLocally(file))
    dispatch(loadFileEvent(formData))
  }

  return (
    <div className="profile-input input-item">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {!file ? (
              <div className={`loadFile ${error && "error"}`}>
                <FileUploader
                  {...field}
                  name={name}
                  maxSize={5}
                  types={["JPEG", "JPG", "PNG"]}
                  multiple={false}
                  handleChange={(file: any) => handleChange(file)}
                  onTypeError={handleError}
                  onSizeError={handleError}
                  required={true}
                >
                  <div className="loadFile-text">
                    <IconLoadFile />
                    <span className="title">
                      {t("Drag and Drop or choose file to upload event image")}
                    </span>
                    <span className="desc">
                      {t(
                        "Allowed image formats are JPG, PNG, JPEG. Up to 5 Mb",
                      )}
                    </span>
                    {isButton && (
                      <span className="btn btn-dark">{t("Upload File")}</span>
                    )}
                  </div>
                </FileUploader>
              </div>
            ) : (
              <div className="loadFileCompleted">
                {/* {loading ? (
                  <IconisLoadingFile />
                ) : (
                  <button
                    className="btn-delete-file"
                    onClick={() => clearFile(new_event_img.id)}
                  >
                    <IconDeleteFile />
                  </button>
                )} */}
                <div className="loadFileCompleted__left">
                  <span className="name">{file.name}</span>
                  <span className="size">
                    {(file.size / 1024).toFixed(0)}KB
                  </span>
                  <span className="file-status">
                    {loading
                      ? `${(
                          Number((file.size / 1024 / 1024).toFixed(0)) -
                          progress / 100
                        ).toFixed(0)} seconds left`
                      : "Done"}
                  </span>
                </div>
                <div className="loadFileCompleted__right">
                  <span
                    className={`percent ${
                      progress < 100 && progress > 0 && "load"
                    }`}
                  >{`${progress}%`}</span>
                  {!loading && <IconCompletedFile />}
                </div>
                <div className="loadFileProgress">
                  <div
                    className={loading ? "progress" : "progress progress--load"}
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </div>
            )}
            {error && (
              <span className="input-item__error error mt-1">{error}</span>
            )}
          </>
        )}
      />
    </div>
  )
}

interface FileDropzoneProps {
  name: string
  label: string
  multiple?: boolean
  accept?: string
  setImage?: any
}

////new elements

// export const FileDropzone: React.FC<FileDropzoneProps> = ({
//   name,
//   label,
//   multiple = true,
//   accept = "image/*",
// }) => {
//   const { t } = useTranslation()
//   const {
//     register,
//     setValue,
//     watch,
//     formState: { errors },
//   } = useFormContext()

//   const files = watch(name) || []

//   const onDrop = (acceptedFiles: File[]) => {
//     setValue(name, acceptedFiles)
//   }

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: { "image/*": [".png", ".jpg", ".jpeg"] },
//     multiple,
//   })

//   return (
//     <div className="dropzone-container flex flex-col">
//       {/* <label className="mb-2 block text-sm font-medium text-black dark:text-white">
//         {label}
//       </label> */}
//       <div
//         {...getRootProps()}
//         // className="dropzone cursor-pointer border border-dashed p-1"
//         // className="cursor-pointer border border-dashed p-1"
//         style={{
//           cursor: "pointer",
//           border: errors[name]?.message ? "1px dashed #f00" : "1px dashed grey",

//           padding: "30px",
//           borderRadius: "20px",
//         }}
//       >
//         <input {...getInputProps()} />
//         <div className="loadFile-text ">
//           <IconLoadFile />
//           <span className="title fw-semibold">
//             {t("Drag and Drop or choose file to upload event image")}
//           </span>
//           <span className="desc">
//             {t("Allowed image formats are JPG, PNG, JPEG. Up to 5 Mb")}
//           </span>
//           {errors[name] && (
//             <small className="text-danger desc">
//               {errors[name]?.message as string}
//             </small>
//           )}
//           {/* {isButton && <span className="btn btn-dark">{t("Upload File")}</span>} */}
//         </div>{" "}
//       </div>
//       <ul className="mt-2">
//         {files.map((file: File, idx: number) => (
//           <li key={idx}>
//             {file.name} - {Math.round(file.size / 1024)} KB
//           </li>
//         ))}
//       </ul>
//     </div>
//   )
// }

export const FileDropzone: React.FC<FileDropzoneProps> = ({
  name,
  label,
  multiple = true,
  accept = "image/*",
  setImage, // New prop to set the uploaded image
}) => {
  const { t } = useTranslation()
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const files = watch(name) || []

  const onDrop = (acceptedFiles: File[]) => {
    setValue(name, acceptedFiles)
    if (setImage && acceptedFiles.length > 0) {
      setImage(URL.createObjectURL(acceptedFiles[0])) // Create a URL for the first uploaded file
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple,
  })

  return (
    <div className="dropzone-container flex flex-col">
      <div
        {...getRootProps()}
        style={{
          cursor: "pointer",
          border: errors[name]?.message ? "1px dashed #f00" : "1px dashed grey",
          padding: "30px",
          borderRadius: "20px",
        }}
      >
        <input {...getInputProps()} />
        <div className="loadFile-text">
          <IconLoadFile />
          <span className="title fw-semibold">
            {/* {t("Drag and Drop or choose file to upload event image")} */}
            {t("event.uploadInstructions")}
          </span>
          <span className="desc">{t("event.allowedFormats")}</span>
          {errors[name] && (
            <small className="text-danger desc">
              {errors[name]?.message as string}
            </small>
          )}
        </div>
      </div>
      <ul className="mt-2">
        {files.map((file: File, idx: number) => (
          <li key={idx}>
            {file.name} - {Math.round(file.size / 1024)} KB
          </li>
        ))}
      </ul>
    </div>
  )
}
export const FileDropzoneWithIcon: React.FC<FileDropzoneProps> = ({
  name,
  label,
  multiple = true,
  accept = "image/*",
  setImage, // New prop to set the uploaded image
}) => {
  const { t } = useTranslation()
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const files = watch(name) || []

  const onDrop = (acceptedFiles: File[]) => {
    setValue(name, acceptedFiles)
    if (setImage && acceptedFiles.length > 0) {
      setImage(URL.createObjectURL(acceptedFiles[0])) // Create a URL for the first uploaded file
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple,
  })

  return (
    <div className="dropzone-container flex flex-col">
      <div
        {...getRootProps()}
        style={{
          cursor: "pointer",
          // border: errors[name]?.message ? "1px dashed #f00" : "1px dashed grey",
          // padding: "30px",
          borderRadius: "20px",
        }}
      >
        <input {...getInputProps()} />
        <div className="loadFile-text">
          <BiImageAdd />

          {/* {errors[name] && (
            <small className="text-danger desc">
              {errors[name]?.message as string}
            </small>
          )} */}
        </div>
      </div>
      {/* <ul className="mt-2">
        {files.map((file: File, idx: number) => (
          <li key={idx}>
            {file.name} - {Math.round(file.size / 1024)} KB
          </li>
        ))}
      </ul> */}
    </div>
  )
}
interface CreateEventFieldProps {
  // control: Control<any> // Use a specific type if you have a defined form type
  name: string
  label: string
  placeholder?: string
  // error?: any
}

export const StringInput: React.FC<CreateEventFieldProps> = ({
  name,
  label,
  placeholder,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="profile-input ">
      <input
        {...register(name)}
        // className={`input ${error ? "input-error" : ""}`}
        placeholder={placeholder}
        style={errors[name]?.message ? { borderBottom: "1px solid red" } : {}}
      />
      {/* <label htmlFor={name} style={{ fontSize: "12px" }}>
        {label}
      </label> */}
      {errors[name] && (
        <span className="input-item__error error mt-1">
          {" "}
          {errors[name]?.message as string}
        </span>
      )}
    </div>
  )
}

export const DateInput: React.FC<CreateEventFieldProps> = ({
  name,
  label,
  placeholder,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext()

  const selectedDate = watch(name) // Get the current value of the date field
  // Function to parse date from "YYYY-MM-DD HH:mm:ss" format to Date object
  const parseDate = (dateStr: string) =>
    parse(dateStr, "yyyy-MM-dd HH:mm:ss", new Date())

  // Function to format Date object back to "YYYY-MM-DD HH:mm:ss"
  const formatDate = (date: Date) => format(date, "yyyy-MM-dd HH:mm:ss")

  return (
    <div className="profile-input input-item">
      <DatePicker
        selected={selectedDate ? parseDate(selectedDate) : null} // Parse date if exists
        onChange={(date: Date) => setValue(name, formatDate(date))} // Format the date to string on change
        className={`form-control ${errors[name] ? "is-invalid" : ""}`}
        placeholderText={placeholder}
        // showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={1} // Set 1-minute intervals for accurate time selection
        dateFormat="yyyy-MM-dd HH:mm:ss" // Custom date format
      />
      {/* <label htmlFor={name}>{label}</label> */}
      <IconCalendar className="icon" />

      {errors[name] && (
        <span className="input-item__error error mt-1">
          {errors[name]?.message as string}
        </span>
      )}
      <label
        htmlFor={name}
        className="form-label mt-3"
        style={{ fontSize: "12px" }}
      >
        {" "}
        {/* Bootstrap label class with margin-top */}
        {label}
      </label>
    </div>
  )
}

// export const SelectInput: React.FC<SelectLanguageEventProps> = ({
//   name,
//   label,
//   options,
// }) => {
//   const {
//     register,
//     setValue,
//     watch,
//     formState: { errors },
//   } = useFormContext()
//   const selectedValue = watch(name) // Get the current value of the select field

//   return (
//     <div className={errors[name] ? "select-event input-error" : "select-event"}>
//       <Select
//         classNamePrefix="select-event"
//         defaultValue={options.find((option) => option.value === selectedValue)}
//         onChange={(selectedOption: any) => {
//           setValue(name, selectedOption.value) // Set the selected value in the form
//         }}
//         options={options}
//         styles={{
//           control: (provided: any) => ({
//             ...provided,
//             border: "none",
//             borderBottom: errors[name] ? "1px solid red" : "1px solid #ced4da",
//             boxShadow: "none",
//             "&:hover": {
//               border: errors[name] ? "1px solid red" : "1px solid #80bdff",
//             },
//           }),
//         }}
//       />

//       <label className={"active"} htmlFor={name}>
//         {label}
//       </label>

//       {errors[name] && (
//         <span className="input-item__error error mt-1">
//           {/* {errors[name]?.message} */}
//         </span>
//       )}
//     </div>
//   )
// }

interface SelectLanguageEventProps {
  name: string
  label: string
  options: { value: string | number; label: string }[]
}

export const SelectInput: React.FC<SelectLanguageEventProps> = ({
  name,
  label,
  options,
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const selectedValue = watch(name) // Get the current value of the select field

  return (
    <div
      // className={errors[name] ? "select-event input-error" : "select-event"}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.1rem", // Small gap between select and error message
        marginBottom: "0.5rem",
      }}
    >
      <select
        {...register(name)} // Register the select input
        value={selectedValue || ""} // Ensure it starts as empty or the selected value
        onChange={(e) => setValue(name, e.target.value)} // Update the form value
        className={`form-select ${errors[name] ? "is-invalid" : ""}`}
        style={{
          border: "none",
          borderBottom: errors[name] ? "1px solid red" : "1px solid #ced4da",
          marginBottom: errors[name] ? "10px" : "1px",
          // marginTop: errors[name] ? "-20px" : "1px",

          boxShadow: "none",
          // padding: "0.5rem 0", // Padding for better touch area
          // marginBottom: "0", // No margin between select and error
          fontWeight: "normal",
          color: "#757575",
        }}
      >
        <option
          value=""
          disabled
          className="text-muted fw-light"
        >{`Select ${label}`}</option>

        {/* Placeholder option */}
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className="p-2"
            style={{ padding: "0.5rem 0" }}
          >
            {option.label}
          </option>
        ))}
      </select>
      {errors[name] && (
        <span className="__error error  ">
          {errors[name]?.message as string}
        </span>
      )}
    </div>
  )
}

interface SelectLanguageEventPropsAuto {
  name: string
  label: string
  options?: { value: string; label: string }[] // For general use case (not needed for Google Maps AutoComplete)
}

interface GoogleAutocompleteInputProps {
  name: string
  label: string
}

export const GoogleAutocompleteInput: React.FC<
  GoogleAutocompleteInputProps
> = ({ name, label }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)
  const { t } = useTranslation()

  const onPlaceSelected = () => {
    const place = autocompleteRef.current?.getPlace()

    if (place) {
      const formattedAddress = place.formatted_address || ""
      const locationName = place.name || ""
      const latitude = place.geometry?.location?.lat() || null
      const longitude = place.geometry?.location?.lng() || null

      setValue(`${name}.address`, formattedAddress)
      setValue(`${name}.name`, locationName)
      setValue(`${name}.latitude`, latitude)
      setValue(`${name}.longitude`, longitude)
    }
  }

  return (
    <div>
      <Autocomplete
        onLoad={(autocomplete) => {
          autocompleteRef.current = autocomplete
        }}
        onPlaceChanged={onPlaceSelected}
      >
        <div className="profile-input input-item">
          <style>{`
            input::placeholder {
              font-size: 16px; /* Adjust the font size here */
            }
          `}</style>
          <input
            {...register(`${name}.address`, {
              required: "Address is required",
            })} // Add validation rules here
            style={{
              boxSizing: "border-box",
              borderBottom: "1px solid #cfcfcf",
              width: "100%",
              height: "32px",
              padding: "0 12px",
              borderRadius: "3px",
              // boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
              fontSize: "17px",
              outline: "none",
              marginTop: errors[name] ? "50px" : "10px",
            }}
            type="text"
            placeholder={label}
            // required
          />
        </div>
      </Autocomplete>
      {/* Display error message if exists */}

      {errors[name] && (
        <span className="error ">
          {t("event.formSchemas.locationIsRequired")}
        </span>
      )}
    </div>
  )
}
